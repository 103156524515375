import { XMarkIcon } from "@heroicons/react/24/solid";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  Keyword,
  KeywordTracker,
  KeywordTrackerMatches,
  ProjectWithInterviewCount,
} from "app-types";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { joinClassnames } from "ui";
import { useAppSelector } from "../../hooks/hook";
import { selectKeywordTrackerIdToMatches } from "./insightsSlice";
import { QuestionThemeAnalysisCell } from "./questionThemeAnalysisCell";
import { QuestionThemeQuotes } from "./questionThemeQuotes";

/*
 * Types.
 */

enum QuestionThemesTableColumnsEnum {
  QUESTION = "question",
  ANALYSIS = "analysis",
}

interface QuestionThemesTableProps {
  project: ProjectWithInterviewCount;
  keywordTrackers: KeywordTracker[];
}

// A question row just displays the interview question.
interface QuestionRow {
  question: string;
}

// A keyword row display the keyword phrase and the percentage of matching interviews.
interface KeywordRow {
  keyword_tracker_id: string;
  keyword: Keyword;
}

// A placeholder row is used when a question has no valid themes yet.
interface PlaceholderRow {
  message: string;
}

type QuestionThemesTableRow = QuestionRow | KeywordRow | PlaceholderRow;

/*
 * Component.
 */

export const QuestionThemesTable: FC<QuestionThemesTableProps> = ({
  keywordTrackers,
  project,
}) => {
  const keywordTrackerIdToMatches = useAppSelector(
    selectKeywordTrackerIdToMatches
  );

  const rowData = useMemo(
    () => computeRows(keywordTrackers, keywordTrackerIdToMatches),
    [keywordTrackers, keywordTrackerIdToMatches]
  );

  const [selectedRow, setSelectedRow] = useState<KeywordRow | undefined>();
  const selectedRowRef = useRef<HTMLTableRowElement | null>(null);

  const coreColumns: ColumnDef<QuestionThemesTableRow>[] = [
    {
      accessorFn: (row: QuestionThemesTableRow) => {
        if (isQuestionRow(row)) {
          return <div>{row.question}</div>;
        }

        if (isPlaceholderRow(row)) {
          return <div className="italic">{row.message}</div>;
        }

        return <div>{row.keyword.phrase}</div>;
      },
      id: QuestionThemesTableColumnsEnum.QUESTION,
      enableSorting: false,
    },
    {
      accessorFn: (row: QuestionThemesTableRow) => {
        if (isKeywordRow(row)) {
          return <QuestionThemeAnalysisCell project={project} {...row} />;
        }
      },
      id: QuestionThemesTableColumnsEnum.ANALYSIS,
      enableSorting: false,
    },
  ];

  const columns = useMemo(() => coreColumns, []);

  // Used to ensure that when a row is clicked, the row is still visible in the viewport
  // once the QuestionThemeQuotes are rendered (since the table width will be reduced).
  useEffect(() => {
    if (selectedRow && selectedRowRef.current) {
      selectedRowRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [selectedRow]);

  const readOnlyColumn: Partial<ColumnDef<QuestionThemesTableRow>> = {
    cell: ({ getValue }) => {
      return (
        <div className="py-2 px-3 text-left text-sm">
          {getValue() as string}
        </div>
      );
    },
  };

  const table = useReactTable({
    data: rowData,
    columns,
    defaultColumn: readOnlyColumn,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const renderTableBody = () => {
    return table.getRowModel().rows.map((row) => {
      if (isQuestionRow(row.original) || isPlaceholderRow(row.original))
        return (
          <tr
            key={row.id}
            className={joinClassnames(
              "py-1.5 px-3 text-left text-gray-900",
              isQuestionRow(row.original) ? "bg-slate-100 font-semibold" : ""
            )}
          >
            {/* Apply colSpan of 2 to span both columns */}
            <td colSpan={2}>
              {flexRender(
                row.getVisibleCells()[0].column.columnDef.cell,
                row.getVisibleCells()[0].getContext()
              )}
            </td>
          </tr>
        );

      // Standard keyword rows
      return (
        <tr
          key={row.id}
          ref={
            areKeywordRowsMatching(selectedRow, row.original)
              ? selectedRowRef
              : null
          }
          onClick={() => {
            setSelectedRow(row.original as KeywordRow);
          }}
          className={joinClassnames(
            "cursor-pointer",
            areKeywordRowsMatching(selectedRow, row.original)
              ? "bg-blue-100"
              : "hover:bg-blue-50"
          )}
        >
          {row.getVisibleCells().map((cell) => {
            return (
              <td
                key={cell.id}
                className={`text-xs text-gray-900 ${
                  cell.column.id === QuestionThemesTableColumnsEnum.QUESTION
                    ? "w-4/5"
                    : ""
                }`}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            );
          })}
        </tr>
      );
    });
  };

  const renderQuotes = () => {
    if (!selectedRow) return null;

    return (
      <div
        className="text-sm w-1/2 sticky top-2 h-fit overflow-y-auto border border-slate-200 rounded-xl"
        style={{ maxHeight: "calc(100vh - 128px)" }}
      >
        <div className="bg-slate-100 sticky top-0 py-2 px-3 text-left font-semibold text-gray-900 flex justify-between">
          <div>Related quotes</div>
          <XMarkIcon
            className="h-5 w-5 cursor-pointer"
            aria-hidden="true"
            onClick={() => setSelectedRow(undefined)}
          />
        </div>
        <QuestionThemeQuotes
          keyword={selectedRow.keyword}
          keyword_tracker_id={selectedRow.keyword_tracker_id}
        />
      </div>
    );
  };

  return (
    <div className="flex space-x-2 relative w-full">
      <div
        className={`overflow-y-auto flex-1 pb-1 rounded-xl border border-slate-200 ${
          Boolean(selectedRow) ? "w-1/2" : "w-full"
        }`}
      >
        <table className="w-full">
          <tbody className="divide-y divide-gray-200 bg-white flex-shrink">
            {renderTableBody()}
          </tbody>
        </table>
      </div>
      {renderQuotes()}
    </div>
  );
};

/*
 * Helpers.
 */

function isQuestionRow(row: QuestionThemesTableRow): row is QuestionRow {
  return "question" in row;
}

function isPlaceholderRow(row: QuestionThemesTableRow): row is PlaceholderRow {
  return "message" in row;
}

function isKeywordRow(row: QuestionThemesTableRow): row is KeywordRow {
  return "keyword_tracker_id" in row && "keyword" in row;
}

function computeRows(
  keywordTrackers: KeywordTracker[],
  allKeywordTrackerMatches: Record<string, KeywordTrackerMatches>
) {
  let rowData: QuestionThemesTableRow[] = [];

  keywordTrackers.forEach((kt, i) => {
    const matches = allKeywordTrackerMatches[kt.id];
    if (kt.question) {
      rowData.push({ question: `${i + 1}. ${kt.question}` });
    }

    const sortedKeywords = [...kt.keywords]
      .filter((k) => {
        // Exclude a question summary theme if there's only 1 match
        return matches[k.phrase]?.interview_ids.length > 1;
      })
      .sort((a, b) => {
        // Sort keywords from most to least matches
        const aMatchCount = matches[a.phrase]?.interview_ids.length || 0;
        const bMatchCount = matches[b.phrase]?.interview_ids.length || 0;
        return bMatchCount - aMatchCount;
      });

    if (!sortedKeywords.length) {
      rowData.push({
        message:
          "No themes found for this question yet. Themes will be updated as more interviews are conducted.",
      });
      return;
    }

    // Create a KeywordRow for each keyword.
    sortedKeywords.forEach((keyword) => {
      rowData.push({
        keyword_tracker_id: kt.id,
        keyword: keyword,
      });
    });
  });

  return rowData;
}

function areKeywordRowsMatching(
  a: KeywordRow | undefined,
  b: KeywordRow | undefined
) {
  if (!a || !b) return false;

  return (
    a.keyword_tracker_id === b.keyword_tracker_id &&
    a.keyword.phrase === b.keyword.phrase
  );
}
