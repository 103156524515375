import { ProjectWithInterviewCount } from "app-types";
import { FC, useEffect } from "react";
import { FullPageSkeleton } from "ui";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { selectNonEmptyInterviewsWithContactsAndActivitiesForProject } from "../interviews/interviewsSlice";
import {
  clearInsightsParameters,
  fetchInsightsAsync,
  selectInsightsLoadingState,
} from "./insightsSlice";
import { InsightsTab } from "./insightsTab";

interface InsightsTabContainerProps {
  project: ProjectWithInterviewCount;
}

// This component is responsible for loading insights data based on the current parameters
export const InsightsTabContainer: FC<InsightsTabContainerProps> = ({
  project,
}) => {
  const dispatch = useAppDispatch();
  const insightsLoadingState = useAppSelector(selectInsightsLoadingState);

  const interviews = useAppSelector((state) =>
    selectNonEmptyInterviewsWithContactsAndActivitiesForProject(
      state,
      project.id
    )
  );

  // Load insights on initial render
  useEffect(() => {
    dispatch(
      fetchInsightsAsync({
        projectId: project.id,
      })
    );

    // Reset insights state on unmount
    return () => {
      dispatch(clearInsightsParameters(undefined));
    };
  }, []);

  return (
    <div className="mx-auto w-full max-w-6xl text-lg text-gray-600 py-4">
      {insightsLoadingState === "succeeded" ? (
        <InsightsTab project={project} interviews={interviews} />
      ) : (
        <FullPageSkeleton />
      )}
    </div>
  );
};
