import { FC } from "react";

interface ReadOnlyBulletedListProps {
  items: string[];
  maxItemsToDisplay?: number;
  onListClick?: () => void;
  itemDescriptor?: string;
  icon?: string | JSX.Element;
}

export const ReadOnlyBulletedList: FC<ReadOnlyBulletedListProps> = ({
  items,
  maxItemsToDisplay,
  onListClick,
  itemDescriptor = "item",
  icon = "•",
}) => {
  const shouldTruncate =
    maxItemsToDisplay && items.length > maxItemsToDisplay + 1;
  const displayItems = shouldTruncate
    ? items.slice(0, maxItemsToDisplay)
    : items;
  const excessItems = items.length - (maxItemsToDisplay || 0);

  const containerClasses = onListClick
    ? "cursor-pointer inline-block group"
    : "group";

  return (
    <div className={containerClasses} onClick={onListClick}>
      {displayItems.map((item, index) => (
        <div
          key={index}
          className={`group-hover:bg-blue-50 border-b border-l border-r max-w-full flex justify-between items-center px-3 py-2 text-sm relative bg-white ${
            index === 0 ? "border-t rounded-t-lg" : ""
          } ${
            !shouldTruncate && index === items.length - 1 ? "rounded-b-lg" : ""
          }`}
        >
          <div className="flex items-center">
            <span className="text-gray-500 mr-2">
              {typeof icon === "string" ? icon : icon}
            </span>
            <span>{item}</span>
          </div>
        </div>
      ))}
      {shouldTruncate && (
        <div className="group-hover:bg-blue-50 border-b border-l border-r max-w-full flex justify-center items-center px-3 py-2 text-sm relative bg-white rounded-b-lg cursor-pointer text-blue-500">
          +{excessItems} more {itemDescriptor}
        </div>
      )}
    </div>
  );
};
