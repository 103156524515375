import { FC } from "react";

interface UnitAmountTextProps {
  unitAmount: number;
}

export const UnitAmountText: FC<UnitAmountTextProps> = ({ unitAmount }) => {
  const dollars = unitAmount / 100;
  // If negative, show the - sign before the $
  const formattedAmount =
    dollars < 0 ? `-$${Math.abs(dollars)}` : `$${dollars}`;

  return <>{formattedAmount}</>;
};
