import { FC } from "react";
import { Button, ButtonVariantsEnum, InfoBanner, Link } from "ui";

export const PaywallBanner: FC = () => {
  return (
    <InfoBanner
      title={"Please upgrade to continue using Alpharun"}
      description={
        <>
          {
            "Your trial has expired and your company does not have an active subscription. Upgrade to run new interviews and access insights. "
          }
          <Link href="https://www.alpharun.com/pricing">View pricing</Link>
          {"."}
          <div className="flex space-x-4 mt-4">
            <Button
              variant={ButtonVariantsEnum.Primary}
              label="Contact us"
              onClick={() => {
                window.open("mailto:support@alpharun.com");
              }}
            />
            <Button
              variant={ButtonVariantsEnum.Secondary}
              label="Book a meeting"
              onClick={() => {
                window.open("https://cal.frontapp.com/alpharun/paul/7fc09a3a");
              }}
            />
          </div>
        </>
      }
    />
  );
};
