import { Company, HandlerOf, Project, ProjectUpdate } from "app-types";
import { FC } from "react";
import { Label, Select, SizesEnum } from "ui";
import { AIContextSetting } from "./aiContextSetting";

interface OtherSettingsSectionProps {
  onSaveProject: HandlerOf<ProjectUpdate>;
  company: Company;
  project: Project;
  interviewLimitSettingRef: React.MutableRefObject<HTMLLabelElement | null>;
}

export const OtherSettingsSection: FC<OtherSettingsSectionProps> = ({
  onSaveProject,
  company,
  project,
  interviewLimitSettingRef,
}) => {
  return (
    <div className="pb-6 mb-10">
      <Label size={SizesEnum.LARGE}>Other settings</Label>
      <div className="mt-3">
        <AIContextSetting
          context={project.settings.prompt_context}
          onSave={(newContext) => {
            onSaveProject({
              id: project.id,
              settings: {
                ...project.settings,
                prompt_context: newContext || null,
              },
            });
          }}
        />
      </div>
      <label className="block text-sm font-semibold leading-6 text-gray-900 mt-2">
        Clarifying questions limit
      </label>
      <div className="mt-2 w-52">
        <Select
          options={maxClarifyingQuestionsOptions}
          currentSelection={
            maxClarifyingQuestionsOptions.find(
              (o) => o.value === project.settings.max_clarifying_questions_count
            ) || { name: "2 per interview question", value: 2 }
          }
          onChange={(option) => {
            const newLimit = maxClarifyingQuestionsOptions.find(
              (o) => o.name === option.name
            )?.value;

            onSaveProject({
              id: project.id,
              settings: {
                ...project.settings,
                max_clarifying_questions_count: newLimit,
              },
            });
          }}
        />
      </div>
      <div className="mt-2 text-sm text-gray-600">
        The maximum number of dynamic clarifying questions to ask based on a
        participant's response to each interview question. Dynamic clarifying
        questions can help elicit more detailed, insightful responses, and are
        only asked when a participant's response isn't detailed enough.
      </div>
      <label
        className="block text-sm font-semibold leading-6 text-gray-900 mt-2"
        ref={interviewLimitSettingRef}
      >
        Monthly interview limit
      </label>
      <div className="mt-2 w-56">
        <Select
          options={interviewThrottlingOptions}
          currentSelection={
            interviewThrottlingOptions.find(
              (o) => o.value === project.settings.max_interview_count
            ) || { name: "No limit (recommended)", value: undefined }
          }
          onChange={(option) => {
            const newLimit = interviewThrottlingOptions.find(
              (o) => o.name === option.name
            )?.value;

            onSaveProject({
              id: project.id,
              settings: {
                ...project.settings,
                max_interview_count: newLimit,
              },
            });
          }}
        />
      </div>
      <div className="mt-2 text-sm text-gray-600">
        When the monthly interview limit is reached, participants that open an
        interview link will be blocked from starting an interview. For the best
        insights and participant experience, we recommend a high limit or none
        at all.
      </div>
    </div>
  );
};

const interviewThrottlingOptions = [
  { name: "No limit (recommended)", value: undefined },
  { name: "10 interviews per month", value: 10 },
  { name: "20 interviews per month", value: 20 },
  { name: "30 interviews per month", value: 30 },
  { name: "50 interviews per month", value: 50 },
  { name: "100 interviews per month", value: 100 },
  { name: "150 interviews per month", value: 150 },
  { name: "200 interviews per month", value: 200 },
];

const maxClarifyingQuestionsOptions = [
  { name: "No clarifying questions", value: 0 },
  { name: "1 per interview question", value: 1 },
  { name: "2 per interview question", value: 2 },
];
