import { FC } from "react";
import { Label, SizesEnum } from "ui";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import { selectCompany, updateCompanyData } from "../../company/companySlice";
import {
  NotificationTypeEnum,
  showNotification,
} from "../../notificationsOverlay/notificationsSlice";
import { WelcomeAndOutroMessagesEditor } from "../../projects/projectSettings/welcomeAndOutroMessagesEditor";
import { ColorPicker } from "./colorPicker";
import { CompanyImageEditor } from "./companyLogoEditor";
import { CompanyNameDescriptionEditor } from "./companyNameDescriptionEditor";

const CompanySettingsSection: FC = () => {
  const dispatch = useAppDispatch();
  const company = useAppSelector(selectCompany);
  if (!company) return null;

  return (
    <div className="max-w-2xl min-w-[450px] mx-auto px-8 space-y-3">
      <CompanyNameDescriptionEditor
        onSave={(updates) => {
          dispatch(updateCompanyData({ id: company.id, updates }));

          showNotification(dispatch, {
            id: `company-updated-${new Date().getTime()}`,
            primaryMessage: `Organization successfully updated`,
            type: NotificationTypeEnum.SUCCESS,
          });
        }}
        name={company.name}
        description={company.description}
      />

      <div>
        <Label size={SizesEnum.SMALL}>Logo</Label>
        <CompanyImageEditor company={company} type="logo" />
      </div>

      <div>
        <Label size={SizesEnum.SMALL}>Favicon</Label>
        <CompanyImageEditor company={company} type="favicon" />
      </div>

      <div>
        <Label size={SizesEnum.SMALL} className="mb-2 block">
          Interview background color
        </Label>
        <ColorPicker
          color={company.settings.interview_color || "#172554"} // Default blue-950
          onChange={(color) => {
            dispatch(
              updateCompanyData({
                id: company.id,
                updates: {
                  settings: {
                    ...company.settings,
                    interview_color: color,
                  },
                },
              })
            );
          }}
        />
        <div className="mt-2 text-sm text-gray-600">
          Customize the background color of the interview page to match your
          brand.
        </div>
      </div>

      <WelcomeAndOutroMessagesEditor />
    </div>
  );
};

export default CompanySettingsSection;
