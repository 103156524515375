import { Contact, getContactDisplayNames } from "app-types";
import { formatDistanceToNow } from "date-fns";
import { FC } from "react";
import { getInitials } from "ui";

interface ContactNameWithTimeProps {
  contact: Contact;
  date?: Date;
}

function getColor(email: string) {
  const colors = [
    "bg-red-500",
    "bg-green-500",
    "bg-blue-500",
    "bg-indigo-500",
    "bg-orange-500",
    "bg-amber-500",
    "bg-yellow-500",
    "bg-lime-500",
    "bg-green-500",
    "bg-emerald-500",
    "bg-teal-500",
    "bg-cyan-500",
    "bg-sky-500",
    "bg-blue-500",
    "bg-indigo-500",
    "bg-rose-500",
    "bg-pink-500",
    "bg-fuchsia-500",
    "bg-purple-500",
    "bg-violet-500",
  ];

  // Use a hash function to consistently map the email to an index in the colors array
  const hashCode = email
    .split("")
    .reduce((acc, char) => acc + char.charCodeAt(0), 0);
  const colorIndex = hashCode % colors.length;

  return colors[colorIndex];
}

export const ContactNameWithTime: FC<ContactNameWithTimeProps> = ({
  contact,
  date,
}) => {
  const { primaryDisplayName, secondaryDisplayName, tertiaryDisplayName } =
    getContactDisplayNames(contact);

  const initials = getInitials(
    contact.email,
    contact.first_name,
    contact.last_name
  );

  const color = getColor(contact.id);

  return (
    <div className={`flex flex-col`}>
      <div className="flex items-center space-x-1">
        <div
          className={`h-6 w-6 mr-1 rounded-full text-center ${color} flex items-center justify-center text-white mt-0.5`}
          style={{ fontSize: "0.7rem" }}
        >
          {initials.toUpperCase()}
        </div>
        <div className="flex-1">
          <div className="flex items-center">
            <span className="font-bold text-gray-900 text-md">
              {primaryDisplayName}
            </span>
            {date && (
              <>
                <span className="mx-1">&middot;</span>
                <span className="text-gray-500 ">
                  {formatDistanceToNow(date, {
                    addSuffix: true,
                  })}
                </span>
              </>
            )}
          </div>
          {secondaryDisplayName && (
            <div className={`text-sm text-gray-600`}>
              {`${secondaryDisplayName}${
                tertiaryDisplayName ? `, ${tertiaryDisplayName}` : ""
              }`}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
