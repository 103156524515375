import { TrashIcon } from "@heroicons/react/24/outline";
import { CompanyInvitation, HandlerOf, Teammate } from "app-types";
import { format } from "date-fns";
import { FC, useState } from "react";
import {
  ButtonVariantsEnum,
  IconButton,
  ModalVariantsEnum,
  Pill,
  PillColorsEnum,
  SimpleModal,
  SizesEnum,
  TableSkeleton,
} from "ui";

interface TeammatesTableProps {
  teammates: Teammate[];
  pendingInvitations: CompanyInvitation[];
  onDeleteInvitation: HandlerOf<string>;
  isLoading: boolean;
  hasError: boolean;
}

export const TeammatesTable: FC<TeammatesTableProps> = ({
  pendingInvitations,
  teammates,
  onDeleteInvitation,
  isLoading,
  hasError,
}) => {
  const [invitationToDelete, setInvitationToDelete] = useState<string | null>(
    null
  );

  // Filter out any invitations for existing teammates
  const pendingInvitationsToRender = pendingInvitations.filter((i) => {
    return !teammates.find((t) => t.email === i.recipient_email);
  });

  const invitationRows = pendingInvitationsToRender.map((i) => {
    return {
      id: i.id,
      isInvitation: true,
      email: i.recipient_email,
      status: "Invited",
      dateAddedTimestamp: new Date(i.created_at).getTime(),
    };
  });

  const teammateRows = teammates.map((t) => {
    return {
      id: t.id,
      isInvitation: false,
      email: t.email,
      status: "Active",
      dateAddedTimestamp: new Date(t.created_at).getTime(),
    };
  });

  // Display rows sorted from newest to oldest
  const rows = [...invitationRows, ...teammateRows].sort(
    (a, b) => b.dateAddedTimestamp - a.dateAddedTimestamp
  );

  const renderTableRows = () => {
    if (isLoading) return <TableSkeleton />;

    if (hasError)
      return (
        <tr>
          <td
            colSpan={3}
            className="px-6 py-4 text-center text-sm text-red-500"
          >
            An error occurred. Please refresh the page and try again.
          </td>
        </tr>
      );

    return (
      <>
        {rows.map((row) => (
          <tr className="group relative" key={row.id}>
            <td className="overflow-hidden text-ellipsis px-6 py-2 whitespace-nowrap text-sm text-gray-800">
              {row.email}
            </td>
            <td className="overflow-hidden text-ellipsis px-6 py-2 whitespace-nowrap text-sm text-gray-800">
              <Pill
                label={row.status}
                size={SizesEnum.SMALL}
                color={
                  row.isInvitation
                    ? PillColorsEnum.YELLOW
                    : PillColorsEnum.GREEN
                }
              />
            </td>
            <td className="overflow-hidden text-ellipsis px-6 py-2 whitespace-nowrap text-sm text-gray-800">
              {format(new Date(row.dateAddedTimestamp), "MMM dd yyyy")}
              {row.isInvitation && (
                <div className="text-gray-500 absolute right-2 top-3 cursor-pointer hidden group-hover:block">
                  <IconButton
                    variant={ButtonVariantsEnum.Secondary}
                    icon={<TrashIcon className="h-4 w-4" />}
                    onClick={() => {
                      setInvitationToDelete(row.id);
                    }}
                  />
                </div>
              )}
            </td>
          </tr>
        ))}
      </>
    );
  };

  return (
    <>
      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
        <table className="min-w-full divide-y divide-gray-200 table-fixed w-full">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="w-[55%] px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Email
              </th>
              <th
                scope="col"
                className="w-[20%] px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Status
              </th>
              <th
                scope="col"
                className="w-[25%] px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Date added
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {renderTableRows()}
          </tbody>
        </table>
      </div>
      <SimpleModal
        isOpen={Boolean(invitationToDelete)}
        onCancel={() => {
          setInvitationToDelete(null);
        }}
        variant={ModalVariantsEnum.Warning}
        title="Revoke invitation"
        subtitle="Are you sure you want to cancel this invitation?"
        confirmButtonText="Cancel invitation"
        onConfirm={() => {
          invitationToDelete && onDeleteInvitation(invitationToDelete);
          setInvitationToDelete(null);
        }}
      />
    </>
  );
};
