import { Slice, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  Contact,
  InterviewLoaded,
  KeywordTrackerMatches,
  SurveyInsights,
  TranscriptFragment,
  TrendData,
  TrendDataTypesEnum,
} from "app-types";
import { getAxiosInstanceWithAuth } from "../../api/axiosConfig";
import { RootState } from "../../app/store";

interface InsightsParameters {
  projectId: string;
  // In the future, add things like date ranges or search filters.
  // For now we're loading all transcriptFragments for a project
}

export interface InsightsState {
  parameters: InsightsParameters | undefined;
  interviewIds: string[];
  transcriptFragmentIds: string[];
  contactIds: string[];
  keywordTrackerIdToMatches: Record<string, KeywordTrackerMatches>;
  surveySummaries: SurveyInsights[];
  trends: TrendData[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: InsightsState = {
  parameters: undefined,
  interviewIds: [],
  transcriptFragmentIds: [],
  contactIds: [],
  keywordTrackerIdToMatches: {},
  surveySummaries: [],
  trends: [],
  status: "idle",
  error: null,
};

interface FetchInsightsPayload {
  interviews: InterviewLoaded[];
  transcript_fragments: TranscriptFragment[];
  contacts: Contact[];
  keyword_tracker_id_to_matches: Record<string, KeywordTrackerMatches>;
  survey_summaries: SurveyInsights[];
  trends: TrendData[];
}

export const fetchInsightsAsync = createAsyncThunk<
  FetchInsightsPayload,
  InsightsParameters,
  { rejectValue: string }
>(
  "insights/fetchInsights",
  async (parameters: InsightsParameters, thunkAPI) => {
    try {
      const axios = await getAxiosInstanceWithAuth();
      const response = await axios.post(`/insights/list_transcript_fragments`, {
        project_id: parameters.projectId,
      });

      return thunkAPI.fulfillWithValue(response.data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const insightsSlice: Slice<InsightsState> = createSlice({
  name: "insights",
  initialState,
  reducers: {
    clearInsightsParameters: (state) => {
      state.parameters = undefined;
      state.interviewIds = [];
      state.transcriptFragmentIds = [];
      state.contactIds = [];
      state.surveySummaries = [];
      state.trends = [];
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInsightsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchInsightsAsync.fulfilled, (state, action) => {
        state.status = "succeeded";

        // Maintain pointers to relevant IDs to prevent them from being cleaned up.
        state.interviewIds = action.payload.interviews.map(
          (interview) => interview.id
        );
        state.transcriptFragmentIds = action.payload.transcript_fragments.map(
          (transcriptFragment) => transcriptFragment.id
        );
        state.contactIds = action.payload.contacts.map((contact) => contact.id);
        state.keywordTrackerIdToMatches =
          action.payload.keyword_tracker_id_to_matches;
        // For now, survey_summaries will only ever be an empty array or have 1 member.
        // But in the future we may have more than one survey.
        state.surveySummaries = action.payload.survey_summaries.length
          ? action.payload.survey_summaries
          : [];

        state.trends = action.payload.trends;
      })
      .addCase(fetchInsightsAsync.rejected, (state, action) => {
        state.status = "failed";
        state.interviewIds = [];
        state.transcriptFragmentIds = [];
        state.contactIds = [];
        state.surveySummaries = [];
        state.trends = [];
        state.error = action.error.message || null;
      });
  },
});

export const { clearInsightsParameters } = insightsSlice.actions;
export default insightsSlice.reducer;

export const selectInsightsLoadingState = (state: RootState) =>
  state.insights.status;
export const selectInsightsInterviewIds = (state: RootState) =>
  state.insights.interviewIds;

export const selectKeywordTrackerIdToMatches = (state: RootState) =>
  state.insights.keywordTrackerIdToMatches;
export const selectKeywordTrackerMatchesForTrackerId =
  (keywordTrackerId: string) => (state: RootState) =>
    state.insights.keywordTrackerIdToMatches[keywordTrackerId];
export const selectSurveySummaries = (state: RootState) =>
  state.insights.surveySummaries;
export const selectSentimentTrendData = (state: RootState) => {
  return state.insights.trends.find(
    (data) => data.type === TrendDataTypesEnum.SENTIMENT
  );
};
