import {
  CheckCircleIcon,
  LanguageIcon,
  TrashIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import {
  ASSESSMENT_FIELD_NAME_MAX_LENGTH,
  CANDIDATE_ALIGN_FIELD_NAME,
  CefrAssessmentFieldNameToLanguage,
  CustomFieldDefinition,
  CustomFieldDefinitionInsertClient,
  LANGUAGE_DISPLAY_NAMES,
} from "app-types";
import React from "react";
import {
  ButtonVariantsEnum,
  IconButton,
  Textarea,
  TextareaVariantsEnum,
} from "ui";

interface AssessmentEditorRowProps {
  field: CustomFieldDefinition | CustomFieldDefinitionInsertClient;
  onRenameField: (newName: string) => void;
  onBlur: (value: string) => void;
  onRemoveField: () => void;
  fieldError: string;
}

export const AssessmentEditorRow: React.FC<AssessmentEditorRowProps> = ({
  field,
  onRenameField,
  onBlur,
  onRemoveField,
  fieldError,
}) => {
  const renderFieldContent = () => {
    if (field.field_name in CefrAssessmentFieldNameToLanguage && !fieldError) {
      return (
        <div className="flex items-center space-x-2">
          <LanguageIcon className="w-4 h-4 mr-2" />
          CEFR{" "}
          {
            LANGUAGE_DISPLAY_NAMES[
              CefrAssessmentFieldNameToLanguage[
                field.field_name as keyof typeof CefrAssessmentFieldNameToLanguage
              ]
            ]
          }{" "}
          Level Assessment
        </div>
      );
    }

    if (field.display_name === CANDIDATE_ALIGN_FIELD_NAME && !fieldError) {
      return (
        <div className="flex items-center space-x-2">
          <UserIcon className="w-4 h-4 mr-2" />
          Candidate/Job Alignment
        </div>
      );
    }

    return (
      <div>
        <Textarea
          value={field.display_name}
          onChange={(e) => onRenameField(e.target.value)}
          onBlur={(e) => onBlur(e.target.value)}
          placeholder="Has 2+ years of customer service experience"
          errorDescription={fieldError}
          maxLength={ASSESSMENT_FIELD_NAME_MAX_LENGTH}
          variant={TextareaVariantsEnum.MINIMAL}
          shouldDisableNewline
        />
      </div>
    );
  };

  return (
    <tr className="group relative border-b border-gray-900/5">
      <td className="px-3 py-2 text-sm text-gray-900">
        <div className="flex flex-row items-center w-full">
          <CheckCircleIcon className="w-4 h-4 text-green-600 mr-2 flex-shrink-0" />
          <div className="w-full">{renderFieldContent()}</div>
        </div>
      </td>
      <td className="absolute right-2 top-1/2 -translate-y-1/2">
        <div className="invisible group-hover:visible">
          <IconButton
            variant={ButtonVariantsEnum.Secondary}
            icon={<TrashIcon className="w-4 h-4 text-red-500" />}
            onClick={onRemoveField}
          />
        </div>
      </td>
    </tr>
  );
};
