import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { InterviewDecisionEnum } from "app-types";
import { InfoTooltip } from "ui";

export const InterviewDecisionIndicator = ({
  decision,
}: {
  decision: InterviewDecisionEnum | null;
}) => {
  if (decision === InterviewDecisionEnum.ADVANCED) {
    return (
      <InfoTooltip
        id={`decision-advanced`}
        place="right"
        content="Candidate was advanced"
      >
        <CheckCircleIcon className="h-4 w-4 flex-shrink-0 text-green-500" />
      </InfoTooltip>
    );
  }

  if (decision === InterviewDecisionEnum.REJECTED) {
    return (
      <InfoTooltip
        id={`decision-rejected`}
        place="right"
        content="Candidate was rejected"
      >
        <XCircleIcon className="h-4 w-4 flex-shrink-0 text-red-500" />
      </InfoTooltip>
    );
  }

  return null;
};
