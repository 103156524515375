import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
import { Dictionary } from "@reduxjs/toolkit";
import {
  Contact,
  Interview,
  KeywordTracker,
  KeywordTrackerTypesEnum,
} from "app-types";
import { FC, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, ButtonVariantsEnum, PillColorsEnum, joinClassnames } from "ui";
import { useAppSelector } from "../../../hooks/hook";
import { selectTranscriptFragmentsByKeyword } from "../../transcriptFragments/transcriptFragmentsSlice";
import { ContactNameWithTranscriptFragment } from "../contactNameWithTranscriptFragment";
import { InsightBar } from "../insightBar";
import { selectKeywordTrackerMatchesForTrackerId } from "../insightsSlice";
import { KeywordAnalysisCard } from "./keywordAnalysisCard";

export interface KeywordTrackerCardProps {
  keywordTracker: KeywordTracker;
  contactsDictionary: Dictionary<Contact>;
  interviewsDictionary: Dictionary<Interview>;
  interviewCount: number;
}

// Currently this card is only used for competitive keyword trackers
export const KeywordTrackerCard: FC<KeywordTrackerCardProps> = ({
  keywordTracker,
  contactsDictionary,
  interviewsDictionary,
  interviewCount,
}) => {
  const navigate = useNavigate();
  const [isQuoteSectionExpanded, setIsQuoteSectionExpanded] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { keywords } = keywordTracker;
  const keywordTrackerMatches = useAppSelector(
    selectKeywordTrackerMatchesForTrackerId(keywordTracker.id)
  );

  const sortedKeywords = useMemo(() => {
    return [...keywords].sort((a, b) => {
      // Sort keywords from most to least matches
      const aMatchCount =
        keywordTrackerMatches[a.phrase]?.interview_ids.length || 0;
      const bMatchCount =
        keywordTrackerMatches[b.phrase]?.interview_ids.length || 0;
      return bMatchCount - aMatchCount;
    });
  }, [keywords, keywordTrackerMatches]);

  const selectedKeyword = sortedKeywords[selectedIndex];

  const transcriptFragments = useAppSelector(
    selectTranscriptFragmentsByKeyword(keywordTracker.id, selectedKeyword)
  );

  const renderQuotesSection = () => {
    if (transcriptFragments.length === 0)
      return (
        <div>{`"${selectedKeyword.phrase}" hasn't appeared in any interviews yet.`}</div>
      );

    // If we don't have any analysis, we just show the quotes without any ability to show/hide
    if (!isQuoteSectionExpanded && selectedKeyword.analyses.length > 0) {
      return (
        <div>
          <Button
            variant={ButtonVariantsEnum.Secondary}
            icon={<PlusIcon className="h-4 w-4 mr-2" />}
            label="Show interview quotes"
            onClick={() => setIsQuoteSectionExpanded(true)}
          />
        </div>
      );
    }

    return (
      <>
        {transcriptFragments.map((tf) => {
          const interview = interviewsDictionary[tf.interview_id];
          if (!interview) return null; // Shouldn't happen with proper state management

          const contact = contactsDictionary[interview.contact_id];
          if (!contact) return null; // Shouldn't happen with proper state management

          if (!tf.text_transcript) return null;

          return (
            <ContactNameWithTranscriptFragment
              key={`keyword-tf-${tf.id}`}
              transcriptFragments={[tf]}
              date={new Date(tf?.start_time)}
              contact={contact}
              onClick={() => {
                // Add interview_id query parameter to the current location
                navigate({
                  search: `?interview_id=${interview.id}&f=${tf.id}`,
                });
              }}
              shouldHideQuestion
            />
          );
        })}
        {selectedKeyword.analyses.length > 0 ? (
          <div>
            <Button
              variant={ButtonVariantsEnum.Secondary}
              icon={<MinusIcon className="h-4 w-4 mr-2" />}
              label="Hide interview quotes"
              onClick={() => setIsQuoteSectionExpanded(false)}
            />
          </div>
        ) : null}
      </>
    );
  };

  if (sortedKeywords.length === 0) {
    return (
      <div className="flex bg-white flex-row divide-y divide-gray-100 text-sm leading-6">
        <div className="p-4">
          As more interviews are completed, AI-generated themes will be shown
          here.
        </div>
      </div>
    );
  }

  return (
    <div className="flex bg-white flex-row divide-y divide-gray-100 text-sm leading-6">
      <div className="flex-shrink-0 w-[200px] overflow-y-auto max-h-[1000px]">
        {sortedKeywords.map((entry, index) => {
          const interviewPercentage =
            interviewCount > 0
              ? Math.round(
                  ((keywordTrackerMatches[entry.phrase]?.interview_ids.length ||
                    0) /
                    interviewCount) *
                    100
                )
              : 0;

          return (
            <div
              key={`keyword-${index}`}
              className={joinClassnames(
                `p-3 cursor-pointer ${
                  index !== 0 ? "border-t border-gray-900/5" : ""
                }`,
                selectedIndex === index
                  ? "bg-gray-50 text-indigo-600"
                  : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50"
              )}
              onClick={() => setSelectedIndex(index)}
            >
              <div className="text-sm font-semibold ">{entry.phrase}</div>
              <div
                className={joinClassnames(
                  "mt-2 flex text-sm",
                  selectedIndex === index ? "text-indigo-600" : "text-gray-500"
                )}
              >
                <InsightBar
                  color={PillColorsEnum.BLUE}
                  percentageWidth={interviewPercentage}
                  label={`${interviewPercentage}% of interviews`}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className="border-l flex flex-col space-y-4 p-4 overflow-y-auto max-h-[1000px]">
        {keywordTracker.type === KeywordTrackerTypesEnum.COMPETITIVE
          ? selectedKeyword.analyses.map((a) => (
              <KeywordAnalysisCard
                keywordAnalysis={a}
                competitorName={selectedKeyword.phrase}
              />
            ))
          : null}
        {renderQuotesSection()}
      </div>
    </div>
  );
};
