import { Assessment, CustomField, ProjectPublicAPI } from "../../index.ts";
import { InterviewQuestion } from "../client/client.ts";
import { Tables, TablesInsert, TablesUpdate } from "../db/supabase.ts";
import { ComplexAnswerPublicAPI } from "./complexAnswer.ts";
import { BasicContact, ContactDB } from "./contact.ts";
import { TranscriptFragmentPublicAPI } from "./transcriptFragment.ts";

export enum InterviewStatusEnum {
  PENDING = "pending",
  IN_PROGRESS = "in_progress",
  COMPLETED = "completed",
  CANCELLED = "cancelled",
}

export enum InterviewDecisionEnum {
  ADVANCED = "advanced",
  REJECTED = "rejected",
}

export type InterviewCallMetadata = {
  id: string;
  call_id: string;
  agent_id: string;
  start_time: string | undefined;
  end_time: string | undefined;
  twilio_recording_id: string | undefined;
  recording_url: string | undefined;
  raw_video_key: string | undefined; // S3 key for the raw video
  processed_video_key: string | undefined; // S3 key for the processed video
};

export type CompletedInterviewCallMetadata = InterviewCallMetadata & {
  start_time: string;
  end_time: string;
};

export function isCompletedCallMetadata(
  call: InterviewCallMetadata
): call is CompletedInterviewCallMetadata {
  return !!call.start_time && !!call.end_time;
}

export type InterviewMetadata = {
  feedback: InterviewFeedback | null;
  calls: InterviewCallMetadata[];
};

export type InterviewDB = Tables<"interview">;
export type InterviewInsert = Omit<TablesInsert<"interview">, "questions"> & {
  questions?: InterviewQuestion[];
};
export type InterviewUpdate = Omit<TablesUpdate<"interview">, "questions"> & {
  questions?: InterviewQuestion[];
};
export type Interview = Omit<
  Tables<"interview">,
  "questions" | "status" | "reward" | "insights" | "metadata"
> & {
  questions: InterviewQuestion[];
  status: InterviewStatusEnum;
  decision: InterviewDecisionEnum | null;
  reward: InterviewReward | null;
  insights: InterviewInsights;
  metadata: InterviewMetadata | null;
};

export interface InterviewLoaded extends Interview {
  custom_fields: CustomField[];
}

type InterviewReward = {
  ext_id: string;
  type: string;
  value_denomination: number;
  value_currency_code: string;
  reward_link: string;
  sent_at: string;
};

export type InterviewWithBasicContact = Omit<Interview, "contact_id"> & {
  contact: BasicContact;
};

export type InterviewInsights = {
  summary: string | null;
  sentiment_score: number | null;
  key_positive_quotes: string[] | null;
  key_negative_quotes: string[] | null;
  assessment_score: number | null;
  strengths: string[] | null;
  weaknesses: string[] | null;
  unanswered_candidate_questions: string[] | null;
};

export enum InterviewFeedbackRatingsEnum {
  GREAT = "Great",
  REPETITIVE = "Repetitive",
  MISSING_FOCUS_AREAS = "Missing focus areas",
  INACCURATE_ASSESSMENT = "Inaccurate assessment",
  OTHER = "Other feedback",
}

export type InterviewFeedback = {
  rating: InterviewFeedbackRatingsEnum;
  text: string | null;
};

type InterviewInsightsPublicAPI = {
  summary: string | null;
  sentiment_score: number | null;
  assessment_score: number | null;
  key_positive_quotes: string[] | null;
  key_negative_quotes: string[] | null;
  strengths: string[] | null;
  weaknesses: string[] | null;
};

export type AssessmentCriteriaPublicAPI = {
  name: string;
  value: string | number | Date | boolean | null | undefined;
  commentary: string | null;
};

export type InterviewPublicAPI = {
  id: string;
  project_id: string;
  project: ProjectPublicAPI;
  status: InterviewStatusEnum;
  contact: BasicContact;
  started_at: string;
  completed_at: string | null;
  transcript_fragments: TranscriptFragmentPublicAPI[];
  survey_answers: ComplexAnswerPublicAPI[];
  insights: InterviewInsightsPublicAPI | null;
  calls: InterviewPublicAPICall[];
  assessment_criteria: AssessmentCriteriaPublicAPI[] | undefined;
  decision: InterviewDecisionEnum | null;
};

export type InterviewPublicAPICall = {
  id: string;
  recording_url: string;
};

export type WindowEventMetadata = {
  duration: number; // In seconds
  seconds_from_start: number;
  call_id: string;
};

export type InterviewCheatingRiskSummary = {
  num_significant_calls: number;
  long_pauses: WindowEventMetadata[];
  fullscreen_exits: WindowEventMetadata[];
  window_blurs: WindowEventMetadata[];
};

export const EMPTY_INTERVIEW_INSIGHTS: InterviewInsights = {
  summary: null,
  sentiment_score: null,
  assessment_score: null,
  key_positive_quotes: null,
  key_negative_quotes: null,
  strengths: null,
  weaknesses: null,
  unanswered_candidate_questions: null,
};

export const mapInterviewDbToInterview = (interviewDb: InterviewDB) => {
  const questions = (interviewDb.questions || []) as InterviewQuestion[];

  const status = interviewDb.status as InterviewStatusEnum;
  const reward = interviewDb.reward as InterviewReward;
  const insights: InterviewInsights = {
    ...EMPTY_INTERVIEW_INSIGHTS,
    ...(typeof interviewDb.insights === "object" ? interviewDb.insights : {}),
  };

  const interview: Interview = {
    ...interviewDb,
    questions: questions,
    status: status,
    decision: interviewDb.decision as InterviewDecisionEnum | null,
    reward: reward,
    insights: insights,
    metadata: (interviewDb.metadata as InterviewMetadata) || null,
  };

  return interview;
};

export const mapInterviewDbToInterviewLoaded = (
  interviewDb: InterviewDB,
  customFields: CustomField[]
) => {
  const interview = mapInterviewDbToInterview(interviewDb);

  const interviewLoaded: InterviewLoaded = {
    ...interview,
    custom_fields: customFields,
  };

  return interviewLoaded;
};

export const mapInterviewAndContactToInterviewWithContact = (
  interview: Interview,
  contact: ContactDB
): InterviewWithBasicContact => {
  return {
    ...interview,
    contact: {
      email: contact.email,
      phone_number: contact.phone_number,
      first_name: contact.first_name || null,
      last_name: contact.last_name || null,
    },
  } as InterviewWithBasicContact;
};

export const mapInterviewInsightsToPublicAPIInsights = (
  insights: InterviewInsights
) => {
  return {
    summary: insights.summary,
    sentiment_score: insights.sentiment_score,
    assessment_score: insights.assessment_score,
    key_positive_quotes: insights.key_positive_quotes,
    key_negative_quotes: insights.key_negative_quotes,
    strengths: insights.strengths,
    weaknesses: insights.weaknesses,
  } as InterviewInsightsPublicAPI;
};

export const mapInterviewAssessmentFieldsToPublicAPIFormat = (
  assessment: Assessment,
  customFields: CustomField[]
) => {
  // Return an array with an entry for each assessment criteria, even if the custom field is not present
  return assessment.custom_field_definitions.map((definition) => {
    const matchingFieldValue = customFields.find(
      (field) => field.custom_field_definition_id === definition.id
    );

    return {
      name: definition.display_name,
      value: matchingFieldValue?.data.value,
      commentary: matchingFieldValue?.data.display_value || null,
    };
  });
};
