import { ComplexQuestionOption, reorderArrayItems } from "app-types";
import { FC } from "react";
import { Button, ButtonVariantsEnum, InfoTooltip } from "ui";
import { SurveyOptionEditor } from "./surveyOptionEditor";

const DEFAULT_MAX_SURVEY_OPTIONS = 10;

interface SurveyOptionsEditorProps {
  options: ComplexQuestionOption[];
  onOptionsChange: (options: ComplexQuestionOption[]) => void;
  maxNumberOfOptions?: number;
}

export const SurveyOptionsEditor: FC<SurveyOptionsEditorProps> = ({
  options,
  onOptionsChange,
  maxNumberOfOptions,
}) => {
  const maxNumberOfSurveyOptions =
    maxNumberOfOptions || DEFAULT_MAX_SURVEY_OPTIONS;

  return (
    <>
      <div className="flex flex-col space-y-2 my-2">
        {options.map((option, index) => {
          const isDuplicate = options.some(
            (o, i) => i !== index && o.name && o.name === option.name
          );

          return (
            <div key={`surveyoption-${index}`}>
              <SurveyOptionEditor
                value={option.name}
                onChange={(newValue: string) => {
                  onOptionsChange(
                    options.map((o, i) => {
                      return i === index ? { name: newValue } : o;
                    })
                  );
                }}
                onRemove={() => {
                  onOptionsChange(options.filter((_, i) => i !== index));
                }}
                onClickReorder={(direction: "up" | "down") => {
                  const newOptions = reorderArrayItems(
                    options,
                    index,
                    direction
                  );
                  if (newOptions) onOptionsChange(newOptions);
                }}
                isDuplicate={isDuplicate}
              />
            </div>
          );
        })}
      </div>
      <div className="mt-2 flex">
        <InfoTooltip
          id="add-option-tooltip"
          place="right"
          content={
            options.length >= maxNumberOfSurveyOptions ? (
              <span>
                We support at most {maxNumberOfSurveyOptions} survey options
                <br />
                to ensure a high-quality interview experience
              </span>
            ) : undefined
          }
        >
          <Button
            variant={ButtonVariantsEnum.Secondary}
            label="Add option"
            isDisabled={options.length >= maxNumberOfSurveyOptions}
            onClick={() => {
              onOptionsChange([...options, { name: "" }]);
            }}
          />
        </InfoTooltip>
      </div>
    </>
  );
};
