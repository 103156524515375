import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { isRecruitingModeProject } from "app-types";
import {
  InterviewDecisionEnum,
  InterviewLoaded,
} from "app-types/src/entities/interview";
import { FC, useState } from "react";
import { Button, ButtonVariantsEnum, ModalVariantsEnum, SimpleModal } from "ui";
import { SizesEnum } from "ui/src/helpers/helpers";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import { selectProjectById } from "../../projects/projectsSlice";
import { updateInterview } from "../interviewsSlice";

interface InterviewDecisionButtonsProps {
  interview: InterviewLoaded;
}

export const InterviewDecisionButtons: FC<InterviewDecisionButtonsProps> = ({
  interview,
}) => {
  const dispatch = useAppDispatch();
  const [showUndoDialog, setShowUndoDialog] = useState(false);

  const project = useAppSelector(selectProjectById(interview.project_id));
  if (!project || !isRecruitingModeProject(project)) {
    return null;
  }

  const onDecisionChange = (decision: InterviewDecisionEnum | null) => {
    dispatch(
      updateInterview({
        projectId: interview.project_id,
        interviewId: interview.id,
        update: {
          decision,
        },
      })
    );

    if (decision === null) {
      setShowUndoDialog(false);
    }
  };

  if (interview.decision === InterviewDecisionEnum.ADVANCED) {
    return (
      <>
        <Button
          variant={ButtonVariantsEnum.Secondary}
          onClick={() => setShowUndoDialog(true)}
          size={SizesEnum.MEDIUM}
          icon={
            <CheckCircleIcon
              className="h-4 w-4 mr-1 text-green-600"
              aria-hidden="true"
            />
          }
          label="Advanced"
        />
        <SimpleModal
          isOpen={showUndoDialog}
          onCancel={() => setShowUndoDialog(false)}
          onConfirm={() => onDecisionChange(null)}
          title="Undo Advancement"
          subtitle="Are you sure you want to undo advancing this candidate?"
          confirmButtonText="Undo"
          variant={ModalVariantsEnum.Standard}
        />
      </>
    );
  }

  if (interview.decision === InterviewDecisionEnum.REJECTED) {
    return (
      <>
        <Button
          variant={ButtonVariantsEnum.Secondary}
          onClick={() => setShowUndoDialog(true)}
          size={SizesEnum.MEDIUM}
          icon={
            <XCircleIcon
              className="h-4 w-4 mr-1 text-red-600"
              aria-hidden="true"
            />
          }
          label="Rejected"
        />
        <SimpleModal
          isOpen={showUndoDialog}
          onCancel={() => setShowUndoDialog(false)}
          onConfirm={() => onDecisionChange(null)}
          title="Undo Rejection"
          subtitle="Are you sure you want to undo rejecting this candidate?"
          confirmButtonText="Undo"
          variant={ModalVariantsEnum.Standard}
        />
      </>
    );
  }

  return (
    <>
      <Button
        variant={ButtonVariantsEnum.Secondary}
        onClick={() => onDecisionChange(InterviewDecisionEnum.ADVANCED)}
        size={SizesEnum.MEDIUM}
        icon={
          <CheckCircleIcon
            className="h-4 w-4 mr-1 text-green-600"
            aria-hidden="true"
          />
        }
        label="Advance"
      />
      <Button
        variant={ButtonVariantsEnum.Secondary}
        onClick={() => onDecisionChange(InterviewDecisionEnum.REJECTED)}
        size={SizesEnum.MEDIUM}
        icon={
          <XCircleIcon
            className="h-4 w-4 mr-1 text-red-600"
            aria-hidden="true"
          />
        }
        label="Reject"
      />
    </>
  );
};
