import { TrashIcon } from "@heroicons/react/24/outline";
import { ApiKey, LoadingStatesEnum } from "app-types";
import { format } from "date-fns";
import { FC, useEffect, useState } from "react";
import {
  Button,
  ButtonVariantsEnum,
  IconButton,
  Link,
  TableSkeleton,
} from "ui";
import { getAxiosInstanceWithAuth } from "../../../api/axiosConfig";
import { useAppDispatch } from "../../../hooks/hook";
import { CreateApiKeyModal } from "./createApiKeyModal";
import { DeleteApiKeyModal } from "./deleteApiKeyModal";

export const DeveloperSettingsSection: FC = () => {
  const dispatch = useAppDispatch();

  const [listApiKeysLoadingState, setListApiKeysLoadingState] =
    useState<LoadingStatesEnum>(LoadingStatesEnum.LOADED);
  const [apiKeys, setApiKeys] = useState<ApiKey[]>([]);
  const [apiKeyToDelete, setApiKeyToDelete] = useState<ApiKey | null>(null);
  const [isCreateApiKeyModalOpen, setIsCreateApiKeyModalOpen] = useState(false);

  const fetchApiKeys = async () => {
    setListApiKeysLoadingState(LoadingStatesEnum.LOADING);
    try {
      const axios = await getAxiosInstanceWithAuth();
      const { data } = await axios.get(`/api-keys`);

      setApiKeys(data);
      setListApiKeysLoadingState(LoadingStatesEnum.LOADED);
    } catch (err) {
      setListApiKeysLoadingState(LoadingStatesEnum.ERROR);
    }
  };

  useEffect(() => {
    fetchApiKeys();
  }, []);

  const renderTableRows = () => {
    if (listApiKeysLoadingState === LoadingStatesEnum.LOADING)
      return <TableSkeleton />;

    if (listApiKeysLoadingState === LoadingStatesEnum.ERROR)
      return (
        <tr>
          <td
            colSpan={3}
            className="px-6 py-4 text-center text-sm text-red-500"
          >
            An error occurred. Please refresh the page and try again.
          </td>
        </tr>
      );

    if (apiKeys.length === 0)
      return (
        <tr>
          <td
            className="px-3 py-9 text-center text-sm font-semibold text-gray-700"
            colSpan={4}
          >
            Create an API key to use the Alpharun API
          </td>
        </tr>
      );

    return (
      <>
        {apiKeys.map((apiKey) => (
          <tr className="group relative" key={apiKey.id}>
            <td className="overflow-hidden text-ellipsis px-6 py-2 whitespace-nowrap text-sm text-gray-800">
              {apiKey.name}
            </td>
            <td className="overflow-hidden text-ellipsis px-6 py-2 whitespace-nowrap text-sm text-gray-800">
              {`*****${apiKey.key_suffix}`}
            </td>
            <td className="overflow-hidden text-ellipsis px-6 py-2 whitespace-nowrap text-sm text-gray-800">
              {format(new Date(apiKey.created_at), "MMM dd yyyy")}
              {
                <div className="text-gray-500 absolute right-2 top-1.5 cursor-pointer hidden group-hover:block">
                  <IconButton
                    variant={ButtonVariantsEnum.Secondary}
                    icon={<TrashIcon className="h-4 w-4" />}
                    onClick={() => {
                      setApiKeyToDelete(apiKey);
                    }}
                  />
                </div>
              }
            </td>
          </tr>
        ))}
      </>
    );
  };

  return (
    <div className="max-w-2xl min-w-[450px] mx-auto px-8 py-6">
      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
        <table className="min-w-full divide-y divide-gray-200 table-fixed w-full">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="w-[45%] px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Name
              </th>
              <th
                scope="col"
                className="w-[30%] px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                API Key
              </th>
              <th
                scope="col"
                className="w-[25%] px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Date Created
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {renderTableRows()}
          </tbody>
        </table>
      </div>
      <div className="mt-4 text-sm text-gray-600">
        API keys allow you to use the Alpharun API.{" "}
        <Link href="https://help.alpharun.com/en/articles/2476993">
          View API documentation here
        </Link>
        {"."}
      </div>
      <div className="mt-4">
        <Button
          variant={ButtonVariantsEnum.Primary}
          label="Create API Key"
          onClick={() => {
            setIsCreateApiKeyModalOpen(true);
          }}
        />
      </div>
      <CreateApiKeyModal
        isOpen={isCreateApiKeyModalOpen}
        onClose={() => {
          setIsCreateApiKeyModalOpen(false);
          fetchApiKeys();
        }}
      />
      <DeleteApiKeyModal
        isOpen={Boolean(apiKeyToDelete)}
        onClose={() => {
          setApiKeyToDelete(null);
          fetchApiKeys();
        }}
        apiKeyToDelete={apiKeyToDelete}
      />
    </div>
  );
};
