export const SUPABASE_PUBLIC_STORAGE_BUCKET_NAME = "public-assets";
export enum PublicStorageFoldersEnum {
  LOGOS = "logos",
  FAVICONS = "favicons",
}
export const getLogoUrl = (appUrl: string, logo_file_name: string) => {
  return `${appUrl}/storage/v1/object/public/${SUPABASE_PUBLIC_STORAGE_BUCKET_NAME}/${PublicStorageFoldersEnum.LOGOS}/${logo_file_name}`;
};

export const getFaviconUrl = (appUrl: string, favicon_file_name: string) => {
  return `${appUrl}/storage/v1/object/public/${SUPABASE_PUBLIC_STORAGE_BUCKET_NAME}/${PublicStorageFoldersEnum.FAVICONS}/${favicon_file_name}`;
};
