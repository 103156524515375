import { Menu, Transition } from "@headlessui/react";
import React, { FC } from "react";
import { joinClassnames } from "../helpers/helpers";

export interface DropdownItem {
  label: string;
  onClick: () => void;
}

interface DropdownMenuProps {
  items: DropdownItem[];
  align?: "left" | "right";
}

export const DropdownMenu: FC<DropdownMenuProps> = ({
  items,
  align = "right",
}) => {
  return (
    <Transition
      as={React.Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items
        className={joinClassnames(
          "absolute z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none",
          align === "right" ? "right-0" : "left-0"
        )}
      >
        {items.map((i) => {
          return (
            <Menu.Item key={"dropdown-item-" + i.label}>
              {({ active }) => (
                <a
                  className={joinClassnames(
                    active ? "bg-gray-100" : "",
                    "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                  )}
                  onClick={i.onClick}
                >
                  {i.label}
                </a>
              )}
            </Menu.Item>
          );
        })}
      </Menu.Items>
    </Transition>
  );
};
