import {
  EntityState,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  CustomFieldDefinition,
  CustomFieldMetadata,
  IntegrationProvidersEnum,
  LoadingStatesEnum,
} from "app-types";
import { Selector } from "react-redux";
import { getAxiosInstanceWithAuth } from "../../api/axiosConfig";
import { RootState } from "../../app/store";

export const customFieldDefinitionsAdapter =
  createEntityAdapter<CustomFieldDefinition>();

const initialState: EntityState<CustomFieldDefinition> & {
  status: LoadingStatesEnum;
  error: string | null;
} = customFieldDefinitionsAdapter.getInitialState({
  status: LoadingStatesEnum.LOADING,
  error: null,
});

export const setCustomFieldDefinitionsForProvider = createAsyncThunk<
  CustomFieldDefinition[],
  {
    provider: IntegrationProvidersEnum;
    customFieldMetadatas: CustomFieldMetadata[];
  },
  { rejectValue: string }
>(
  "customFieldDefinitions/setCustomFieldDefinitionsForProvider",
  async ({ provider, customFieldMetadatas }, thunkAPI) => {
    try {
      const axios = await getAxiosInstanceWithAuth();
      const response = await axios.put(`/custom_fields/provider/${provider}`, {
        custom_field_metadatas: customFieldMetadatas,
      });

      return thunkAPI.fulfillWithValue(response.data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const customFieldDefinitionsSlice = createSlice({
  name: "customFieldDefinitions",
  initialState,
  reducers: {
    setAllCustomFieldDefinitions: customFieldDefinitionsAdapter.setAll,
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        setCustomFieldDefinitionsForProvider.pending,
        (state, action) => {
          state.status = LoadingStatesEnum.LOADING;
        }
      )
      .addCase(
        setCustomFieldDefinitionsForProvider.fulfilled,
        (state, action) => {
          state.status = LoadingStatesEnum.LOADED;

          console.log("payload to upsert");
          console.log(action.payload);

          customFieldDefinitionsAdapter.upsertMany(state, action.payload);
        }
      )
      .addCase(
        setCustomFieldDefinitionsForProvider.rejected,
        (state, action) => {
          state.status = LoadingStatesEnum.ERROR;
          state.error = action.error.message || null;
        }
      );
  },
});

export const { setAllCustomFieldDefinitions } =
  customFieldDefinitionsSlice.actions;
export default customFieldDefinitionsSlice.reducer;

// Selector to get all customFieldDefinitions for a specific provider
export const selectCustomFieldDefinitionsByProvider = (
  provider: IntegrationProvidersEnum
): Selector<RootState, CustomFieldDefinition[]> =>
  createSelector(
    [
      (state: RootState) =>
        customFieldDefinitionsAdapter
          .getSelectors()
          .selectAll(state.customFieldDefinitions),
    ],
    (customFieldDefinitions) => {
      return customFieldDefinitions.filter((def) => def.provider === provider);
    }
  );
