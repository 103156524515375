import { InterviewInsights } from "app-types";
import { FC } from "react";
import { joinClassnames } from "ui";
import { SentimentScore } from "../../insights/sentimentScore";
import { ShareFragmentIconButton } from "./shareFragmentIconButton";

interface SentimentSectionProps {
  insights: InterviewInsights;
  onClickText: (text: string) => void;
  onClickShare: (text: string) => void;
}

export const SentimentSection: FC<SentimentSectionProps> = ({
  insights,
  onClickText,
  onClickShare,
}) => {
  const textClassName =
    "relative group py-1 px-2 pr-7 mb-2 rounded inline-block text-sm";

  const renderShareButton = (quote: string) => {
    return (
      <div className="absolute right-1 top-1/2 transform -translate-y-1/2 space-x-2">
        <ShareFragmentIconButton
          onClick={() => {
            onClickShare(quote);
          }}
        />
      </div>
    );
  };

  return (
    <>
      <div className="text-sm text-gray-900">
        {insights.sentiment_score && (
          <div className="mb-2">
            <SentimentScore score={insights.sentiment_score} />
          </div>
        )}
        {(insights.key_positive_quotes || []).map((quote, i) => {
          return (
            <div
              className={joinClassnames(
                textClassName,
                "bg-green-50 text-green-950 hover:bg-green-100 cursor-pointer"
              )}
              onClick={() => onClickText(quote)}
              key={`positive-quote-${i}`}
            >
              {quote}
              {renderShareButton(quote)}
            </div>
          );
        })}
        {(insights.key_negative_quotes || []).map((quote, i) => {
          return (
            <div
              className={joinClassnames(
                textClassName,
                "bg-red-50 text-red-950 hover:bg-red-100 cursor-pointer"
              )}
              onClick={() => onClickText(quote)}
              key={`negative-quote-${i}`}
            >
              {quote}
              {renderShareButton(quote)}
            </div>
          );
        })}
      </div>
    </>
  );
};
