import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { FullPageSkeleton } from "ui";
import { fetchNonEmptyInterviewsForProject } from "../features/interviews/interviewsSlice";
import {
  fetchProjectById,
  selectProjectsDictionary,
} from "../features/projects/projectsSlice";
import { useAppDispatch, useAppSelector } from "../hooks/hook";
import {
  ProjectDetailsPage,
  ProjectDetailsTabsEnum,
} from "./projectDetailsPage";

const ProjectDetailsDataProvider: React.FC<{
  project_id: string;
  tab: ProjectDetailsTabsEnum;
}> = ({ project_id, tab }) => {
  const dispatch = useAppDispatch();

  const project = useAppSelector(selectProjectsDictionary)[project_id];
  const projectStatus = useAppSelector((state) => state.projects.status);
  const projectError = useAppSelector((state) => state.projects.error);
  const interviewsError = useAppSelector((state) => state.interviews.error);

  useEffect(() => {
    dispatch(fetchProjectById(project_id));

    // Prefetch interviews so that they're loaded when user clicks Interviews tab
    dispatch(
      fetchNonEmptyInterviewsForProject({
        projectId: project_id,
        page: 1,
      })
    );
  }, [project_id, dispatch]);

  if (projectError || interviewsError) {
    return <div>Error loading project or interviews</div>;
  }

  // If we're loading but already have a project, don't show loader to avoid UI flash
  if (projectStatus !== "succeeded" && !project) {
    return <FullPageSkeleton />;
  }

  if (!project) {
    return <div>Project not found</div>;
  }

  return <ProjectDetailsPage project={project} tab={tab} />;
};

interface ProjectDetailsPageContainerProps {
  tab: ProjectDetailsTabsEnum;
}
export const ProjectDetailsPageContainer: React.FC<
  ProjectDetailsPageContainerProps
> = ({ tab }) => {
  const { project_id } = useParams();

  if (!project_id) {
    return <div>Invalid project ID</div>;
  }

  return <ProjectDetailsDataProvider project_id={project_id} tab={tab} />;
};
