import { FC } from "react";

interface TextToCopyProps {
  text: React.ReactNode;
}

export const TextToCopy: FC<TextToCopyProps> = ({ text }) => {
  return (
    <div className="rounded-md text-xs bg-slate-50 py-2 px-2 border border-solid border-slate-300 w-full flex space-x-2  cursor-text">
      {text}
    </div>
  );
};
