import { IntegrationProvidersEnum } from "app-types";
import { getAxiosInstanceWithAuth } from "../api/axiosConfig";

export const openOAuthSigninPopupForIntegration = async (
  integration: IntegrationProvidersEnum
) => {
  // Open a blank popup immediately on user click
  const popup = window.open("about:blank", "OAuthPopup");

  try {
    const axios = await getAxiosInstanceWithAuth();
    const response = await axios.get(`/oauth/login/${integration}`);
    console.log(response.data);

    if (!popup) {
      // TODO Popup may have been blocked, show the URL in the UI for user to click
      return;
    }

    // Set the URL of the popup
    popup.location.href = response.data;
  } catch (error) {
    console.error("Error during OAuth login:", error);
    popup && popup.close(); // Close the popup if there's an error
  }
};
