import { FC } from "react";
import { CRMIntegrationsSection } from "./crmIntegrationsSection";
import { SlackIntegrationSection } from "./slackIntegrationSection";

export const IntegrationSettingsSection: FC = () => {
  return (
    <div className="max-w-2xl min-w-[450px] mx-auto px-8 py-6 flex flex-col space-y-6">
      <SlackIntegrationSection />
      <CRMIntegrationsSection />
    </div>
  );
};
