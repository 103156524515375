import {
  buildProjectUrlPath,
  InterviewAgentTypesEnum,
  Project,
  ProjectDetailsTabPathsEnum,
} from "app-types";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { Input, Select } from "ui";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { selectAssessmentById } from "../assessments/assessmentsSlice";
import { AssessmentDistributionBar } from "./assessmentDistributionBar";
import {
  fetchNonEmptyInterviewsForProject,
  selectInterviewsForCurrentParametersWithContactsAndActivities,
} from "./interviewsSlice";
import { InterviewsTable } from "./interviewsTable";

const MIN_ASSESSMENT_SCORE_OPTIONS = [
  { name: "Any score", value: 1 },
  { name: "2 or higher", value: 2 },
  { name: "3 or higher", value: 3 },
  { name: "4 or higher", value: 4 },
  { name: "5", value: 5 },
];

interface InterviewsTabProps {
  project: Project;
}

export const InterviewsTab: React.FC<InterviewsTabProps> = ({ project }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [searchQuery, setSearchQuery] = useState("");
  const [minAssessmentScore, setMinAssessmentScore] = useState<number>();

  const interviews = useAppSelector(
    selectInterviewsForCurrentParametersWithContactsAndActivities
  );
  const assessment = useAppSelector(
    selectAssessmentById(project.assessment_id)
  );

  const interviewsStatus = useAppSelector((state) => state.interviews.status);

  const isRecruitingProject =
    project.settings.interview_agent_type ===
    InterviewAgentTypesEnum.RECRUITING_INTERVIEWER;

  const onSearchTextChange = (newSearchQuery: string) => {
    setSearchQuery(newSearchQuery);
    setMinAssessmentScore(undefined);
    dispatch(
      fetchNonEmptyInterviewsForProject({
        projectId: project.id,
        page: 1,
        search_text: newSearchQuery,
        min_assessment_score: undefined,
      })
    );
  };

  const onMinAssessmentScoreChange = (newMinAssessmentScore?: number) => {
    setSearchQuery("");
    setMinAssessmentScore(newMinAssessmentScore);
    dispatch(
      fetchNonEmptyInterviewsForProject({
        projectId: project.id,
        page: 1,
        search_text: "",
        min_assessment_score: newMinAssessmentScore,
      })
    );
  };

  return (
    <div className="h-full pt-4 flex flex-col">
      {isRecruitingProject && (
        <AssessmentDistributionBar
          projectId={project.id}
          onScoreClick={(score) => {
            if (score === minAssessmentScore) {
              onMinAssessmentScoreChange(undefined);
            } else {
              onMinAssessmentScoreChange(score);
            }
          }}
        />
      )}
      <div className="flex flex-row space-x-2 mb-4">
        <div className="w-[300px]">
          <Input
            placeholder="Search interview transcripts..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={(evt) => {
              if (evt.key === "Enter") {
                evt.preventDefault();
                onSearchTextChange(searchQuery);
                (evt.target as HTMLElement).blur();
              }
            }}
            onClickClearButton={(evt) => {
              evt.preventDefault();
              setSearchQuery("");
              onSearchTextChange("");
              (evt.target as HTMLElement).blur();
            }}
            leftIcon={<MagnifyingGlassIcon className="h-4 w-4 text-gray-700" />}
            maxLength={200}
          />
        </div>
        {isRecruitingProject && (
          <div className="w-[180px]">
            <Select
              options={MIN_ASSESSMENT_SCORE_OPTIONS}
              currentSelection={MIN_ASSESSMENT_SCORE_OPTIONS.find(
                (o) => o.value === minAssessmentScore
              )}
              onChange={(option) => {
                const value = option.value as number;
                onMinAssessmentScoreChange(value);
              }}
              placeholder="Minimum score"
              onClickClearButton={(evt) => {
                evt.preventDefault();
                onMinAssessmentScoreChange(undefined);
                (evt.target as HTMLElement).blur();
              }}
            />
          </div>
        )}
      </div>
      <InterviewsTable
        interviews={interviews}
        isLoading={interviewsStatus === "loading"}
        onInterviewClick={(
          interviewId: string,
          transcriptFragmentId?: string
        ) => {
          navigate(
            buildProjectUrlPath(
              project.id,
              ProjectDetailsTabPathsEnum.Interviews,
              interviewId,
              transcriptFragmentId
            )
          );
        }}
        project={project}
      />
    </div>
  );
};
