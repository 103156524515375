import {
  BasicContact,
  COMPETITORS_KEYWORD_TRACKER_NAME,
  Company,
  ComplexAnswerResponse,
  ComplexQuestion,
  ComplexQuestionInsert,
  Contact,
  InterviewAgentTypesEnum,
  KeywordTracker,
  KeywordTrackerTypesEnum,
  Project,
  ProjectModesEnum,
  RatingSurveyResponse,
  SimpleSurveyResponse,
} from "../..";
import {
  BasicQuestion,
  ComplexInterviewQuestion,
  ComplexQuestionTypesEnum,
  InterviewQuestion,
  ProjectPublic,
  ProjectQuestion,
  SurveyScalesEnum,
} from "../client/client";

export const extractDomainFromEmail = (email: string) => {
  // Split the email string by the '@' character.
  const parts = email.split("@");

  // Check if the email contains an '@' character and has a domain part.
  if (parts.length === 2 && parts[1]) {
    return parts[1]; // Return the domain part.
  } else {
    // Return an empty string if the email is invalid.
    return "";
  }
};

export function convertToValidHttpsUrl(url: string): string | null {
  const urlWithHttps = url.startsWith("https://") ? url : `https://${url}`;

  const pattern = /^https:\/\/[^\s/$.?#].[^\s]*$/i;
  return pattern.test(urlWithHttps) ? urlWithHttps : null;
}

export function isValidDomain(domain: string) {
  if (!domain || !domain.includes(".")) {
    return false;
  }

  // Regular expression to match a basic domain structure
  const pattern = /^[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$/;
  if (pattern.test(domain)) {
    // Split domain and check each part
    const parts = domain.split(".");
    for (const part of parts) {
      if (part.startsWith("-") || part.endsWith("-")) {
        return false;
      }
    }
    return true;
  } else {
    return false;
  }
}

export const isValidEmail = (email: string, excludeAliases?: boolean) => {
  // Check if email contains an '@'
  if (!email.includes("@")) {
    return false;
  }

  // Split the email into local part and domain part
  const [localPart, domainPart] = email.split("@");

  if (!domainPart) return false;

  // Prevent gmail's + alias addresses
  if (excludeAliases && localPart.includes("+")) return false;

  // Use the regular expression to validate the structure of the email
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
};

export function isComplexProjectQuestionInsert(
  question: ProjectQuestion
): question is ComplexQuestionInsert {
  return (
    !("id" in question) &&
    "type" in question &&
    Object.values(ComplexQuestionTypesEnum).includes(question.type)
  );
}

export function isComplexProjectQuestion(
  question: ProjectQuestion
): question is ComplexQuestion {
  return (
    "id" in question &&
    "type" in question &&
    Object.values(ComplexQuestionTypesEnum).includes(question.type)
  );
}

export function isComplexInterviewQuestion(
  question: InterviewQuestion
): question is ComplexInterviewQuestion {
  return "id" in question;
}

export function isValidBasicQuestion(question: any): boolean {
  return (
    typeof question.question === "string" && question.question.trim() !== ""
  );
}

// Extended validation for ComplexQuestion and ComplexQuestionInsert using isComplexQuestion
export function isValidComplexQuestion(
  question: any,
  allowInsert?: boolean
): boolean {
  const isComplexQuestion =
    isComplexProjectQuestion(question) ||
    (allowInsert && isComplexProjectQuestionInsert(question));
  if (!isComplexQuestion) return false;

  const hasValidOptions =
    Array.isArray(question.options) &&
    question.options.length > 0 &&
    question.options.every(
      (option: any) =>
        typeof option.name === "string" && option.name.trim() !== ""
    );
  const hasValidScale =
    question.type !== ComplexQuestionTypesEnum.RATING ||
    (typeof question.scale === "string" &&
      Object.values(SurveyScalesEnum).includes(question.scale));
  const hasValidType =
    typeof question.type === "string" &&
    Object.values(ComplexQuestionTypesEnum).includes(question.type);

  // Add any additional validations specific to ComplexQuestion or ComplexQuestionInsert
  return hasValidOptions && hasValidScale && hasValidType;
}

export function splitProjectQuestions(questions: ProjectQuestion[]): {
  basicQuestions: BasicQuestion[];
  complexQuestions: (ComplexQuestion | ComplexQuestionInsert)[];
} {
  const basicQuestions: BasicQuestion[] = [];
  const complexQuestions: (ComplexQuestion | ComplexQuestionInsert)[] = [];

  questions.forEach((question) => {
    if (
      isComplexProjectQuestion(question) ||
      isComplexProjectQuestionInsert(question)
    ) {
      complexQuestions.push(question as ComplexQuestion);
    } else {
      basicQuestions.push(question as BasicQuestion);
    }
  });

  return { basicQuestions, complexQuestions };
}

export const projectWithComplexQuestionsQuery = `
*,
complex_questions:question(*)
`;

export function isComplexAnswerResponse(
  response: any
): response is ComplexAnswerResponse {
  // Check if response is RatingSurveyResponse[] (matrix-style question)
  if (isRatingSurveyResponseList(response)) {
    return true;
  }

  // Check if response is SimpleSurveyResponse
  if (isSimpleSurveyResponse(response)) {
    return true;
  }

  return false;
}

export function isRatingSurveyResponseList(
  response: any
): response is RatingSurveyResponse[] {
  return Array.isArray(response) && response.every(isRatingSurveyResponse);
}

export function isSimpleSurveyResponse(
  response: any
): response is SimpleSurveyResponse {
  return response && "value" in response && typeof response.value === "string";
}

function isRatingSurveyResponse(
  response: any
): response is RatingSurveyResponse {
  return (
    typeof response === "object" &&
    response !== null &&
    "name" in response &&
    typeof response.name === "string" &&
    "rating" in response &&
    typeof response.rating === "number" &&
    response.rating > 0 &&
    response.rating <= 5
  );
}

export const getContactDisplayNames = (
  contact: Contact | BasicContact,
  accountName?: string
) => {
  const emailOrPhone = contact.email || contact.phone_number;
  if (!emailOrPhone) {
    throw new Error("Contact must have an email or phone number");
  }

  const primaryDisplayName =
    contact.first_name && contact.last_name
      ? `${contact?.first_name} ${contact?.last_name}`
      : emailOrPhone;
  const secondaryDisplayName =
    contact.first_name && contact.last_name ? emailOrPhone : null;

  // If the contact has name, email, and phone we also return the phone number as a tertiary display name
  // since the email gets used for the secondary display name
  const tertiaryDisplayName =
    contact.first_name &&
    contact.last_name &&
    contact.email &&
    contact.phone_number
      ? contact.phone_number
      : null;

  return {
    primaryDisplayName: accountName
      ? `${primaryDisplayName} (${accountName})`
      : primaryDisplayName,
    secondaryDisplayName,
    tertiaryDisplayName,
  };
};

export enum ProjectDetailsTabPathsEnum {
  ProjectConfiguration = "setup",
  Interviews = "interviews",
  Insights = "insights",
}

export enum ProjectSettingsSections {
  InterviewAssessment = "interview-assessment",
}

export const buildProjectUrlPath = (
  projectId: string,
  tab?: ProjectDetailsTabPathsEnum,
  interviewId?: string,
  transcriptFragmentId?: string,
  settingsSection?: ProjectSettingsSections
) => {
  const base = `/project/${projectId}/${
    tab || ProjectDetailsTabPathsEnum.ProjectConfiguration
  }`;

  let url = interviewId ? `${base}/${interviewId}` : base;

  const params = new URLSearchParams();
  if (transcriptFragmentId) params.append("f", transcriptFragmentId);
  if (settingsSection) params.append("section", settingsSection);

  const queryString = params.toString();
  return queryString ? `${url}?${queryString}` : url;
};

interface ShareLinkArgs {
  baseUrl: string;
  projectId: string;
  interviewId: string;
  primaryDisplayName: string;
  transcriptFragmentId?: string;
}

export const getShareLinkForInterview = ({
  baseUrl,
  projectId,
  interviewId,
  primaryDisplayName,
  transcriptFragmentId,
}: ShareLinkArgs) => {
  return `${baseUrl}${buildProjectUrlPath(
    projectId,
    ProjectDetailsTabPathsEnum.Interviews,
    interviewId
  )}?n=${encodeURIComponent(primaryDisplayName)}${
    transcriptFragmentId ? `&f=${transcriptFragmentId}` : ""
  }`;
};

// Splits a summary paragraph into an array of strings, each starting with a "- "
// and stripping periods.
export function splitSummaryTextIntoLines(summary: string) {
  return summary
    .split(". ")
    .map(
      (sentence, index, array) =>
        `- ${sentence.trim()}${index === array.length - 1 ? "" : "."}`
    );
}

// Whether this company should be blocked from usage b/c their trial has expired and they have no plan
export const isCompanyBlocked = (company: Company) => {
  // If they have a plan, they are not blocked
  if (company.billing_current_plan) return false;

  // If they have a non-expired trial, they are not blocked
  if (
    company.trial_expires_at &&
    new Date() < new Date(company.trial_expires_at)
  )
    return false;

  return true;
};

export const getInterviewDurationEstimate = (
  numQuestions: number,
  options?: {
    // Standard case w/out options returns "4 minutes" as in "only takes 4 minutes"
    shouldUseSingularPhrasing?: boolean; // E.g. "4 minute" as in "4 minute survey"
    shouldAbbreviate?: boolean; // E.g. "4 min"
  }
) => {
  if (numQuestions <= 1)
    return `one ${options?.shouldAbbreviate ? "min" : "minute"}`;

  if (options?.shouldAbbreviate) return `${numQuestions + 1} min`;

  return `${numQuestions + 1} minute${
    options?.shouldUseSingularPhrasing ? "" : "s"
  }`;
};

export const isGlobalCompetitiveKeywordTracker = (
  keywordTracker: KeywordTracker
) => {
  return (
    keywordTracker.type === KeywordTrackerTypesEnum.COMPANY_COMPETITORS &&
    keywordTracker.name === COMPETITORS_KEYWORD_TRACKER_NAME
  );
};

export function reorderArrayItems<T>(
  items: T[],
  index: number,
  direction: "up" | "down"
): T[] | false {
  if (
    (direction === "up" && index === 0) ||
    (direction === "down" && index === items.length - 1)
  ) {
    // Do nothing if moving up at the first item or down at the last item
    return false;
  }

  let swapIndex = index + (direction === "up" ? -1 : 1);

  // Ensure swapIndex remains within the array bounds
  if (swapIndex < 0 || swapIndex >= items.length) {
    // Return the original array if no swap is possible
    return items;
  }

  const newItems = [...items];
  // Swap the elements
  [newItems[index], newItems[swapIndex]] = [
    newItems[swapIndex],
    newItems[index],
  ];

  return newItems;
}

export function getDisplayPhoneNumber(phoneNumber: string, extension?: string) {
  return extension ? `${phoneNumber} ext. ${extension}` : phoneNumber;
}

export function getDialingPhoneNumber(phoneNumber: string, extension?: string) {
  return `${phoneNumber.replace(/\s/g, "")}${extension ? `,${extension}` : ""}`;
}

export function isValidUrl(url: string): boolean {
  // Add https:// if not present
  const urlWithProtocol =
    url.startsWith("http://") || url.startsWith("https://")
      ? url
      : `https://${url}`;

  try {
    const parsedUrl = new URL(urlWithProtocol);

    // Check if the hostname has at least one dot (.) and doesn't end with a dot
    if (!parsedUrl.hostname.includes(".") || parsedUrl.hostname.endsWith(".")) {
      return false;
    }

    // Check if the hostname has a valid TLD (at least 2 characters after the last dot)
    const parts = parsedUrl.hostname.split(".");
    if (parts[parts.length - 1].length < 2) {
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
}

export function isRecruitingModeProject(
  project: Project | ProjectPublic
): boolean {
  const agentType =
    "settings" in project // Project case
      ? project.settings.interview_agent_type
      : project.interview_agent_type; // ProjectPublic ase

  return (
    project.mode === ProjectModesEnum.VOICE_AGENT &&
    agentType === InterviewAgentTypesEnum.RECRUITING_INTERVIEWER
  );
}
