import {
  Keyword,
  ProjectWithInterviewCount,
  QUESTION_SUMMARY_MAX_INTERVIEW_COUNT,
} from "app-types";
import React from "react";
import { PillColorsEnum } from "ui";
import { useAppSelector } from "../../hooks/hook";
import { InsightBar } from "./insightBar";
import { selectKeywordTrackerIdToMatches } from "./insightsSlice";

interface QuestionThemeAnalysisCellProps {
  project: ProjectWithInterviewCount;
  keyword_tracker_id: string;
  keyword: Keyword;
}

// Renders the "% of interviews" cell for a keyword.
export const QuestionThemeAnalysisCell: React.FC<
  QuestionThemeAnalysisCellProps
> = ({ project, keyword, keyword_tracker_id }) => {
  const keywordTrackerIdToMatches = useAppSelector(
    selectKeywordTrackerIdToMatches
  );

  const keywordTrackerMatches = keywordTrackerIdToMatches[keyword_tracker_id];

  const totalInterviewCount = project.interview_count;

  // Question summaries are capped at 25 most recent responses
  const interviewDenominatorCount =
    totalInterviewCount > QUESTION_SUMMARY_MAX_INTERVIEW_COUNT
      ? QUESTION_SUMMARY_MAX_INTERVIEW_COUNT
      : totalInterviewCount;

  const numMatchingInterviews =
    keywordTrackerMatches[keyword.phrase].interview_ids.length;

  const interviewPercentage =
    interviewDenominatorCount > 0
      ? Math.round((numMatchingInterviews / interviewDenominatorCount) * 100)
      : 0;

  return (
    <div className="space-y-2 flex flex-col">
      <InsightBar
        color={PillColorsEnum.BLUE}
        percentageWidth={interviewPercentage}
        label={`${interviewPercentage}% of interviews`}
      />
    </div>
  );
};
