import { HandlerOf, InterviewAgentTypesEnum } from "app-types";
import { FC } from "react";
import { Label, Select, SizesEnum } from "ui";

interface InterviewAgentTypeSettingProps {
  interviewAgentType: InterviewAgentTypesEnum | null;
  onChange: HandlerOf<InterviewAgentTypesEnum>;
}

const options = [
  {
    name: "Standard (best for most use cases)",
    value: InterviewAgentTypesEnum.GENERIC_INTERVIEWER,
  },
  {
    name: "Recruiting (best for candidate interviews)",
    value: InterviewAgentTypesEnum.RECRUITING_INTERVIEWER,
  },
];

export const InterviewAgentTypeSetting: FC<InterviewAgentTypeSettingProps> = ({
  interviewAgentType,
  onChange,
}) => {
  return (
    <div>
      <Label size={SizesEnum.SMALL}>Interview type</Label>
      <div className="mt-2 w-[350px]">
        <Select
          options={options}
          currentSelection={options.find((o) => o.value === interviewAgentType)}
          onChange={(option) => {
            const newLimit = options.find((o) => o.name === option.name)?.value;

            if (newLimit) {
              onChange(newLimit);
            }
          }}
        />
      </div>
      <div className="mt-2 text-sm text-gray-600">
        The type of interview being conducted. The AI interviewer tone and style
        will be adapted to the interview type.
      </div>
    </div>
  );
};
