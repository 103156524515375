import { Dialog } from "@headlessui/react";
import {
  Company,
  InterviewAgentTypesEnum,
  LoadingStatesEnum,
  ProjectModesEnum,
} from "app-types";
import {
  ProjectTemplate,
  ProjectTypesEnum,
  getQuestionsForProjectType,
} from "app-types/src/constants/projectTypeToDefaultQuestions";
import { useState } from "react";
import {
  Button,
  ButtonVariantsEnum,
  Input,
  Label,
  Modal,
  Select,
  SizesEnum,
} from "ui";
import { useAppSelector } from "../../../hooks/hook";
import { selectIsRecruitingModeCompany } from "../../company/companySlice";
import { PhoneNumberSelect } from "../../phoneNumbers/phoneNumberSelect";
import { InterviewAgentTypeSetting } from "../projectSettings/interviewAgentTypeSetting";
import { JobDescriptionTextArea } from "../projectSettings/jobDescriptionSetting";

const projectModeOptions = [
  { name: "AI Survey", value: ProjectModesEnum.SURVEY },
  { name: "Voice Agent", value: ProjectModesEnum.VOICE_AGENT },
];

interface CreateProjectModalProps {
  isOpen: boolean;
  onClose: () => void;
  name: string;
  template?: ProjectTemplate;
  onNameChange: (name: string) => void;
  mode: ProjectModesEnum | null;
  onModeChange: (mode: ProjectModesEnum) => void;
  onClickCreate: ({
    jobDescription,
    interviewAgentType,
    phoneNumberId,
  }: {
    jobDescription?: string;
    interviewAgentType?: InterviewAgentTypesEnum;
    phoneNumberId?: string;
  }) => void;
  company: Company;
  loadingState: LoadingStatesEnum;
  onClickUseImporter: () => void;
}

export const CreateProjectModal = ({
  isOpen,
  onClose,
  name,
  template,
  mode,
  onModeChange,
  onNameChange,
  onClickCreate,
  company,
  loadingState,
  onClickUseImporter,
}: CreateProjectModalProps) => {
  const isRecruitingModeCompany = useAppSelector(selectIsRecruitingModeCompany);

  const isRecruitingModeProject =
    mode === ProjectModesEnum.VOICE_AGENT &&
    template?.type === ProjectTypesEnum.CandidateScreen;

  const [jobDescription, setJobDescription] = useState("");

  // Normally this setting is predetermined by the project type, but for "Custom" projects it
  // can be modified
  const [interviewAgentType, setInterviewAgentType] =
    useState<InterviewAgentTypesEnum>(
      InterviewAgentTypesEnum.GENERIC_INTERVIEWER
    );

  const [selectedPhoneNumberId, setSelectedPhoneNumberId] = useState<string>();

  const renderFields = () => {
    if (!template) {
      return null;
    }

    if (isRecruitingModeCompany) {
      return (
        <>
          <div className="mt-6 mb-2">
            <Input
              label="Role name"
              autoFocus={true}
              placeholder="Customer Service Rep Interviews"
              onChange={(evt) => {
                onNameChange(evt.target.value);
              }}
              value={name}
            />
          </div>
          <div className="mt-4 text-sm">
            <JobDescriptionTextArea
              value={jobDescription}
              onChange={(e) => setJobDescription(e.target.value)}
              description="Copy and paste your job description (no need to worry about text formatting or styling)."
            />
          </div>
          <div className="mt-4">
            <PhoneNumberSelect
              phoneNumberId={selectedPhoneNumberId}
              onChange={setSelectedPhoneNumberId}
            />
          </div>
          <div className="mt-4 text-sm text-gray-600">
            You can also create projects using a job posting URL with the{" "}
            <span
              className="cursor-pointer font-semibold text-blue-600 hover:text-blue-500"
              onClick={onClickUseImporter}
            >
              Job Posting Importer
            </span>
            .
          </div>
        </>
      );
    }

    const questions = getQuestionsForProjectType(
      template.type,
      company.name,
      isRecruitingModeProject
    );

    return (
      <>
        <div className="mt-6 mb-2">
          <Input
            label="Project Name"
            autoFocus={true}
            placeholder={
              isRecruitingModeProject
                ? "Customer Service Rep Interviews"
                : "Feedback interviews"
            }
            onChange={(evt) => {
              onNameChange(evt.target.value);
            }}
            value={name}
          />
        </div>

        {!isRecruitingModeProject && (
          <>
            <div className="mb-1 mt-2">
              <Label size={SizesEnum.SMALL}>Project Mode</Label>
            </div>
            <div className="max-w-[200px]">
              <Select
                options={projectModeOptions}
                currentSelection={
                  projectModeOptions.find((o) => o.value === mode) ||
                  projectModeOptions[0]
                }
                onChange={(option) => {
                  const newMode = projectModeOptions.find(
                    (o) => o.name === option.name
                  )?.value;

                  if (newMode) {
                    onModeChange(newMode);
                  }
                }}
              />
            </div>
            <div className="mt-2 text-sm text-gray-500">
              Use AI Survey mode for more structure and quantitative data, and
              Voice Agent mode for dynamic phone-based conversations.
            </div>
          </>
        )}
        {isRecruitingModeProject && (
          <div className="mt-4 text-sm">
            <JobDescriptionTextArea
              value={jobDescription}
              onChange={(e) => setJobDescription(e.target.value)}
              description="Copy and paste your job description (no need to worry about text formatting or styling)."
            />
          </div>
        )}
        {mode === ProjectModesEnum.VOICE_AGENT &&
          template?.type === ProjectTypesEnum.Custom && (
            <div className="mb-1 mt-2">
              <InterviewAgentTypeSetting
                interviewAgentType={interviewAgentType}
                onChange={(newInterviewAgentType) => {
                  setInterviewAgentType(newInterviewAgentType);
                }}
              />
            </div>
          )}
        {questions.length ? (
          <div className="mt-4 text-sm">
            <label className="block mb-1 text-sm font-medium leading-6 text-gray-900">
              {`Questions Template`}
            </label>
            <div>
              {questions.map((question, index) => {
                return (
                  <div className="flex flex-row space-x-2">
                    <div>{`${index + 1}.`}</div>
                    <div key={`template-question-${index}`} className="mb-1">
                      {`${question.question}`}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="mt-2 text-gray-500">
              {generateQuestionsTemplateHelperText(template.type)}
            </div>
          </div>
        ) : null}
        {mode === ProjectModesEnum.VOICE_AGENT && (
          <PhoneNumberSelect
            phoneNumberId={selectedPhoneNumberId}
            onChange={setSelectedPhoneNumberId}
            className="mt-4"
          />
        )}
      </>
    );
  };

  const renderButtons = () => {
    const isRecruiting = isRecruitingModeProject || isRecruitingModeCompany;

    return (
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <div className="ml-3">
          <Button
            variant={ButtonVariantsEnum.Primary}
            label={"Create Project"}
            isDisabled={!name || (isRecruiting && !jobDescription)}
            onClick={() => {
              onClickCreate({
                jobDescription: isRecruiting ? jobDescription : undefined,
                interviewAgentType:
                  isRecruitingModeCompany ||
                  template?.type === ProjectTypesEnum.CandidateScreen
                    ? InterviewAgentTypesEnum.RECRUITING_INTERVIEWER
                    : interviewAgentType,
                phoneNumberId: selectedPhoneNumberId,
              });
            }}
            isLoading={loadingState === LoadingStatesEnum.LOADING}
          />
        </div>
        <Button
          variant={ButtonVariantsEnum.Secondary}
          label="Cancel"
          onClick={onClose}
        />
      </div>
    );
  };

  return (
    <Modal isOpen={isOpen} onCancel={onClose}>
      <Dialog.Title
        as="h3"
        className="text-xl font-semibold leading-6 text-gray-900"
      >
        New Project
      </Dialog.Title>
      {renderFields()}
      {renderButtons()}
    </Modal>
  );
};

function generateQuestionsTemplateHelperText(type: ProjectTypesEnum) {
  switch (type) {
    case ProjectTypesEnum.CandidateScreen:
      return "You'll be able to edit these questions later and generate questions based on a job description.";

    case ProjectTypesEnum.BrandPerception:
    case ProjectTypesEnum.Discovery:
    case ProjectTypesEnum.ConceptTesting:
    case ProjectTypesEnum.Win:
    case ProjectTypesEnum.Loss:
    case ProjectTypesEnum.SuperchargedNPS:
    case ProjectTypesEnum.Churn:
      return "You'll be able to edit these questions later or generate questions from a research discussion guide.";

    default:
      return "You can always edit these questions later.";
  }
}
