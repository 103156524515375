import {
  ArrowUpOnSquareStackIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import {
  BasicQuestion,
  Company,
  CustomFieldDefinition,
  CustomFieldDefinitionInsertClient,
  getErrorMessageForAssessmentCriteriaValue,
  Project,
  ProjectUpdate,
  reorderArrayItems,
  sortAssessmentFields,
} from "app-types";
import { FC, useEffect, useRef, useState } from "react";
import { Button, ButtonVariantsEnum, Label, SizesEnum, Slideover } from "ui";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import { AssessmentEditor } from "../../assessments/assessmentEditor";
import {
  selectAssessmentById,
  updateAssessment,
} from "../../assessments/assessmentsSlice";
import { ImportQuestionsModal } from "./importQuestionsModal";
import { QuestionsManagerCell } from "./questionsManagerCell";

const MAX_INTERVIEW_QUESTIONS = 25;

interface RecruitingQuestionsManagerSlideoverProps {
  basicQuestions: BasicQuestion[];
  project: Project;
  company: Company;
  onSaveProject: (changes: ProjectUpdate) => void;
  isOpen: boolean;
  onClose: () => void;
}

const emptyBasicQuestion = { question: "" };

export const RecruitingQuestionsManagerSlideover: FC<
  RecruitingQuestionsManagerSlideoverProps
> = (props) => {
  const { project, onSaveProject, isOpen, onClose } = props;
  const dispatch = useAppDispatch();

  const assessment = useAppSelector(
    selectAssessmentById(project.assessment_id)
  );

  const [isImportModalOpen, setIsImportModalOpen] = useState(false);

  // State for managing basic questions
  const [basicQuestions, setBasicQuestions] = useState<BasicQuestion[]>(
    props.basicQuestions.length > 0
      ? props.basicQuestions
      : [emptyBasicQuestion]
  );
  const [hasMadeChanges, setHasMadeChanges] = useState(false);
  const questionsContainerRef = useRef<HTMLDivElement>(null);

  // State for managing assessment criteria
  const sortedOriginalFields = sortAssessmentFields(
    assessment?.custom_field_definitions || []
  );
  const [assessmentFields, setAssessmentFields] =
    useState<(CustomFieldDefinition | CustomFieldDefinitionInsertClient)[]>(
      sortedOriginalFields
    );
  const [assessmentFieldErrors, setAssessmentFieldErrors] = useState<string[]>(
    []
  );

  // Reset state when slideover is opened/closed
  useEffect(() => {
    setBasicQuestions(props.basicQuestions);
    setAssessmentFields(sortedOriginalFields);
    setAssessmentFieldErrors([]);
    setHasMadeChanges(false);
  }, [isOpen]);

  const validateAssessmentFields = (
    fieldsToValidate: (
      | CustomFieldDefinition
      | CustomFieldDefinitionInsertClient
    )[]
  ) => {
    const newErrors = fieldsToValidate.map((field) =>
      getErrorMessageForAssessmentCriteriaValue(field.display_name)
    );
    setAssessmentFieldErrors(newErrors);
    return newErrors.every((error) => error === "");
  };

  const onSave = () => {
    const nonEmptyFields = assessmentFields.filter(
      (field) => field.display_name.trim() !== ""
    );

    if (validateAssessmentFields(nonEmptyFields)) {
      if (assessment) {
        dispatch(
          updateAssessment({
            assessmentId: assessment.id,
            changes: {
              custom_field_definitions: nonEmptyFields,
            },
          })
        );
      }

      setHasMadeChanges(false);

      onSaveProject({
        questions: basicQuestions.filter((q) => q.question), // Filter out empty questions
      });
      onClose();
    }
  };

  const onClickReorderBasicQuestion = (
    index: number,
    direction: "up" | "down"
  ) => {
    const newQuestions = reorderArrayItems(basicQuestions, index, direction);

    if (!newQuestions) return;

    setBasicQuestions(newQuestions);
    setHasMadeChanges(true);
  };

  return (
    <>
      <Slideover
        title={"Interview Editor"}
        onClickClose={onClose}
        shouldShow={isOpen}
        buttons={
          <>
            <Button
              variant={ButtonVariantsEnum.Primary}
              label="Save changes"
              onClick={() => {
                onSave();
              }}
              size={SizesEnum.MEDIUM}
              isDisabled={!hasMadeChanges}
            />
            <Button
              variant={ButtonVariantsEnum.Secondary}
              label="Discard changes"
              onClick={onClose}
              size={SizesEnum.MEDIUM}
            />
          </>
        }
      >
        <div className="flex h-full bg-gray-50 flex-row">
          {assessment && (
            <div className="w-[400px] border-r border-gray-200 flex flex-col">
              <div className="px-3 py-2 flex flex-col space-between border-b border-gray-200 bg-white">
                <Label size={SizesEnum.MEDIUM}>Assessment Criteria</Label>
                <div className="text-gray-600 text-xs">
                  Interviews are automatically scored based on these criteria to
                  help identify qualified candidates.
                </div>
              </div>
              <div className="overflow-y-auto flex-1">
                <AssessmentEditor
                  interviewLanguage={project.settings.interview_language}
                  fields={assessmentFields}
                  setFields={(fields) => {
                    setHasMadeChanges(true);
                    setAssessmentFields(fields);
                  }}
                  fieldErrors={assessmentFieldErrors}
                  setFieldErrors={setAssessmentFieldErrors}
                />
              </div>
            </div>
          )}
          <div className="flex-1 flex flex-col">
            <div className="px-3 py-2 flex flex-col space-between border-b border-gray-200 bg-white">
              <div className="flex flex-row items-center">
                <Label size={SizesEnum.MEDIUM}>Interview Questions</Label>
              </div>
              <div className="text-gray-600 text-xs">
                The AI interviewer will automatically ask clarifying questions
                and adapt to the candidate's responses.
              </div>
            </div>
            <div className="overflow-y-auto flex-1" ref={questionsContainerRef}>
              <div>
                {basicQuestions.map((question, index) => (
                  <QuestionsManagerCell
                    key={`basic-question-${index}`}
                    questionNumber={index + 1}
                    question={question}
                    onClick={() => {}}
                    onRemove={() => {
                      setBasicQuestions(
                        basicQuestions.filter((_, i) => i !== index)
                      );
                      setHasMadeChanges(true);
                    }}
                    onClickMoveUp={
                      index !== 0
                        ? () => onClickReorderBasicQuestion(index, "up")
                        : undefined
                    }
                    onClickMoveDown={
                      index !== basicQuestions.length - 1
                        ? () => onClickReorderBasicQuestion(index, "down")
                        : undefined
                    }
                    onChange={(question) => {
                      setBasicQuestions(
                        basicQuestions.map((q, i) =>
                          i === index ? question : q
                        )
                      );
                      setHasMadeChanges(true);
                    }}
                  />
                ))}

                <div className="m-3 flex flex-row justify-between space-x-3">
                  <Button
                    variant={ButtonVariantsEnum.Secondary}
                    icon={<PlusIcon className="w-4 h-4 mr-2" />}
                    onClick={() => {
                      setBasicQuestions((previousBasicQuestions) => {
                        const newQuestions = [
                          ...previousBasicQuestions,
                          emptyBasicQuestion,
                        ];
                        return newQuestions;
                      });
                    }}
                    size={SizesEnum.MEDIUM}
                    label="Add question"
                    isDisabled={
                      basicQuestions.length >= MAX_INTERVIEW_QUESTIONS
                    }
                  />
                  <Button
                    label="Bulk import"
                    onClick={() => {
                      setIsImportModalOpen(true);
                    }}
                    variant={ButtonVariantsEnum.Secondary}
                    size={SizesEnum.MEDIUM}
                    icon={<ArrowUpOnSquareStackIcon className="w-4 h-4 mr-2" />}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ImportQuestionsModal
          isOpen={isImportModalOpen}
          onClose={() => setIsImportModalOpen(false)}
          onImport={(questions: BasicQuestion[]) => {
            setBasicQuestions(questions);
            setHasMadeChanges(true);
            setIsImportModalOpen(false);
          }}
          project={project}
        />
      </Slideover>
    </>
  );
};
