import {
  Company,
  isRecruitingModeProject,
  ProjectUpdate,
  ProjectWithInterviewCount,
  splitProjectQuestions,
} from "app-types";
import { getInterviewLinkForProject, Label, SizesEnum } from "ui";
import { useAppDispatch } from "../../../hooks/hook";
import {
  NotificationTypeEnum,
  showNotification,
} from "../../notificationsOverlay/notificationsSlice";
import { ProjectSettingsQuestionsSection } from "../surveyQuestions/projectSettingsQuestionSection";
import { AIContextSetting } from "./aiContextSetting";
import { AppearanceSettingsSection } from "./appearanceSettingsSection";
import { InterviewLanguageSetting } from "./interviewLanguageSetting";
import { InterviewLinkCopybox } from "./interviewLinkCopybox";
import { JobDescriptionSetting } from "./jobDescriptionSetting";
import { PhoneNumberDisplaySection } from "./phoneNumberDisplaySection";
import { ProjectNotificationsSettingsSection } from "./projectNotificationsSettingsSection";

interface VoiceModeProjectSettingsProps {
  project: ProjectWithInterviewCount;
  company: Company;
  onSaveProject: (project: ProjectUpdate) => void;
  onClickSharingTemplates: () => void;
}

export const VoiceModeProjectSettings = ({
  project,
  onSaveProject,
  company,
  onClickSharingTemplates,
}: VoiceModeProjectSettingsProps) => {
  const dispatch = useAppDispatch();
  const { basicQuestions } = splitProjectQuestions(project.questions);
  const isRecruitingMode = isRecruitingModeProject(project);

  return (
    <>
      <div className="flex flex-col space-y-6">
        <div>
          <div className="bg-white border border-gray-200 rounded-lg px-4 py-3">
            <div className="flex flex-row space-x-4">
              <div className="flex-shrink-0">
                <InterviewLinkCopybox
                  inviteLinkUrl={getInterviewLinkForProject(
                    import.meta.env.VITE_INTERVIEW_APP_BASE_URL,
                    company,
                    project
                  )}
                  project={project}
                  onCopyInterviewLink={() => {
                    showNotification(dispatch, {
                      id: `project-${
                        project.id
                      }-link-copied-${new Date().getTime()}`,
                      primaryMessage: `Interview link copied to clipboard`,
                      type: NotificationTypeEnum.SUCCESS,
                    });
                  }}
                />
              </div>
              <div className="flex-shrink-0">
                <Label size={SizesEnum.SMALL}>Phone number</Label>
                <PhoneNumberDisplaySection projectId={project.id} />
              </div>
            </div>
            <div className="text-sm mb-2 text-slate-800">
              Send the interview link or phone number to candidates or add it
              directly to your job posting (
              <span
                onClick={onClickSharingTemplates}
                className="cursor-pointer font-medium text-blue-600 hover:text-blue-500"
              >
                See Templates
              </span>
              ). Links work on desktop or mobile browsers and can be customized
              with a custom logo and messaging.
            </div>
          </div>
        </div>

        <div>
          <Label size={SizesEnum.LARGE}>Interview Settings</Label>
          <div className="flex flex-col space-y-3">
            <div>
              <ProjectSettingsQuestionsSection
                company={company}
                project={project}
                complexQuestions={[]}
                basicQuestions={basicQuestions}
                onSaveProject={onSaveProject}
                isRecruitingMode={isRecruitingMode}
              />
            </div>

            <AIContextSetting
              context={project.settings.prompt_context}
              onSave={(newContext) => {
                onSaveProject({
                  id: project.id,
                  settings: {
                    ...project.settings,
                    prompt_context: newContext || null,
                  },
                });
              }}
              isRecruitingMode={isRecruitingMode}
            />
            {isRecruitingMode && (
              <>
                <JobDescriptionSetting
                  jobDescription={project.job_description}
                  onSave={(newJobDescription) => {
                    onSaveProject({
                      id: project.id,
                      job_description: newJobDescription,
                    });
                  }}
                />
                <InterviewLanguageSetting
                  language={project.settings.interview_language}
                  onSave={(newLanguage) => {
                    onSaveProject({
                      id: project.id,
                      settings: {
                        ...project.settings,
                        interview_language: newLanguage,
                      },
                    });
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>
      <ProjectNotificationsSettingsSection
        project={project}
        onSaveProject={onSaveProject}
      />
      <AppearanceSettingsSection
        onSaveProject={onSaveProject}
        company={company}
        project={project}
      />
    </>
  );
};
