import {
  BalanceTransaction,
  BalanceTransactionStatusesEnum,
  LoadingStatesEnum,
} from "app-types";
import { format } from "date-fns";
import { FC } from "react";
import { Pill, PillColorsEnum, SizesEnum, TableSkeleton, capitalize } from "ui";
import { PaginationControls } from "../../miscellaneous/paginationControls";
import { UnitAmountText } from "./unitAmountText";

interface GiftCardBalanceTableProps {
  balanceTransactions: BalanceTransaction[];
  loadingState: LoadingStatesEnum;
  onClickNextPage: () => void;
  onClickPreviousPage: () => void;
  page: number;
  totalPages: number;
}

const transactionStatusToColor = {
  [BalanceTransactionStatusesEnum.CONFIRMED]: PillColorsEnum.GREEN,
  [BalanceTransactionStatusesEnum.FAILED]: PillColorsEnum.RED,
  [BalanceTransactionStatusesEnum.PENDING]: PillColorsEnum.YELLOW,
};

export const GiftCardBalanceTable: FC<GiftCardBalanceTableProps> = ({
  balanceTransactions,
  loadingState,
  onClickNextPage,
  onClickPreviousPage,
  page,
  totalPages,
}) => {
  const renderTableRows = () => {
    if (loadingState !== LoadingStatesEnum.LOADED) {
      return <TableSkeleton />;
    }

    if (balanceTransactions.length === 0) {
      return (
        <tr>
          <td
            className="px-3 py-9 text-center text-sm font-semibold text-gray-700"
            colSpan={4}
          >
            No gift card activity yet
          </td>
        </tr>
      );
    }

    return (
      <>
        {balanceTransactions.map((transaction) => (
          <tr key={transaction.id}>
            <td className="overflow-hidden text-ellipsis py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
              {transaction.description}
            </td>
            <td className="overflow-hidden text-ellipsis px-3 py-4 text-sm text-gray-500">
              <UnitAmountText unitAmount={transaction.unit_amount} />
            </td>
            <td className="overflow-hidden text-ellipsis px-3 py-4 text-sm text-gray-500">
              <Pill
                label={capitalize(transaction.status)}
                size={SizesEnum.SMALL}
                color={transactionStatusToColor[transaction.status]}
              />
            </td>
            <td className="overflow-hidden whitespace-nowrap text-ellipsis px-3 py-4 text-sm text-gray-500">
              {format(
                new Date(transaction.created_at as string),
                "MMM d, yyyy"
              )}
            </td>
          </tr>
        ))}
      </>
    );
  };

  return (
    <>
      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
        <table className="min-w-full divide-y divide-gray-300 table-fixed w-full">
          <thead className="bg-gray-50">
            <tr>
              <th
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 overflow-hidden text-ellipsis w-[20%]"
                scope="col"
              >
                Activity
              </th>
              <th
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 overflow-hidden text-ellipsis w-[15%]"
                scope="col"
              >
                Amount
              </th>
              <th
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 overflow-hidden text-ellipsis w-[15%]"
                scope="col"
              >
                Status
              </th>
              <th
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 overflow-hidden text-ellipsis w-[20%]"
                scope="col"
              >
                Date
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {renderTableRows()}
          </tbody>
        </table>
      </div>
      <div className="pt-2">
        <PaginationControls
          page={page}
          totalPages={totalPages}
          onClickNextPage={onClickNextPage}
          onClickPreviousPage={onClickPreviousPage}
        />
      </div>
    </>
  );
};
