import {
  LoadingStatesEnum,
  Project,
  ProjectUpdate,
  SlackChannel,
  SlackIntegrationSettings,
} from "app-types";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  ButtonVariantsEnum,
  Checkbox,
  DefaultSkeleton,
  Label,
  SearchableSelect,
  SearchableSelectModesEnum,
  SearchableSelectOption,
  SizesEnum,
} from "ui";
import { useAppSelector } from "../../hooks/hook";
import { fetchSlackChannelsForIntegration } from "../SettingsPage/integrationSettings/slackIntegrationSection";
import { selectSlackIntegration } from "../integrations/integrationsSlice";

// Cache the Slack channels to avoid fetching them multiple times.
let slackChannels: SlackChannel[] = [];

interface SlackProjectNotificationSectionProps {
  project: Project;
  onSave: (changes: ProjectUpdate) => void;
}

export const SlackProjectNotificationsSection: FC<
  SlackProjectNotificationSectionProps
> = ({ project, onSave }) => {
  const slackIntegration = useAppSelector(selectSlackIntegration);
  const navigate = useNavigate();

  const [slackChannelsLoadingState, setSlackChannelsLoadingState] =
    useState<LoadingStatesEnum>(LoadingStatesEnum.LOADED);

  useEffect(() => {
    const fetchSlackChannels = async () => {
      if (slackIntegration && slackChannels.length === 0) {
        try {
          setSlackChannelsLoadingState(LoadingStatesEnum.LOADING);
          const channels = await fetchSlackChannelsForIntegration(
            slackIntegration.id
          );

          slackChannels = channels;
          setSlackChannelsLoadingState(LoadingStatesEnum.LOADED);
        } catch (err) {
          setSlackChannelsLoadingState(LoadingStatesEnum.ERROR);
          console.log("Error fetching Slack channels", err);
        }
      }
    };

    fetchSlackChannels();
  }, [slackIntegration]);

  const renderContent = () => {
    if (!slackIntegration) {
      return (
        <div>
          <Button
            variant={ButtonVariantsEnum.Secondary}
            onClick={() => {
              navigate(`/settings/integrations`);
            }}
            label="Enable Slack integration"
          />
        </div>
      );
    }

    const maybeRenderSelect = () => {
      if (!project.settings.is_slack_enabled) return null;

      if (slackChannelsLoadingState === LoadingStatesEnum.LOADING) {
        return <DefaultSkeleton />;
      }

      if (slackChannelsLoadingState === LoadingStatesEnum.ERROR) {
        return (
          <div className="text-sm text-gray-800">
            An unexpected error occurred loading Slack channels
          </div>
        );
      }

      if (!slackChannels.length) return null;

      let options = [...slackChannels];

      const companyLevelSlackChannelId = (
        slackIntegration.settings as SlackIntegrationSettings
      ).channel_id;

      const formattedCompanyWideChannelIndex = options.findIndex(
        (c) => c.id === companyLevelSlackChannelId
      );

      if (formattedCompanyWideChannelIndex !== -1) {
        // If the company-wide channel can be found, format it with (Default) and place it at the start of the options array
        const formattedCompanyWideChannel = {
          ...options[formattedCompanyWideChannelIndex],
          name: `${options[formattedCompanyWideChannelIndex].name} (Default)`,
        };

        // Remove the original channel from the array
        options.splice(formattedCompanyWideChannelIndex, 1);
        // Prepend the formatted channel to the start of the array
        options.unshift(formattedCompanyWideChannel);
      }

      return (
        <div>
          <div className="w-72 mt-2">
            <SearchableSelect
              label="Target Slack channel"
              mode={SearchableSelectModesEnum.SINGLE}
              placeholder="Search for a public channel..."
              selection={
                // Default to the project specific channel, then fallback to company-wide channel
                options.find(
                  (o) => o.id === project.settings.slack_channel_id
                ) ||
                options.find((o) => o.id === companyLevelSlackChannelId) ||
                null
              }
              options={options}
              onChange={(option: SearchableSelectOption | null) => {
                onSave({
                  id: project.id,
                  settings: {
                    ...project.settings,
                    slack_channel_id: option ? option.id : null,
                  },
                });
              }}
              shouldDisableCustomOptions={true}
            />
          </div>
          <div className="mt-2 text-sm text-gray-600">
            An interview summary and link to the full transcript will be sent to
            this Slack channel when an interview is completed. We recommend
            choosing a popular channel so that insights can be shared across
            your team.
          </div>
        </div>
      );
    };

    return (
      <>
        <Checkbox
          isChecked={project.settings.is_slack_enabled}
          onChange={(isChecked) =>
            onSave({
              id: project.id,
              settings: {
                ...project.settings,
                is_slack_enabled: isChecked,
              },
            })
          }
          label="Send Slack notifications when interviews are completed for this project"
        />
        {maybeRenderSelect()}
      </>
    );
  };

  return (
    <div className="flex flex-col justify-between mb-3">
      <Label size={SizesEnum.SMALL} className="mb-2">
        Slack
      </Label>
      {renderContent()}
    </div>
  );
};
