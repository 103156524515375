import {
  EntityState,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { Activity } from "app-types";
import { RootState } from "../../app/store";
import { fetchNonEmptyInterviewsForProject } from "../interviews/interviewsSlice";

export const activitiesAdapter = createEntityAdapter<Activity>();

const initialState: EntityState<Activity> & {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
} = activitiesAdapter.getInitialState({
  status: "idle",
  error: null,
});

export const selectActivitiesDictionary = (state: RootState) =>
  activitiesAdapter
    .getSelectors((state: RootState) => state.activities)
    .selectEntities(state);

export const selectAllActivities = activitiesAdapter.getSelectors().selectAll;

export const activitiesSlice = createSlice({
  name: "activities",
  initialState,
  reducers: {
    activityAdded: activitiesAdapter.addOne,
    activitiesAdded: activitiesAdapter.addMany,
    activityRemoved: activitiesAdapter.removeOne,
    activityUpdated: activitiesAdapter.updateOne,
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchNonEmptyInterviewsForProject.fulfilled,
      (state, action) => {
        state.status = "succeeded";
        activitiesAdapter.addMany(state, action.payload.activities);
      }
    );
  },
});

export const {
  activityAdded,
  activitiesAdded,
  activityRemoved,
  activityUpdated,
} = activitiesSlice.actions;
export default activitiesSlice.reducer;
