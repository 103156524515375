export enum InterviewAgentTypesEnum {
  GENERIC_INTERVIEWER = "generic",
  RECRUITING_INTERVIEWER = "recruiter",
}

export enum ProjectEmailDigestFrequencyEnum {
  INSTANT = "instant",
  DAILY = "daily",
}

export type ProjectPublicAPI = {
  id: string;
  name: string;
};
