import { LineChartDataSegment } from "app-types";
import { startOfWeek, subWeeks } from "date-fns";
import React from "react";
import { LineChartComponent } from "../features/insights/charts/charts";
import { formatSentimentTickForChart } from "../features/insights/sentimentScore";

/*
 * Constants.
 */

// These margins make sure our chart displays properly given the Y ticks
// like "Very dissatisfied" that take up significant space
export const sentimentChartMargins = { top: 20, left: 35, bottom: 5 };

export const sentimentChartYDomain = [1, 5];

/*
 * Helpers
 */

// Flattens input data into format expected by recharts LineChart
// see https://recharts.org/en-US/api/LineChart#data
export function flattenLineChartInputData(data: LineChartDataSegment[]) {
  return data.map((item) => {
    // Create an object with the name property
    const transformedItem: any = { name: item.x_value };

    // Iterate over y_values array to add each key-value pair to the object
    item.y_values.forEach((yValue) => {
      // Round values to the nearest single decimal place.
      transformedItem[yValue.key] = Number(yValue.value.toFixed(1));
    });

    return transformedItem;
  });
}

export function formatPercentageTickForChart(value: number | string) {
  if (typeof value !== "number") {
    return value;
  }

  const roundedPercent = (value * 100).toFixed(0);

  return `${roundedPercent}%`;
}

/*
 * Sample data.
 */

export function generateSampleSentimentData() {
  const samples: LineChartDataSegment[] = [];

  const scores = [4.2, 4.0, 4.1, 3.8, 3.5, 3.4, 3.0, 2.4, 2.6];

  scores.forEach((score, index) => {
    // Calculate the start of the week, going back from the current date
    const date = startOfWeek(subWeeks(new Date(), index), { weekStartsOn: 1 });

    // Convert date to timestamp
    const x_value = date.getTime();

    // Generate a sample Y value (for simplicity, let's just use the score)
    const y_values = [{ key: "Sentiment", value: score }];

    // Add to the samples array
    samples.unshift({ x_value, y_values }); // unshift to reverse the order, making it chronological
  });

  return samples;
}

export const PlaceholderSentimentLineChart: React.FC = () => {
  return (
    <div className="relative flex">
      <div className="w-full h-full">
        <div className="blur-[2px] pointer-events-none">
          <LineChartComponent
            data={generateSampleSentimentData()}
            scale="time"
            height={200}
            shouldShowTooltip
            yDomain={sentimentChartYDomain}
            yTickFormatter={formatSentimentTickForChart}
            margin={sentimentChartMargins}
          />
        </div>
      </div>
      <div className="px-3 py-1.5 text-base font-medium text-gray-900 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-md bg-white border border-gray-200">
        Conduct more interviews to see sentiment trends
      </div>
    </div>
  );
};
