export enum InterviewLanguagesEnum {
  ENGLISH = "en-US",
  GERMAN = "de-DE",
}

export const LANGUAGE_DISPLAY_NAMES: Record<InterviewLanguagesEnum, string> = {
  [InterviewLanguagesEnum.ENGLISH]: "English",
  [InterviewLanguagesEnum.GERMAN]: "German",
};

export const LANGUAGE_TO_FLAG = {
  [InterviewLanguagesEnum.ENGLISH]: "🇺🇸",
  [InterviewLanguagesEnum.GERMAN]: "🇩🇪",
};
