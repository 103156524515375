import type { EntityState } from "@reduxjs/toolkit";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  Assessment,
  PhoneNumberExtension,
  PosthogEventTypesEnum,
  ProjectModesEnum,
  ProjectQuestion,
  ProjectSettings,
  ProjectWithInterviewCount,
  type Project,
  type ProjectUpdate,
} from "app-types";
import posthog from "posthog-js";
import { Selector } from "react-redux";
import { getAxiosInstanceWithAuth } from "../../api/axiosConfig";
import type { RootState } from "../../app/store";

export const projectsAdapter = createEntityAdapter<ProjectWithInterviewCount>();

const initialState: EntityState<ProjectWithInterviewCount> & {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
} = projectsAdapter.getInitialState({
  status: "idle",
  error: null,
});

export const createProject = createAsyncThunk<
  ProjectWithInterviewCount & {
    assessment: Assessment | null;
    phone_number_extension: PhoneNumberExtension | null;
  },
  {
    name: string;
    incentive_quantity: number;
    questions: ProjectQuestion[];
    short_id: string;
    settings?: ProjectSettings;
    mode: ProjectModesEnum;
    job_description?: string;
    is_live?: boolean;
    phone_number_id?: string;
    assessment_id_to_duplicate?: string;
  },
  { rejectValue: string }
>("projects/createProject", async (project, thunkAPI) => {
  try {
    const axios = await getAxiosInstanceWithAuth();
    const response = await axios.post("/projects", project);

    posthog.capture(PosthogEventTypesEnum.PROJECT_CREATE, {
      project_id: response.data.id,
      project_name: response.data.name,
    });

    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const fetchAllProjects = createAsyncThunk(
  "projects/fetchAllProjects",
  async (_, thunkAPI) => {
    try {
      const companyId = (thunkAPI.getState() as RootState).company.company?.id;

      if (!companyId) {
        throw new Error("No company id found in state");
      }

      const axios = await getAxiosInstanceWithAuth();
      const response = await axios.get("/projects");
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchProjectById = createAsyncThunk(
  "projects/fetchProjectById",
  async (projectId: string, thunkAPI) => {
    try {
      const axios = await getAxiosInstanceWithAuth();
      const response = await axios.get(`/projects/${projectId}`);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const selectAllProjects = projectsAdapter.getSelectors().selectAll;

export const selectProjectsDictionary = (state: RootState) =>
  state.projects.entities;

export const updateProject = createAsyncThunk<
  Project,
  { projectId: string; changes: ProjectUpdate },
  { rejectValue: string }
>("projects/updateProject", async ({ projectId, changes }, thunkAPI) => {
  try {
    const axios = await getAxiosInstanceWithAuth();
    const response = await axios.patch(`/projects/${projectId}`, changes);
    // Since we only update the diff in redux, we don't need to recalculate
    // the interview count when updating on the server.

    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const deleteProject = createAsyncThunk(
  "projects/deleteProject",
  async (projectId: string, thunkAPI) => {
    try {
      const axios = await getAxiosInstanceWithAuth();
      const response = await axios.delete(`/projects/${projectId}`);

      return thunkAPI.fulfillWithValue(projectId);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    projectAdded: projectsAdapter.addOne,
    projectRemoved: projectsAdapter.removeOne,
    projectUpdated: projectsAdapter.updateOne,
    setAllProjects: projectsAdapter.setAll,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createProject.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createProject.fulfilled, (state, action) => {
        state.status = "succeeded";
        projectsAdapter.addOne(state, action.payload);
      })
      .addCase(createProject.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
      })
      .addCase(fetchAllProjects.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllProjects.fulfilled, (state, action) => {
        state.status = "succeeded";
        projectsAdapter.setAll(state, action.payload);
      })
      .addCase(fetchAllProjects.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
      })
      .addCase(fetchProjectById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProjectById.fulfilled, (state, action) => {
        state.status = "succeeded";
        projectsAdapter.upsertOne(state, action.payload);
      })
      .addCase(fetchProjectById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
      })
      .addCase(updateProject.pending, (state, action) => {
        state.status = "loading";
        // Optimistic update
        projectsAdapter.updateOne(state, {
          id: action.meta.arg.projectId,
          // Typecast because the ProjectUpdate type for `questions` is more permissive
          // than ProjectQuestion[], and we are optimistically updating with the fn arg,
          // rather than the return value of `updateProjectDb` (which maps the result to Project)
          changes: action.meta.arg.changes as Partial<Project>,
        });
      })
      .addCase(updateProject.fulfilled, (state, action) => {
        state.status = "succeeded";
        projectsAdapter.updateOne(state, {
          id: action.payload.id,
          changes: action.payload,
        });
      })
      .addCase(updateProject.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
      })
      .addCase(deleteProject.pending, (state, action) => {
        state.status = "loading";
        // Optimistic update
        console.log("deleting project", action.meta.arg);
        projectsAdapter.removeOne(state, action.meta.arg);
      })
      .addCase(deleteProject.fulfilled, (state, action) => {
        state.status = "succeeded";
        projectsAdapter.removeOne(state, action.payload);
      })
      .addCase(deleteProject.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
      });
  },
});

export const { projectAdded, projectRemoved, projectUpdated, setAllProjects } =
  projectsSlice.actions;
export default projectsSlice.reducer;

export const selectProjectById = (
  id: string
): Selector<RootState, Project | undefined> =>
  createSelector([(state: RootState) => state.projects], (projectsState) =>
    projectsAdapter.getSelectors().selectById(projectsState, id)
  );
