import { LineChartDataSegment } from "app-types";
import { format } from "date-fns";
import React from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { flattenLineChartInputData } from "../../../helpers/chartHelpers";

const lineColors = ["#2563eb", "#059669", "#475569", "#d97706", "#dc2626"];
const tickStyle = { fontSize: 14, fontWeight: 500, fill: "#1e293b" };

interface LineChartProps {
  data: LineChartDataSegment[];
  scale: "time" | "auto";
  shouldShowLegend?: boolean;
  height?: number;
  margin?: { top?: number; right?: number; bottom?: number; left?: number };
  shouldShowTooltip?: boolean;
  yDomain?: number[];
  yTickFormatter?: ((value: number) => string) | ((value: string) => string);
}

export const LineChartComponent: React.FC<LineChartProps> = ({
  data,
  scale,
  shouldShowLegend,
  height,
  shouldShowTooltip,
  yDomain,
  yTickFormatter,
  margin,
}) => {
  if (data.length < 2) {
    return null;
  }

  return (
    <ResponsiveContainer width="100%" height={height || 250}>
      <LineChart
        data={flattenLineChartInputData(data)}
        margin={margin || undefined}
      >
        <CartesianGrid strokeDasharray="3 3" strokeWidth={2} />
        <XAxis
          strokeWidth={2}
          dataKey="name"
          tick={tickStyle}
          scale={scale}
          type={scale === "time" ? "number" : "category"}
          domain={scale === "time" ? ["dataMin", "dataMax"] : undefined}
          tickFormatter={scale === "time" ? formatDate : undefined}
          tickMargin={10}
        />
        <YAxis
          strokeWidth={2}
          tick={tickStyle}
          tickFormatter={yTickFormatter ? yTickFormatter : undefined}
          domain={yDomain ? yDomain : undefined}
          tickMargin={10}
        />

        {shouldShowTooltip && (
          <Tooltip
            contentStyle={{
              padding: "6px",
              borderRadius: "6px",
              borderColor: "#d1d5db",
            }}
            itemStyle={{
              fontSize: 14,
              fontWeight: 500,
              paddingTop: "0px",
              paddingBottom: "0px",
              lineHeight: 1.4,
            }}
            labelStyle={{
              fontSize: 14,
              fontWeight: "bold",
              lineHeight: 1,
              paddingBottom: "6px",
            }}
            labelFormatter={(value) => {
              if (scale === "time") {
                return formatDate(value);
              }

              return value;
            }}
          />
        )}

        {shouldShowLegend && (
          <Legend
            formatter={(value: string, entry: any) => {
              const { color } = entry;

              return (
                <span className="text-sm font-semibold" style={{ color }}>
                  {value}
                </span>
              );
            }}
          />
        )}

        {data[0].y_values.map((v, i) => (
          <Line
            type="monotone"
            dataKey={v.key}
            stroke={lineColors[i % lineColors.length]}
            strokeWidth={3}
            key={`${v.key}-${i}`}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

function formatDate(date: number) {
  return format(new Date(date), "MMM d, yyyy");
}
