import { Tables, TablesUpdate } from "../db/supabase.ts";

export type TeammateDB = Tables<"teammate">;
export type TeammateUpdateDB = TablesUpdate<"teammate">;

export interface Teammate {
  id: string;
  company_id: string | null;
  email: string;
  first_name: string | null;
  last_name: string | null;
  profile_picture_url: string | null;
  created_at: string;
  updated_at: string | null;
};

export function mapTeammateDBToTeammate(teammateDB: TeammateDB) {
  const teammate: Teammate = {
    id: teammateDB.id,
    email: teammateDB.email,
    company_id: teammateDB.company_id,
    first_name: teammateDB.first_name,
    last_name: teammateDB.last_name,
    profile_picture_url: teammateDB.profile_picture_url,
    created_at: teammateDB.created_at,
    updated_at: teammateDB.updated_at,
  };
  return teammate;
}
