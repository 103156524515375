import { FC } from "react";
import { InterviewWithContactAndActivities } from "../../app/admin_client_types";
import { useAppSelector } from "../../hooks/hook";
import { TranscriptFragmentText } from "../insights/transcriptFragmentText";
import {
  selectInterviewParameters,
  selectMatchingTranscriptFragmentForInterview,
} from "./interviewsSlice";

interface InterviewQuoteMatch {
  interview: InterviewWithContactAndActivities;
}

export const InterviewQuoteMatch: FC<InterviewQuoteMatch> = ({ interview }) => {
  const matchingTranscriptFragment = useAppSelector((state) =>
    selectMatchingTranscriptFragmentForInterview(state, interview.id)
  );

  const interviewParameters = useAppSelector(selectInterviewParameters);
  const searchText = interviewParameters?.search_text;

  const textTranscript = matchingTranscriptFragment?.text_transcript;
  if (!textTranscript || !searchText) return null;

  // Use searchText to narrow down text_transcript so we display less volume of text
  // Split the transcript into sentences and find first sentence that contains the search text
  const sentences = textTranscript.match(/[^.!?]+[.!?]+/g) || [textTranscript];
  const matchingSentenceIndex = sentences.findIndex((sentence) =>
    sentence.toLowerCase().includes(searchText.toLowerCase())
  );

  let displayText = sentences[matchingSentenceIndex];
  if (!displayText) return null;

  // Add ellipsis at the beginning if the matching sentence is not the first one
  if (matchingSentenceIndex > 0) {
    displayText = "..." + displayText;
  }

  // Add ellipsis at the end if the matching sentence is not the last one
  if (
    matchingSentenceIndex < sentences.length - 1 &&
    matchingSentenceIndex >= 0
  ) {
    displayText += "...";
  }

  return (
    <TranscriptFragmentText
      text={displayText}
      highlightedText={searchText}
      role={null}
    />
  );
};
