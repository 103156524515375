import { PencilIcon } from "@heroicons/react/24/outline";
import { BillingPlansEnumToDisplayName, LoadingStatesEnum } from "app-types";
import { FC, useEffect, useState } from "react";
import {
  Button,
  ButtonVariantsEnum,
  IconButton,
  Label,
  Link,
  SizesEnum,
  capitalize,
} from "ui";
import { getAxiosInstanceWithAuth } from "../../../api/axiosConfig";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import {
  fetchBillingInfo,
  selectDefaultPaymentMethod,
  selectPlanInfo,
} from "../../billing/billingSlice";
import {
  selectCompany,
  selectIsRecruitingModeCompany,
} from "../../company/companySlice";
import { GiftCardBalanceSection } from "./giftCardBalanceSection";

export const BillingSettingsSection: FC = () => {
  const dispatch = useAppDispatch();
  const company = useAppSelector(selectCompany);
  const isRecruitingMode = useAppSelector(selectIsRecruitingModeCompany);
  const [portalLoadingState, setPortalLoadingState] = useState(
    LoadingStatesEnum.LOADED
  );

  const planInfo = useAppSelector(selectPlanInfo);
  const defaultPaymentMethod = useAppSelector(selectDefaultPaymentMethod);
  if (!company) return null;

  useEffect(() => {
    dispatch(fetchBillingInfo());
  }, []);

  const openStripeBillingPortal = async (isPaymentMethodUpdate?: boolean) => {
    setPortalLoadingState(LoadingStatesEnum.LOADING);
    try {
      const axios = await getAxiosInstanceWithAuth();
      const path = `/billing/portal-session${
        isPaymentMethodUpdate ? "?type=payment_method_update" : ""
      }`;
      const { data } = await axios.post(path);
      setPortalLoadingState(LoadingStatesEnum.LOADED);

      // Navigate to the portal url rather than opening in popup
      // so we don't have listen to webhooks when payment methods are added
      window.location.href = data;
    } catch (err) {
      setPortalLoadingState(LoadingStatesEnum.ERROR);
    }
  };

  return (
    <div className="max-w-2xl min-w-[450px] mx-auto px-8">
      <div className="border-b border-gray-900/10 py-4">
        <h2 className="text-lg pb-2 font-semibold leading-6 text-gray-900">
          Plan
        </h2>
        <div className="py-2 flex flex-col space-y-4 text-sm">
          {company.billing_current_plan && (
            <div>
              <Label size={SizesEnum.SMALL}>Plan name</Label>
              <div className="text-gray-700">
                {BillingPlansEnumToDisplayName[company.billing_current_plan]}
              </div>
            </div>
          )}
          {defaultPaymentMethod && defaultPaymentMethod.is_card ? (
            <div>
              <Label size={SizesEnum.SMALL}>Payment Method</Label>
              <div className="text-gray-700 space-x-1 flex flex-row group">
                <span>
                  {`${capitalize(defaultPaymentMethod.brand)} ending in ${
                    defaultPaymentMethod.last4
                  } (${defaultPaymentMethod.exp_month >= 10 ? "" : "0"}${
                    defaultPaymentMethod.exp_month
                  }/${defaultPaymentMethod.exp_year})`}
                </span>
                <div className="hidden group-hover:flex">
                  <IconButton
                    variant={ButtonVariantsEnum.Tertiary}
                    icon={<PencilIcon className="h-3 w-3" />}
                    onClick={() => {
                      const isPaymentMethodUpdate = true;
                      openStripeBillingPortal(isPaymentMethodUpdate);
                    }}
                  />
                </div>
              </div>
            </div>
          ) : null}
          {company.billing_company_id && (
            <div className="flex flex-row space-x-3">
              <Button
                label="Manage Subscription & Invoices"
                onClick={() => openStripeBillingPortal()}
                variant={ButtonVariantsEnum.Secondary}
                isLoading={portalLoadingState === LoadingStatesEnum.LOADING}
              />
            </div>
          )}
          {portalLoadingState === LoadingStatesEnum.ERROR && (
            <div>
              {"Sorry, an error occurred. Please try again or "}
              <Link href="mailto:support@alpharun.com">
                reach out to our team
              </Link>

              {" for help."}
            </div>
          )}
          <div>
            {`Questions about your plan? `}
            <Link href="mailto:support@alpharun.com">Email us</Link>
            {" or "}
            <Link href="https://cal.frontapp.com/alpharun/paul/7fc09a3a">
              book a meeting
            </Link>
            {"."}
          </div>
        </div>
      </div>
      {!isRecruitingMode && (
        <div className="py-4">
          <h2 className="text-lg pb-2 font-semibold leading-6 text-gray-900">
            Interview incentives
          </h2>
          <GiftCardBalanceSection
            company={company}
            defaultPaymentMethod={defaultPaymentMethod}
            onClickAddPaymentMethod={() => {
              const isPaymentMethodUpdate = true;
              openStripeBillingPortal(isPaymentMethodUpdate);
            }}
            billingPortalLoadingState={portalLoadingState}
          />
        </div>
      )}
    </div>
  );
};
