// Given a string of HTML (not JSX), copy it to the clipboard so that it
// can be pasted into a rich text editor and preserve text formatting.
// Rich text copy works for Chrome and Safari, but `plaintext` is
// required as a fallback for Firefox which doesn't support ClipboardItem
export async function copyRichText(html: string, plaintext: string) {
  const typeRich = "text/html";
  const blobRich = new Blob([html], { type: typeRich });

  const typePlain = "text/plain";
  const blobPlain = new Blob([html], { type: typePlain });

  try {
    if (typeof ClipboardItem !== "undefined") {
      // Modern browsers with ClipboardItem support
      const data = [
        new ClipboardItem({ [typeRich]: blobRich, [typePlain]: blobPlain }),
      ];
      await navigator.clipboard.write(data);
    } else {
      // Fallback for Firefox and other browsers without ClipboardItem support
      // This will only copy plain text
      await navigator.clipboard.writeText(plaintext);
    }
  } catch (error) {
    console.error("Error copying text: ", error);
  }
}
