import type { Slice } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  BillingProductInfo,
  LoadingStatesEnum,
  PaymentMethodInfo,
} from "app-types";
import { getAxiosInstanceWithAuth } from "../../api/axiosConfig";
import type { RootState } from "../../app/store";

interface BillingState {
  billing: BillingInfo;
  giftCardBalance: GiftCardBalanceInfo;
}
export interface BillingInfo {
  defaultPaymentMethod: PaymentMethodInfo | null;
  planInfo: BillingProductInfo | null;
  status: LoadingStatesEnum;
  error: string | null;
}

export interface GiftCardBalanceInfo {
  balanceUnitAmount: number | null;
  pendingBalanceUnitAmount: number | null;
  status: LoadingStatesEnum;
  error: string | null;
}

const initialState = {
  billing: {
    defaultPaymentMethod: null,
    planInfo: null,
    status: LoadingStatesEnum.LOADING,
    error: null,
  },
  giftCardBalance: {
    // balances in cents
    balanceUnitAmount: null,
    pendingBalanceUnitAmount: null,
    status: LoadingStatesEnum.LOADING,
    error: null,
  },
} as BillingState;

export const fetchBillingInfo = createAsyncThunk(
  "billing/fetch",
  async (_args, thunkAPI) => {
    try {
      const axios = await getAxiosInstanceWithAuth();
      const { data } = await axios.get(`/billing/info`);

      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchGiftCardBalance = createAsyncThunk(
  "billing/fetchBalance",
  async (_args, thunkAPI) => {
    try {
      const axios = await getAxiosInstanceWithAuth();
      const { data } = await axios.post(`/billing/check-balance`);

      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Slice
export const billingSlice: Slice<BillingState> = createSlice({
  name: "billing",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBillingInfo.pending, (state) => {
        state.billing.status = LoadingStatesEnum.LOADING;
      })
      .addCase(fetchBillingInfo.fulfilled, (state, action) => {
        state.billing.status = LoadingStatesEnum.LOADED;
        state.billing.planInfo = action.payload.platform;
        state.billing.defaultPaymentMethod =
          action.payload.default_payment_method;
      })
      .addCase(fetchBillingInfo.rejected, (state, action) => {
        state.billing.status = LoadingStatesEnum.ERROR;
        state.billing.error = action.error.message || null;
      })
      .addCase(fetchGiftCardBalance.pending, (state) => {
        state.giftCardBalance.status = LoadingStatesEnum.LOADING;
      })
      .addCase(fetchGiftCardBalance.fulfilled, (state, action) => {
        state.giftCardBalance.status = LoadingStatesEnum.LOADED;
        state.giftCardBalance.balanceUnitAmount =
          action.payload.balance_unit_amount;
        state.giftCardBalance.pendingBalanceUnitAmount =
          action.payload.pending_unit_amount;
      })
      .addCase(fetchGiftCardBalance.rejected, (state, action) => {
        state.giftCardBalance.status = LoadingStatesEnum.ERROR;
        state.giftCardBalance.error = action.error.message || null;
      });
  },
});

export default billingSlice.reducer;

export const selectBillingInfo = (state: RootState) => state.billing.billing;
export const selectDefaultPaymentMethod = (state: RootState) =>
  state.billing.billing.defaultPaymentMethod;
export const selectPlanInfo = (state: RootState) =>
  state.billing.billing.planInfo;
export const selectBillingLoadingState = (state: RootState) =>
  state.billing.billing.status;
export const selectBalanceInfo = (state: RootState) =>
  state.billing.giftCardBalance;
