/*
 * Functions
 */

import { Company, Project } from "app-types";

export const joinClassnames = (...classes: (string | undefined)[]) => {
  return classes.filter(Boolean).join(" ");
};

export const isValidName = (name: string) => {
  return name.length > 0 && name.length < 36;
};

// Converts snake cased or camel cased strings to human readable strings
export const toHumanReadableString = (str: string) => {
  // Convert snake_case to space-separated words
  let result = str.replace(/_/g, " ");

  // Insert space before each uppercase letter for camelCase
  result = result.replace(/([a-z])([A-Z])/g, "$1 $2");

  // Capitalize the first letter and return
  return result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();
};

export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getInitials = (
  email: string | null,
  firstName: string | null,
  lastName: string | null
) => {
  const firstInitial = firstName?.charAt(0) || email?.charAt(0);
  if (!firstInitial) return ""; // If we only have a phone number for this contact

  const secondInitial = lastName?.charAt(0);
  return `${firstInitial}${secondInitial || ""}`;
};

export const getInterviewLinkForProject = (
  interviewAppBaseUrl: string,
  company: Company,
  project: Project
) => {
  // Fully whitelabeled company.
  if (company.settings.whitelabel_domain)
    return `${company.settings.whitelabel_domain}/i/${project.short_id}`;

  // Standard Alpharun interview link.
  return `${interviewAppBaseUrl}/${project.short_id}`;
};

/*
 * Types
 */

export enum SizesEnum {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

// FOr displaying "1st, 2nd etc."
export const getOrdinalForNumber = (n: number): string => {
  const suffixes = ["th", "st", "nd", "rd"];
  const v = n % 100;
  return n + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
};
