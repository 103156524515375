import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
} from "@reduxjs/toolkit";
import { LoadingStatesEnum, PhoneNumber } from "app-types";
import { Selector } from "react-redux";
import { getAxiosInstanceWithAuth } from "../../api/axiosConfig";
import { RootState } from "../../app/store";
import { createProject } from "../projects/projectsSlice";

export const phoneNumbersAdapter = createEntityAdapter<PhoneNumber>();

const initialState: EntityState<PhoneNumber> & {
  status: LoadingStatesEnum;
  error: string | null;
} = phoneNumbersAdapter.getInitialState({
  status: LoadingStatesEnum.LOADING,
  error: null,
});

export const fetchPhoneNumbers = createAsyncThunk(
  "phoneNumbers/fetch",
  async (_parameters, thunkAPI) => {
    try {
      const axios = await getAxiosInstanceWithAuth();
      const response = await axios.get("phone-numbers");

      return thunkAPI.fulfillWithValue(response.data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const phoneNumbersSlice = createSlice({
  name: "phoneNumbers",
  initialState,
  reducers: {
    setAllPhoneNumbers: phoneNumbersAdapter.setAll,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPhoneNumbers.pending, (state) => {
        state.status = LoadingStatesEnum.LOADING;
      })
      .addCase(fetchPhoneNumbers.fulfilled, (state, action) => {
        state.status = LoadingStatesEnum.LOADED;
        phoneNumbersAdapter.setAll(state, action.payload);
      })
      .addCase(fetchPhoneNumbers.rejected, (state, action) => {
        state.status = LoadingStatesEnum.ERROR;
        state.error = action.payload as string;
      })
      .addCase(createProject.fulfilled, (state, action) => {
        if (!action.payload.phone_number_extension) {
          return;
        }

        // If a new phone number extension was created during project creation, add it to the state.
        // Get the phone number for this extension first.
        const phoneNumber = phoneNumbersAdapter
          .getSelectors()
          .selectById(
            state,
            action.payload.phone_number_extension.phone_number_id
          );
        if (!phoneNumber) {
          return;
        }

        // Add this extension to the phone number's list of extensions
        phoneNumbersAdapter.updateOne(state, {
          id: phoneNumber.id,
          changes: {
            extensions: [
              ...phoneNumber.extensions,
              action.payload.phone_number_extension,
            ],
          },
        });
      });
  },
});

export const { setAllPhoneNumbers } = phoneNumbersSlice.actions;

export default phoneNumbersSlice.reducer;

export const selectAllPhoneNumbers: Selector<RootState, PhoneNumber[]> =
  createSelector(
    [(state: RootState) => state.phoneNumbers],
    (phoneNumbersState) =>
      phoneNumbersAdapter.getSelectors().selectAll(phoneNumbersState)
  );

export const selectPhoneNumberAndExtensionForProject = (
  projectId: string
): Selector<
  RootState,
  { phoneNumber: PhoneNumber; extension: string } | null
> =>
  createSelector(
    [(state: RootState) => state.phoneNumbers],
    (phoneNumbersState) => {
      const phoneNumbers = phoneNumbersAdapter
        .getSelectors()
        .selectAll(phoneNumbersState);
      for (const phoneNumber of phoneNumbers) {
        const extension = phoneNumber.extensions.find(
          (ext) => ext.project_id === projectId
        );
        if (extension) {
          return {
            phoneNumber: phoneNumber,
            extension: extension.extension,
          };
        }
      }
      return null;
    }
  );
