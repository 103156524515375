import { buildProjectUrlPath } from "app-types";
import { formatDistanceToNow } from "date-fns";
import * as React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Pill, PillColorsEnum, SizesEnum, TableSkeleton } from "ui";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { selectIsRecruitingModeCompany } from "../company/companySlice";
import { fetchAllProjects, selectAllProjects } from "./projectsSlice";

export const ProjectsTable: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchAllProjects());
  }, [dispatch]);

  const navigate = useNavigate();
  const isRecruitingModeCompany = useAppSelector(selectIsRecruitingModeCompany);
  const projects = useAppSelector((state) => selectAllProjects(state.projects));
  const { status: projectsLoadStatus } = useAppSelector(
    (state) => state.projects
  );

  const renderTableRows = () => {
    // Show a skeleton only if we're loading and there are no projects previously fetched
    if (projectsLoadStatus !== "succeeded" && projects.length === 0) {
      return <TableSkeleton />;
    }

    if (!projects.length) {
      return (
        <tr>
          <td
            className="px-3 py-9 text-center text-sm font-semibold text-gray-700"
            colSpan={100}
          >
            No projects created yet. Create one to get started.
          </td>
        </tr>
      );
    }

    return (
      <>
        {projects
          .sort(
            (a, b) =>
              new Date(b.updated_at as string).getTime() -
              new Date(a.updated_at as string).getTime()
          )
          .map((project) => (
            <tr
              className="hover:bg-blue-50 cursor-pointer"
              key={project.id}
              onClick={() => {
                navigate(buildProjectUrlPath(project.id));
              }}
            >
              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                {project.name}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <Pill
                  label={project.is_live ? "Live" : "Draft"}
                  color={
                    project.is_live ? PillColorsEnum.GREEN : PillColorsEnum.GREY
                  }
                  size={SizesEnum.SMALL}
                />
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {`${project.interview_count || "None"}`}
              </td>
              {!isRecruitingModeCompany && (
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {project.incentive_quantity
                    ? `$${project.incentive_quantity}`
                    : "None"}
                </td>
              )}
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {formatDistanceToNow(
                  new Date(project.updated_at || project.created_at),
                  {
                    addSuffix: true,
                  }
                )}
              </td>
            </tr>
          ))}
      </>
    );
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="flow-root">
        <div className="-mx-4 -my-2 overflow-x-visible sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      scope="col"
                    >
                      Name
                    </th>
                    <th
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      scope="col"
                    >
                      Status
                    </th>
                    <th
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      scope="col"
                    >
                      Interviews
                    </th>
                    {!isRecruitingModeCompany && (
                      <th
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        scope="col"
                      >
                        Incentive
                      </th>
                    )}
                    <th
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      scope="col"
                    >
                      Last updated
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {renderTableRows()}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
