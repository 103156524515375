import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { FC } from "react";
import { ButtonVariantsEnum, IconButton } from "ui";

interface PaginationControls {
  page: number;
  totalPages: number;
  onClickNextPage: () => void;
  onClickPreviousPage: () => void;
}

export const PaginationControls: FC<PaginationControls> = ({
  page,
  totalPages,
  onClickNextPage,
  onClickPreviousPage,
}) => {
  return (
    <div className="flex items-center space-x-2">
      <span className="text-sm text-gray-600 mr-1">
        Page {page} of {totalPages}
      </span>
      <IconButton
        variant={ButtonVariantsEnum.Secondary}
        onClick={onClickPreviousPage}
        isDisabled={page <= 1}
        icon={<ChevronLeftIcon className="h-4 w-4" />}
      ></IconButton>
      <IconButton
        variant={ButtonVariantsEnum.Secondary}
        onClick={onClickNextPage}
        isDisabled={page >= totalPages}
        icon={<ChevronRightIcon className="h-4 w-4" />}
      ></IconButton>
    </div>
  );
};
