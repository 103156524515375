import {
  ComplexQuestion,
  ComplexQuestionInsert,
  ComplexQuestionTypesEnum,
} from "app-types";
import { FC } from "react";
import { MatrixQuestionEditor } from "./matrixQuestionEditor";
import { MultipleChoiceQuestionEditor } from "./multipleChoiceQuestionEditor";

interface ComplexQuestionEditorProps {
  question: ComplexQuestion | ComplexQuestionInsert;
  onChange: (question: ComplexQuestion | ComplexQuestionInsert) => void;
}

export const ComplexQuestionEditor: FC<ComplexQuestionEditorProps> = ({
  question,
  onChange,
}) => {
  switch (question.type) {
    case ComplexQuestionTypesEnum.RATING: {
      return <MatrixQuestionEditor question={question} onChange={onChange} />;
    }
    case ComplexQuestionTypesEnum.MULTIPLE_CHOICE: {
      return (
        <MultipleChoiceQuestionEditor question={question} onChange={onChange} />
      );
    }
  }
};
