import type { PayloadAction, Slice } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  CompanyModesEnum,
  isCompanyBlocked,
  type Company,
  type CompanyUpdateDB,
} from "app-types";
import { getAxiosInstanceWithAuth } from "../../api/axiosConfig";
import { updateCompany } from "../../api/supabaseService";
import type { RootState } from "../../app/store";

export interface CompanyState {
  company: Company | null;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}
const initialState = {
  company: null,
  status: "idle",
  error: null,
} as CompanyState;

// Async Action
interface PostCompanyDataArgs {
  companyName: string;
  shouldEnableDomainLogin: boolean;
}

export const postCompanyData = createAsyncThunk(
  "company/post",
  async (args: PostCompanyDataArgs, thunkAPI) => {
    const { companyName, shouldEnableDomainLogin } = args;
    try {
      const axios = await getAxiosInstanceWithAuth();
      const response = await axios.post("/company", {
        name: companyName,
        should_enable_domain_login: shouldEnableDomainLogin,
      });

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

interface UpdateCompanyArgs {
  id: string;
  updates: CompanyUpdateDB;
}
export const updateCompanyData = createAsyncThunk(
  "company/update",
  async (args: UpdateCompanyArgs, thunkAPI) => {
    try {
      const updatedCompany = await updateCompany(args.id, args.updates);

      return updatedCompany;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Slice
export const companySlice: Slice<CompanyState> = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompany: (state, action: PayloadAction<Company>) => {
      return {
        ...state,
        company: action.payload,
        status: "succeeded",
        error: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postCompanyData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postCompanyData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.company = action.payload;
      })
      .addCase(postCompanyData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
      })
      .addCase(updateCompanyData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateCompanyData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.company = action.payload;
      })
      .addCase(updateCompanyData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
      });
  },
});

export const { setCompany } = companySlice.actions;
export default companySlice.reducer;

export const selectCompany = (state: RootState) => state.company.company;
export const selectCompanyLoadingState = (state: RootState) =>
  state.company.status;
export const selectIsCompanyBlocked = (state: RootState) => {
  const company = state.company.company;

  // Dont block if we're still loading the company
  if (!company) {
    return false;
  }

  return isCompanyBlocked(company);
};

export const selectIsRecruitingModeCompany = (state: RootState) => {
  return state.company.company?.settings.mode === CompanyModesEnum.RECRUITING;
};

export const selectIsUsingSSO = (state: RootState) => {
  return state.company.company?.settings.identity_provider_id !== null;
};

// TODO: remove after rollout
export const selectShouldShowInterviewLanguageFeatures = (state: RootState) => {
  return ["Penbrothers", "AcmeCRM", "Twilio testing"].includes(
    state.company.company?.name || ""
  );
};

export const selectIsCheatingDetectionEnabled = (state: RootState) => {
  // For debugging purposes, always show cheating detection for Alpharun teammates.
  if (state.teammate.teammate?.email.endsWith("@alpharun.com")) return true;

  return state.company.company?.settings.is_cheating_detection_enabled;
};
