import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { PlanFeatureName } from "app-types";
import { FC, useState } from "react";
import { Modal } from "ui";
import { UpsellBanner } from "./upsellBanner";

interface UpgradeTextProps {
  planFeatureName: PlanFeatureName;
}

export const UpgradeText: FC<UpgradeTextProps> = ({ planFeatureName }) => {
  const [shouldShowUpsellBanner, setShouldShowUpsellBanner] = useState(false);

  return (
    <>
      <span
        className="font-medium text-blue-600 hover:text-blue-500 cursor-pointer"
        onClick={() => setShouldShowUpsellBanner(true)}
      >
        {" "}
        Upgrade to access.
      </span>
      <Modal
        isOpen={shouldShowUpsellBanner}
        onCancel={() => setShouldShowUpsellBanner(false)}
      >
        <div className="flex justify-between items-center">
          <Dialog.Title
            as="h3"
            className="text-xl font-semibold leading-6 text-gray-900"
          >
            Upgrade for access
          </Dialog.Title>
          <XMarkIcon
            className="h-5 w-5 cursor-pointer"
            aria-hidden="true"
            onClick={() => setShouldShowUpsellBanner(false)}
          />
        </div>
        <div className="mt-6">
          <UpsellBanner planFeatureName={planFeatureName} />
        </div>
      </Modal>
    </>
  );
};
