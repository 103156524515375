import { ArrowUpIcon, TrashIcon } from "@heroicons/react/24/outline";
import { FC } from "react";
import { ButtonVariantsEnum, IconButton, Textarea } from "ui";

const maxCharactersPerOption = 110;

interface SurveyOptionsEditorProps {
  value: string;
  onChange: (value: string) => void;
  onRemove: () => void;
  onClickReorder: (direction: "up" | "down") => void;
  isDuplicate: boolean;
}

export const SurveyOptionEditor: FC<SurveyOptionsEditorProps> = ({
  value,
  onChange,
  onRemove,
  onClickReorder,
  isDuplicate,
}) => {
  // Handle duplicate options, reordering, and deleting

  return (
    <div className="w-full group relative">
      <div>
        <Textarea
          value={value}
          placeholder="Add a new survey option"
          onChange={(evt) => {
            onChange(evt.target.value);
          }}
          maxLength={maxCharactersPerOption}
          shouldDisableNewline
          errorDescription={
            isDuplicate ? "Duplicate options aren't allowed." : ""
          }
          autoFocus={!value} // Auto-focus if input is empty (when adding new value)
        />
      </div>
      <div className="absolute right-2 top-1/2 transform -translate-y-1/2 space-x-2 opacity-0 group-hover:opacity-100">
        <IconButton
          variant={ButtonVariantsEnum.Secondary}
          icon={<ArrowUpIcon className="h-4 w-4" />}
          onClick={() => {
            onClickReorder("up");
          }}
          tabIndex={-1}
        />
        <IconButton
          variant={ButtonVariantsEnum.Secondary}
          icon={<ArrowUpIcon className="h-4 w-4 transform rotate-180" />}
          onClick={() => {
            onClickReorder("down");
          }}
          tabIndex={-1}
        />
        <IconButton
          variant={ButtonVariantsEnum.Secondary}
          icon={<TrashIcon className="h-4 w-4" />}
          onClick={onRemove}
          tabIndex={-1}
        />
      </div>
    </div>
  );
};
