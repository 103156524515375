import { MultipleChoiceInsights } from "app-types";
import { FC } from "react";
import { InsightsTable } from "./insightsTable";
import { InsightsCard } from "./keywordTracker/keywordTrackerCardContainer";

interface MultipleChoiceSummaryCardProps {
  summary: MultipleChoiceInsights;
}

export const MultipleChoiceSummaryCard: FC<MultipleChoiceSummaryCardProps> = ({
  summary,
}) => {
  const { question, response_count, data } = summary;

  const rowData = data.map((vector) => {
    const percentage =
      vector.response_count > 0
        ? (vector.response_count / response_count) * 100
        : 0;

    return {
      value: vector.name,
      columns: [{ percentage, width: "14%" }],
    };
  });

  return (
    <InsightsCard
      header={
        <div className="flex flex-row items-center text-base font-semibold text-gray-900 space-x-1 min-h-[28px]">
          {question}
          {` (${summary.response_count} ${
            summary.response_count > 1 ? "responses" : "response"
          })`}
        </div>
      }
      body={<InsightsTable rowData={rowData} />}
    />
  );
};
