import { Dialog } from "@headlessui/react";
import {
  Company,
  getDialingPhoneNumber,
  getDisplayPhoneNumber,
  Project,
} from "app-types";
import copy from "copy-to-clipboard";
import { FC, useState } from "react";
import {
  Button,
  ButtonVariantsEnum,
  getInterviewLinkForProject,
  Modal,
  SmallTabs,
} from "ui";
import { copyRichText } from "../../helpers/utilities";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { selectCompany } from "../company/companySlice";
import {
  NotificationTypeEnum,
  showNotification,
} from "../notificationsOverlay/notificationsSlice";
import { selectPhoneNumberAndExtensionForProject } from "../phoneNumbers/phoneNumbersSlice";
import { selectTeammate } from "../teammate/teammateSlice";
import { TextToCopy } from "./textToCopy";

interface VoiceModeShareTemplatesModalProps {
  isOpen: boolean;
  project: Project;
  onClose: () => void;
  company: Company;
}

export const VoiceModeShareTemplatesModal: FC<
  VoiceModeShareTemplatesModalProps
> = ({ isOpen, onClose, project, company }) => {
  const dispatch = useAppDispatch();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selectedShareOptionIndex, setSelectedShareOptionIndex] = useState(0);

  const phoneNumberAndExtension = useAppSelector(
    selectPhoneNumberAndExtensionForProject(project.id)
  );

  const phoneNumberForDisplay = phoneNumberAndExtension
    ? getDisplayPhoneNumber(
        phoneNumberAndExtension.phoneNumber.number,
        phoneNumberAndExtension.extension
      )
    : "";
  const phoneNumberForDialing = phoneNumberAndExtension
    ? getDialingPhoneNumber(
        phoneNumberAndExtension.phoneNumber.number,
        phoneNumberAndExtension.extension
      )
    : "";

  const companyName = useAppSelector(selectCompany)?.name;
  const teammateFirstName = useAppSelector(selectTeammate)?.first_name;

  const inviteLinkUrl = getInterviewLinkForProject(
    import.meta.env.VITE_INTERVIEW_APP_BASE_URL,
    company,
    project
  );

  const template = getTemplate(
    phoneNumberForDisplay,
    phoneNumberForDialing,
    selectedTabIndex,
    companyName || "",
    teammateFirstName || "",
    inviteLinkUrl,
    selectedShareOptionIndex
  );

  const tabs =
    selectedShareOptionIndex === 0
      ? ["Candidate message", "Job posting note", "Dial instructions"]
      : ["Candidate message", "Job posting note"];

  return (
    <Modal isOpen={isOpen} onCancel={onClose}>
      <div className="flex flex-col items-center">
        <div className="flex flex-row justify-between">
          <Dialog.Title
            as="h3"
            className="text-xl font-semibold leading-6 text-gray-900 "
          >
            <div>Sharing templates</div>
          </Dialog.Title>
        </div>
        <div className="mt-4 flex flex-row">
          <div className="flex-1 flex flex-col space-y-3">
            <div>
              <SmallTabs
                tabs={["Phone number", "Interview link"]}
                selectedTabIndex={selectedShareOptionIndex}
                onSelectTabIndex={setSelectedShareOptionIndex}
              />
            </div>
            <div>
              <SmallTabs
                tabs={tabs}
                selectedTabIndex={selectedTabIndex}
                onSelectTabIndex={setSelectedTabIndex}
              />
            </div>
            <TextToCopy text={template.react} />
            <div>
              <Button
                label="Copy template"
                variant={ButtonVariantsEnum.Secondary}
                onClick={async () => {
                  if (template.richText)
                    await copyRichText(template.richText, template.plainText);
                  else {
                    copy(template.plainText);
                  }

                  showNotification(dispatch, {
                    id: `link-template-${new Date().getTime()}`,
                    primaryMessage: `Template copied`,
                    type: NotificationTypeEnum.SUCCESS,
                  });
                }}
              />
            </div>
          </div>
        </div>

        <div className="mt-5 sm:mt-6 flex justify-center">
          <Button
            variant={ButtonVariantsEnum.Primary}
            label={"Done"}
            onClick={onClose}
          />
        </div>
      </div>
    </Modal>
  );
};

function getTemplate(
  phoneNumberForDisplay: string,
  phoneNumberForDialing: string,
  selectedTabIndex: number,
  companyName: string,
  teammateFirstName: string,
  inviteLinkUrl: string,
  selectedShareOptionIndex: number
) {
  if (selectedShareOptionIndex === 0) {
    if (selectedTabIndex === 0) {
      const candidateMessageTemplate = `SUBJECT: Fast track interview for the {{ROLE_NAME}} role
My name is ${teammateFirstName}, and I'm a recruiter with ${companyName}. Your profile ranked in the top 5% for the {{ROLE_NAME}} role so I've decided to fast-track your application.

If you're interested, just call this number anytime for an instant 5-minute interview: ${phoneNumberForDisplay} or on mobile tap to call ${phoneNumberForDialing}

After that call, you'll skip our traditional interview process and get connected directly to the hiring manager for the role.

Let me know if you have any questions - really excited about your fit for this role.`;

      return {
        plainText: candidateMessageTemplate,
        richText: candidateMessageTemplate.replace(/\n/g, "<br>"),
        react: (
          <div style={{ whiteSpace: "pre-line" }}>
            {candidateMessageTemplate}
          </div>
        ),
      };
    } else if (selectedTabIndex === 1) {
      const jobDescriptionTemplate = `FAST TRACK INTERVIEW: for an instant 5 minute interview, call ${phoneNumberForDisplay} or on mobile tap to call ${phoneNumberForDialing}
You'll hear from us within 24 hours after your interview`;

      return {
        plainText: jobDescriptionTemplate,
        richText: jobDescriptionTemplate.replace(/\n/g, "<br>"),
        react: (
          <div style={{ whiteSpace: "pre-line" }}>{jobDescriptionTemplate}</div>
        ),
      };
    } else {
      const dialInstructionsTemplate = `Call ${phoneNumberForDisplay} or on mobile tap to call: ${phoneNumberForDialing}`;

      return {
        plainText: dialInstructionsTemplate,
        richText: dialInstructionsTemplate,
        react: <div>{dialInstructionsTemplate}</div>,
      };
    }
  } else {
    if (selectedTabIndex === 0) {
      const candidateMessageTemplate = `SUBJECT: Fast track interview for the {{ROLE_NAME}} role
My name is ${teammateFirstName}, and I'm a recruiter with ${companyName}. Your profile ranked in the top 5% for the {{ROLE_NAME}} role so I've decided to fast-track your application.

If you're interested, you can access your instant 5-minute interview here: ${inviteLinkUrl}

After that call, you'll skip our traditional interview process and get connected directly to the hiring manager for the role.

Let me know if you have any questions - really excited about your fit for this role.`;

      return {
        plainText: candidateMessageTemplate,
        richText: candidateMessageTemplate.replace(/\n/g, "<br>"),
        react: (
          <div style={{ whiteSpace: "pre-line" }}>
            {candidateMessageTemplate}
          </div>
        ),
      };
    } else if (selectedTabIndex === 1) {
      const jobDescriptionTemplate = `FAST TRACK INTERVIEW: access your instant 5-minute interview here: ${inviteLinkUrl}
You'll hear from us within 24 hours after your interview`;

      return {
        plainText: jobDescriptionTemplate,
        richText: jobDescriptionTemplate.replace(/\n/g, "<br>"),
        react: (
          <div style={{ whiteSpace: "pre-line" }}>{jobDescriptionTemplate}</div>
        ),
      };
    } else {
      const dialInstructionsTemplate = `Click this link for an instant 5-minute interview: ${inviteLinkUrl}`;

      return {
        plainText: dialInstructionsTemplate,
        richText: dialInstructionsTemplate,
        react: <div>{dialInstructionsTemplate}</div>,
      };
    }
  }
}
