import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { Keyword, TranscriptFragment } from "app-types";
import { Selector } from "react-redux";
import { RootState } from "../../app/store";
import {
  fetchInsightsAsync,
  selectKeywordTrackerMatchesForTrackerId,
} from "../insights/insightsSlice";
import { fetchNonEmptyInterviewsForProject } from "../interviews/interviewsSlice";

export const transcriptFragmentsAdapter =
  createEntityAdapter<TranscriptFragment>();

/*
 * Slice
 */

export const transcriptFragmentsSlice = createSlice({
  name: "transcriptFragments",
  initialState: transcriptFragmentsAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchInsightsAsync.fulfilled, (state, action) => {
      transcriptFragmentsAdapter.addMany(
        state,
        action.payload.transcript_fragments
      );
    });
    builder.addCase(
      fetchNonEmptyInterviewsForProject.fulfilled,
      (state, action) => {
        if (action.payload.matching_transcript_fragments) {
          transcriptFragmentsAdapter.upsertMany(
            state,
            action.payload.matching_transcript_fragments
          );
        }
      }
    );
  },
});

export default transcriptFragmentsSlice.reducer;

const transcriptFragmentsSelectors = transcriptFragmentsAdapter.getSelectors(
  (state: RootState) => state.transcriptFragments
);

export const selectTranscriptFragmentsDictionary = (state: RootState) =>
  state.transcriptFragments.entities;

type TranscriptFragmentMapProjectQuestion = Record<
  string,
  Record<string, TranscriptFragment[]>
>;

/*
 * Creates a mapping that looks like this:
 * {
 *   [project_id]: {
 *      [question]: [TranscriptFragment, TranscriptFragment, ...],
 *      [question]: [TranscriptFragment, TranscriptFragment, ...],
 *   }
 *   ...
 * }
 *
 * This allows us to efficiently display transcriptFragments by question in the UI.
 */
export const selectTranscriptFragmentsByProjectIdQuestion: (
  state: RootState
) => TranscriptFragmentMapProjectQuestion = createSelector(
  [
    transcriptFragmentsSelectors.selectAll,
    (state: RootState) => state.insights.transcriptFragmentIds,
  ],
  (transcriptFragments, transcriptFragmentIds) => {
    // Only show fragments that are in the current insights state
    const filteredFragments = transcriptFragments.filter((fragment) =>
      transcriptFragmentIds.includes(fragment.id)
    );

    return filteredFragments.reduce((acc, fragment) => {
      const { question, project_id } = fragment;

      if (!acc[project_id]) {
        acc[project_id] = {};
      }

      if (!question) {
        return acc;
      }

      if (!acc[project_id][question]) {
        acc[project_id][question] = [];
      }

      acc[project_id][question].push(fragment);

      return acc;
    }, {} as TranscriptFragmentMapProjectQuestion);
  }
);

export const selectTranscriptFragmentsByKeyword = (
  keywordTrackerId: string,
  selectedKeyword: Keyword | undefined
): Selector<RootState, TranscriptFragment[]> =>
  createSelector(
    [
      selectKeywordTrackerMatchesForTrackerId(keywordTrackerId),
      selectTranscriptFragmentsDictionary,
    ],
    (keywordTrackerMatches, transcriptFragmentsDictionary) => {
      if (!selectedKeyword) return [];

      const keywordMatch = keywordTrackerMatches[selectedKeyword.phrase];

      if (!keywordMatch) {
        return [];
      }

      const allTranscriptFragments = keywordMatch.transcript_fragment_ids
        .map((id) => transcriptFragmentsDictionary[id])
        .filter((tf): tf is TranscriptFragment => Boolean(tf));

      // Only display 1 transcript fragment per interview and sort newest to oldest
      const uniqueInterviews = new Set();
      const uniqueAndSortedFragments: TranscriptFragment[] =
        allTranscriptFragments
          .filter((fragment) => {
            const interviewId = fragment?.interview_id;
            if (!uniqueInterviews.has(interviewId)) {
              uniqueInterviews.add(interviewId);
              return true;
            }
            return false;
          })
          .sort(
            (a, b) =>
              new Date(b.start_time).getTime() -
              new Date(a.start_time).getTime()
          );

      return uniqueAndSortedFragments;
    }
  );

// Returns groups of transcript fragments for a question-summary keyword, where each group
// has primary fragment with any clarifying fragments. Ordered most recent to oldest
export const selectTranscriptFragmentGroupsForKeyword = (
  keywordTrackerId: string,
  selectedKeyword: Keyword | undefined
): Selector<RootState, TranscriptFragment[][]> =>
  createSelector(
    [
      selectKeywordTrackerMatchesForTrackerId(keywordTrackerId),
      selectTranscriptFragmentsDictionary,
    ],
    (keywordTrackerMatches, transcriptFragmentsDictionary) => {
      if (!selectedKeyword || !keywordTrackerMatches) return [];

      const keywordMatch = keywordTrackerMatches[selectedKeyword.phrase];

      if (!keywordMatch) {
        return [];
      }

      const transcriptFragmentGroups = keywordMatch.transcript_fragment_ids
        .map((id) => {
          const primaryFragment = transcriptFragmentsDictionary[id];
          if (!primaryFragment) return null;

          const clarifyingFragments =
            primaryFragment.dynamic_transcript_fragment_ids
              .map((id) => {
                return transcriptFragmentsDictionary[id];
              })
              .filter((tf): tf is TranscriptFragment => Boolean(tf));
          return [primaryFragment, ...clarifyingFragments];
        })
        .filter((tf): tf is TranscriptFragment[] => Boolean(tf))
        .sort(
          (a, b) =>
            new Date(b[0].start_time).getTime() -
            new Date(a[0].start_time).getTime()
        );

      return transcriptFragmentGroups;
    }
  );
