import { InterviewLanguagesEnum, LANGUAGE_TO_FLAG } from "app-types";
import { capitalize } from "lodash";
import { useState } from "react";
import { Label, Select, SizesEnum } from "ui";
import { useAppSelector } from "../../../hooks/hook";
import { selectShouldShowInterviewLanguageFeatures } from "../../company/companySlice";
import { SaveAndCancelButtons } from "../saveAndCancelButtons";

interface InterviewLanguageSettingProps {
  language: InterviewLanguagesEnum;
  onSave: (language: InterviewLanguagesEnum) => void;
}

export const InterviewLanguageSetting = ({
  language,
  onSave,
}: InterviewLanguageSettingProps) => {
  const [selectedLanguage, setSelectedLanguage] = useState(language);

  const languageOptions = Object.entries(InterviewLanguagesEnum).map(
    ([key, value]) => ({
      name: `${LANGUAGE_TO_FLAG[value]}\u00A0\u00A0${capitalize(
        key.toLowerCase()
      )}`,
      value: value,
    })
  );

  const hasChanges = selectedLanguage !== language;

  // Only show to select companies for now
  const shouldShow = useAppSelector(selectShouldShowInterviewLanguageFeatures);

  if (!shouldShow) {
    return null;
  }

  return (
    <div className="space-y-2">
      <Label size={SizesEnum.SMALL}>Interview Language</Label>
      <div className="w-[150px]">
        <Select
          currentSelection={languageOptions.find(
            (option) => option.value === selectedLanguage
          )}
          onChange={(option) => {
            setSelectedLanguage(option.value as InterviewLanguagesEnum);
          }}
          options={languageOptions}
        />
      </div>
      <div className="text-sm text-slate-600">
        Select the language that the AI interviewer will use during interviews.
        Your interview questions do not need to match this language.
      </div>
      {hasChanges && (
        <SaveAndCancelButtons
          onSave={() => {
            onSave(selectedLanguage);
          }}
          onCancel={() => {
            setSelectedLanguage(language);
          }}
        />
      )}
    </div>
  );
};
