import { Dialog } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { getInterviewDurationEstimate } from "app-types";
import copy from "copy-to-clipboard";
import { FC, useState } from "react";
import {
  Button,
  ButtonVariantsEnum,
  Label,
  Link,
  Modal,
  SizesEnum,
  SmallTabs,
  capitalize,
} from "ui";
import { copyRichText } from "../../helpers/utilities";
import { useAppDispatch } from "../../hooks/hook";
import {
  NotificationTypeEnum,
  showNotification,
} from "../notificationsOverlay/notificationsSlice";

interface InterviewLinkTipsModalProps {
  isOpen: boolean;
  incentiveQuantity: number | null;
  link: string;
  onClose: () => void;
  numQuestions: number;
}

export const InterviewLinkTipsModal: FC<InterviewLinkTipsModalProps> = ({
  isOpen,
  incentiveQuantity: incentiveAmount,
  link,
  onClose,
  numQuestions,
}) => {
  const dispatch = useAppDispatch();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const incentiveTip = [
    "Emphasize the gift card in your subject line and main message to dramatically increase completion rates.",
  ];
  const otherTips = [
    "Send your interview link to at least 50-100 participants to get a meaningful number of interviews and understand your conversion rates.",
    "To get ongoing interviews, add your link to automated touchpoints (like a welcome email or post cancellation email), rather than one-off manual email blasts.",
  ];

  const tips = incentiveAmount ? [...incentiveTip, ...otherTips] : otherTips;

  const useEmailVariant = selectedTabIndex === 0;
  const template = getTemplate(
    link,
    incentiveAmount,
    useEmailVariant,
    numQuestions
  );

  return (
    <Modal isOpen={isOpen} onCancel={onClose} size={SizesEnum.LARGE}>
      <div className="flex flex-col items-center">
        <div className="flex flex-row justify-between">
          <Dialog.Title
            as="h3"
            className="text-xl font-semibold leading-6 text-gray-900 "
          >
            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100 mb-4">
              <CheckCircleIcon
                className="h-6 w-6 text-green-600"
                aria-hidden="true"
              />
            </div>
            <div>{"Interview link copied"}</div>
          </Dialog.Title>
        </div>
        <div className="mt-6 flex flex-row space-x-4">
          <div className="flex-1 text-sm">
            <div className="mb-2">
              <Label size={SizesEnum.SMALL}>
                Tips for sharing your link with participants
              </Label>
            </div>
            {tips.map((tip, index) => {
              return (
                <div
                  className="mb-4 flex items-start justify-center space-x-3"
                  key={`tip-${index}`}
                >
                  <div className="mt-1 h-5 w-5 text-sm flex items-center justify-center rounded-full border border-blue-600 text-blue-600 flex-shrink-0">
                    {index + 1}
                  </div>
                  <span>{tip}</span>
                </div>
              );
            })}
            <div className="text-sm">
              <Link href="https://help.alpharun.com/en/articles/687232">
                Check out the guide
              </Link>
              {" for more examples, and reach out to "}
              <Link href="mailto:support@alpharun.com">
                support@alpharun.com
              </Link>
              {" with any questions."}
            </div>
          </div>
          <div className="flex-1 flex flex-col space-y-4">
            <Label size={SizesEnum.SMALL}>Sharing templates</Label>
            <div>
              <SmallTabs
                tabs={["Email", "Chat, SMS, Plaintext"]}
                selectedTabIndex={selectedTabIndex}
                onSelectTabIndex={setSelectedTabIndex}
              />
            </div>
            <div className="rounded-md text-xs bg-slate-50 py-2 px-2 border border-solid border-slate-300 w-full flex space-x-2  cursor-text mt-4">
              {template.react}
            </div>
            <div>
              <Button
                label="Copy template"
                variant={ButtonVariantsEnum.Secondary}
                onClick={async () => {
                  if (template.richText)
                    await copyRichText(template.richText, template.plainText);
                  else {
                    copy(template.plainText);
                  }

                  showNotification(dispatch, {
                    id: `link-template-${new Date().getTime()}`,
                    primaryMessage: `Template copied`,
                    type: NotificationTypeEnum.SUCCESS,
                  });
                }}
              />
            </div>
          </div>
        </div>

        <div className="mt-5 sm:mt-6 flex justify-center">
          <Button
            variant={ButtonVariantsEnum.Primary}
            label={"Got it"}
            onClick={onClose}
          />
        </div>
      </div>
    </Modal>
  );
};

function getTemplate(
  link: string,
  incentiveAmount: number | null,
  useEmailVariant: boolean,
  numQuestions: number
) {
  const abbreviatedDurationEstimate = getInterviewDurationEstimate(
    numQuestions,
    {
      shouldAbbreviate: true,
    }
  );

  const subject = `Subject: ${abbreviatedDurationEstimate} research survey with gift card`;
  const greeting = "Hi {{first_name}}";
  const introLine = `we'd love to get your feedback via a `;

  const durationEstimate = getInterviewDurationEstimate(numQuestions, {
    shouldUseSingularPhrasing: true,
  });

  const surveyLinkLinePlaintext = `${durationEstimate} survey: ${link} .`;
  const surveyLinkLineRichtext = `${durationEstimate} survey: <a href=${link} >${link}</a>`;
  const surveyLinkLineReact = (
    <span>
      {`${durationEstimate} survey: `}
      <Link href={link}>{link}</Link>
    </span>
  );
  const giftCardText = incentiveAmount
    ? `As a small thank you for your time, you'll receive a $${incentiveAmount} gift card 
(your choice of Amazon, Visa, charity donation, and more) after completing the survey.`
    : "";

  if (useEmailVariant)
    return {
      plainText: `${subject}\n\n${greeting},\n\n${capitalize(
        introLine
      )}${surveyLinkLinePlaintext}\n\n${giftCardText}`,
      richText: `<div>${subject}<br />${greeting},<br /><br />${capitalize(
        introLine
      )}${surveyLinkLineRichtext}<br/><br/>${giftCardText}</div>`,
      react: (
        <div>
          {subject}
          <br />
          {greeting}
          {","}
          <br />
          <br />
          {capitalize(introLine)}
          {surveyLinkLineReact}
          {incentiveAmount ? (
            <>
              <br />
              <br />
              {giftCardText}
            </>
          ) : null}
        </div>
      ),
    };

  // Plaintext variant with no subject
  return {
    plainText: `${greeting} - ${introLine}${surveyLinkLinePlaintext} ${giftCardText}`,
    react: (
      <div>
        {greeting}
        {" - "}
        {introLine}
        {surveyLinkLineReact} {giftCardText}
      </div>
    ),
  };
}
