import { FC } from "react";
import { Pill, PillColorsEnum, SizesEnum } from "ui";

interface InterviewAssessmentRatingProps {
  rating: any;
}

export const InterviewAssessmentRating: FC<InterviewAssessmentRatingProps> = ({
  rating,
}) => {
  if (typeof rating === "boolean") {
    return (
      <Pill
        label={rating ? "Yes" : "No"}
        size={SizesEnum.SMALL}
        color={rating ? PillColorsEnum.GREEN : PillColorsEnum.RED}
      />
    );
  }

  // Currently only used for CEFR language level.
  if (typeof rating === "string") {
    return (
      <Pill label={rating} size={SizesEnum.SMALL} color={PillColorsEnum.GREY} />
    );
  }

  return (
    <Pill label="Unknown" size={SizesEnum.SMALL} color={PillColorsEnum.GREY} />
  );
};
