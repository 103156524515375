import { LockClosedIcon } from "@heroicons/react/24/outline";
import {
  Company,
  HandlerOf,
  Project,
  ProjectModesEnum,
  ProjectUpdate,
  ResumeCollectionTypesEnum,
  convertToValidHttpsUrl,
  getCompanyHasFeatureAccess,
  isRecruitingModeProject,
} from "app-types";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Checkbox, Input, Label, SizesEnum } from "ui";
import { UpgradeText } from "../../billing/upgradeText";
import { SaveAndCancelButtons } from "../saveAndCancelButtons";
import { WelcomeAndOutroMessagesEditor } from "./welcomeAndOutroMessagesEditor";

interface AppearanceSettingsSectionProps {
  onSaveProject: HandlerOf<ProjectUpdate>;
  company: Company;
  project: Project;
}

export const AppearanceSettingsSection: FC<AppearanceSettingsSectionProps> = ({
  onSaveProject,
  company,
  project,
}) => {
  const navigate = useNavigate();
  const isVoiceModeProject = project.mode === ProjectModesEnum.VOICE_AGENT;
  const isRecruitingMode = isRecruitingModeProject(project);

  const [welcomeMessage, setWelcomeMessage] = useState(
    project.settings.welcome_message || ""
  );
  const [outroMessage, setOutroMessage] = useState(
    project.settings.outro_message || ""
  );
  const [redirectUrl, setRedirectUrl] = useState(
    project.settings.redirect_url || ""
  );
  const [redirectUrlError, setRedirectUrlError] = useState("");
  const [shouldRequestResume, setShouldRequestResume] = useState(
    project.settings.resume_collection_type
  );
  const [shouldForceFullscreen, setShouldForceFullscreen] = useState(
    project.settings.should_force_fullscreen
  );
  const [shouldRecordVideo, setShouldRecordVideo] = useState(
    project.settings.should_record_video
  );

  const isMissingFeatureAccess = !getCompanyHasFeatureAccess(
    company,
    "whitelabeling"
  );

  const onClickSaveRedirectUrl = () => {
    setRedirectUrlError("");

    if (!redirectUrl) {
      onSaveProject({
        id: project.id,
        settings: {
          ...project.settings,
          redirect_url: null,
        },
      });
      return;
    }

    const url = convertToValidHttpsUrl(redirectUrl);

    if (!url) {
      setRedirectUrlError(
        "Invalid URL - please provide a valid URL starting with https://"
      );
      return;
    }

    setRedirectUrl(url);
    onSaveProject({
      id: project.id,
      settings: {
        ...project.settings,
        redirect_url: url,
      },
    });
  };

  const onChangeShouldRequestResume = (isChecked: boolean) => {
    setShouldRequestResume(
      isChecked ? ResumeCollectionTypesEnum.AFTER_INTERVIEW : null
    );
    onSaveProject({
      id: project.id,
      settings: {
        ...project.settings,
        resume_collection_type: isChecked
          ? ResumeCollectionTypesEnum.AFTER_INTERVIEW
          : null,
      },
    });
  };

  const onChangeShouldForceFullscreen = (isChecked: boolean) => {
    setShouldForceFullscreen(isChecked);
    onSaveProject({
      id: project.id,
      settings: {
        ...project.settings,
        should_force_fullscreen: isChecked,
      },
    });
  };

  const onChangeShouldRecordVideo = (shouldRecordVideo: boolean) => {
    setShouldRecordVideo(shouldRecordVideo);
    onSaveProject({
      id: project.id,
      settings: {
        ...project.settings,
        should_record_video: shouldRecordVideo,
      },
    });
  };

  return (
    <div>
      <Label size={SizesEnum.LARGE}>
        {isVoiceModeProject ? "Interview Link Settings" : "Appearance"}
      </Label>
      <div className="mt-2 text-sm text-gray-600">
        You can add a custom logo and background color in{" "}
        <span
          className="font-medium text-blue-600 hover:text-blue-500 cursor-pointer"
          onClick={() => {
            navigate("/settings/organization");
          }}
        >
          organization settings
        </span>
        .
      </div>

      <WelcomeAndOutroMessagesEditor
        project={project}
        onSaveProject={onSaveProject}
      />

      {isRecruitingMode && (
        <>
          <div className="mt-2">
            <Label size={SizesEnum.SMALL} className="mb-2">
              Request resume
            </Label>
            <Checkbox
              isChecked={
                shouldRequestResume ===
                ResumeCollectionTypesEnum.AFTER_INTERVIEW
              }
              onChange={onChangeShouldRequestResume}
              label="Ask the candidate to upload their resume after they complete their interview."
            />
          </div>

          <div className="mt-2">
            <Label size={SizesEnum.SMALL} className="mb-2">
              Fullscreen mode
            </Label>
            <Checkbox
              isChecked={shouldForceFullscreen}
              onChange={onChangeShouldForceFullscreen}
              label="Force the interview to be conducted in a full-screen browser window to help prevent possible cheating."
            />
            <div className="mt-2 text-sm text-gray-600">
              If enabled, the interview will automatically open in fullscreen
              when started, which can help discourage candidates from cheating
              using other browser windows. Candidates that exit fullscreen mode
              will be flagged.
            </div>
          </div>
          <div className="mt-2">
            <Label size={SizesEnum.SMALL} className="mb-2">
              Record video
            </Label>
            <Checkbox
              isChecked={shouldRecordVideo}
              onChange={onChangeShouldRecordVideo}
              label="Record a video of the candidate during the interview."
            />
            <div className="mt-2 text-sm text-gray-600">
              If enabled, candidates will be required to provide webcam access
              during the interview and conduct their interview on a desktop
              computer.
            </div>
          </div>
        </>
      )}

      <label className="block text-sm font-semibold leading-6 text-gray-900 mt-4">
        Redirect URL
      </label>
      <div className="mt-2">
        <Input
          value={redirectUrl}
          onChange={(evt) => {
            setRedirectUrlError("");
            setRedirectUrl(evt.target.value);
          }}
          placeholder="https://example.com"
          errorDescription={redirectUrlError}
          inputClassName="max-w-[300px]"
          leftIcon={
            isMissingFeatureAccess ? (
              <LockClosedIcon className="h-4 w-4 text-gray-700" />
            ) : undefined
          }
          isDisabled={isMissingFeatureAccess}
          maxLength={200}
        />
      </div>
      {redirectUrl !== (project.settings.redirect_url || "") ? (
        <SaveAndCancelButtons
          onSave={onClickSaveRedirectUrl}
          onCancel={() => {
            setRedirectUrl(project.settings.redirect_url || "");
            setRedirectUrlError("");
          }}
        />
      ) : null}
      <div className="mt-2 text-sm text-gray-600">
        The URL to redirect participants to after an interview is completed.
        {isMissingFeatureAccess ? (
          <UpgradeText planFeatureName="whitelabeling" />
        ) : null}
      </div>
    </div>
  );
};
