import {
  CustomFieldDefinition,
  CustomFieldDefinitionInsertClient,
  InterviewLanguagesEnum,
} from "../../index.ts";
import { Tables, TablesInsert, TablesUpdate } from "../db/supabase.ts";
import { CustomFieldTypesEnum } from "./customField.ts";

export type AssessmentDB = Tables<"assessment">;
export type AssessmentUpdate = TablesUpdate<"assessment">;

export enum SpecialAssessmentFieldNamesEnum {
  CEFR_LANGUAGE_LEVEL_ENGLISH = "cefr_language_level_english",
  CEFR_LANGUAGE_LEVEL_GERMAN = "cefr_language_level_german",
}

export const CefrAssessmentFieldNameToLanguage = {
  [SpecialAssessmentFieldNamesEnum.CEFR_LANGUAGE_LEVEL_ENGLISH]:
    InterviewLanguagesEnum.ENGLISH,
  [SpecialAssessmentFieldNamesEnum.CEFR_LANGUAGE_LEVEL_GERMAN]:
    InterviewLanguagesEnum.GERMAN,
};
export const LanguageToCefrAssessmentFieldName = {
  [InterviewLanguagesEnum.ENGLISH]:
    SpecialAssessmentFieldNamesEnum.CEFR_LANGUAGE_LEVEL_ENGLISH,
  [InterviewLanguagesEnum.GERMAN]:
    SpecialAssessmentFieldNamesEnum.CEFR_LANGUAGE_LEVEL_GERMAN,
};

export const CANDIDATE_ALIGN_FIELD_NAME =
  "Candidate's preferences and working style are aligned with the job";

export const SpecialAssessmentFieldDetails = {
  [SpecialAssessmentFieldNamesEnum.CEFR_LANGUAGE_LEVEL_ENGLISH]: {
    display_name: "CEFR English level estimate",
    field_type: CustomFieldTypesEnum.TEXT,
  },
  [SpecialAssessmentFieldNamesEnum.CEFR_LANGUAGE_LEVEL_GERMAN]: {
    display_name: "CEFR German level estimate",
    field_type: CustomFieldTypesEnum.TEXT,
  },
};

export const isSpecialAssessmentFieldName = (fieldName: string) => {
  return Object.values(SpecialAssessmentFieldNamesEnum).includes(
    fieldName as SpecialAssessmentFieldNamesEnum
  );
};

export const getSpecialAssessmentField = (
  customFieldDefinitions: (
    | CustomFieldDefinition
    | CustomFieldDefinitionInsertClient
  )[],
  specialFieldName: SpecialAssessmentFieldNamesEnum
) => {
  return customFieldDefinitions.find((d) => d.field_name === specialFieldName);
};

export const getCEFRLanguageAssessmentField = (
  customFieldDefinitions: (
    | CustomFieldDefinition
    | CustomFieldDefinitionInsertClient
  )[]
) => {
  return customFieldDefinitions.find(
    (d) =>
      d.field_name ===
        SpecialAssessmentFieldNamesEnum.CEFR_LANGUAGE_LEVEL_ENGLISH ||
      d.field_name ===
        SpecialAssessmentFieldNamesEnum.CEFR_LANGUAGE_LEVEL_GERMAN
  );
};

export type AssessmentHasCustomFieldDefinitionDB =
  Tables<"assessment_custom_field_definition">;
export type AssessmentHasCustomFieldDefinitionInsert =
  TablesInsert<"assessment_custom_field_definition">;

export interface Assessment {
  id: string;
  custom_field_definitions: CustomFieldDefinition[];
}

export function mapAssessmentDBToAssessment(
  assessmentDB: AssessmentDB,
  customFieldDefinitions: CustomFieldDefinition[]
) {
  return {
    id: assessmentDB.id,
    custom_field_definitions: customFieldDefinitions,
  };
}

// Sorts assessment field so that special fields are displayed first
export function sortAssessmentFields(fields: CustomFieldDefinition[]) {
  return [...fields].sort((a, b) => {
    return isSpecialAssessmentFieldName(a.field_name) ? -1 : 1;
  });
}

export const ASSESSMENT_FIELD_NAME_MIN_LENGTH = 10;
export const ASSESSMENT_FIELD_NAME_MAX_LENGTH = 200;

export function getErrorMessageForAssessmentCriteriaValue(
  value: string
): string {
  if (value.length < ASSESSMENT_FIELD_NAME_MIN_LENGTH) {
    return `Criteria must be at least ${ASSESSMENT_FIELD_NAME_MIN_LENGTH} characters long`;
  }
  if (value.length > ASSESSMENT_FIELD_NAME_MAX_LENGTH) {
    return `Criteria must not exceed ${ASSESSMENT_FIELD_NAME_MAX_LENGTH} characters`;
  }

  // Don't allow the user to rename the field to one of the reserved field names
  if (isSpecialAssessmentFieldName(value)) {
    return "This field name is reserved, please choose another one";
  }

  return "";
}
