export const BalanceIncreaseOptionToValue: Record<string, number> = {
  $100: 100,
  $150: 150,
  $200: 200,
  $250: 250,
  $400: 400,
  $500: 500,
  $1000: 1000,
  $1500: 1500,
  $2000: 2000,
};

export const NO_BALANCE_REFILL_OPTION = "No refill";

// Do not remove options from these lists, since company settings save numerical values that correspond to these
export const DEFAULT_REFILL_AMOUNT_OPTION = "$400"; // Must be a key in REFILL_AMOUNT_OPTION_TO_VALUE
export const REFILL_AMOUNT_OPTION_TO_VALUE: Record<string, number> = {
  [NO_BALANCE_REFILL_OPTION]: 0,
  $100: 100,
  $200: 200,
  [DEFAULT_REFILL_AMOUNT_OPTION]: 400,
  $500: 500,
  $1000: 1000,
  $2000: 2000,
};
export const REFILL_AMOUNT_OPTIONS = Object.keys(
  REFILL_AMOUNT_OPTION_TO_VALUE
).map((key) => ({
  name: key,
}));

export const DEFAULT_REFILL_THRESHOLD_OPTION = "$200"; // Must be a key in REFILL_THRESHOLD_OPTION_TO_VALUE
export const REFILL_THRESHOLD_OPTION_TO_VALUE: Record<string, number> = {
  $100: 100,
  [DEFAULT_REFILL_THRESHOLD_OPTION]: 200,
  $400: 400,
  $500: 500,
  $1000: 1000,
};
export const REFILL_THRESHOLD_OPTIONS = Object.keys(
  REFILL_AMOUNT_OPTION_TO_VALUE
).map((key) => ({
  name: key,
}));

// Given a unit amount like 20000 (= $200 USD), find the corresponding Select option
export const findUnitAmountOption = (
  unitAmount: number,
  optionToValue: Record<string, number>
) => {
  return Object.keys(optionToValue).find(
    (option) => optionToValue[option] === unitAmount / 100
  );
};

export const balanceIncreaseOptions = Object.keys(BalanceIncreaseOptionToValue);
