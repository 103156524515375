import { CheckIcon, ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import { getDialingPhoneNumber, getDisplayPhoneNumber } from "app-types";
import copy from "copy-to-clipboard";
import { FC, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import {
  NotificationTypeEnum,
  showNotification,
} from "../../notificationsOverlay/notificationsSlice";
import { selectPhoneNumberAndExtensionForProject } from "../../phoneNumbers/phoneNumbersSlice";

interface PhoneNumberDisplaySectionProps {
  projectId: string;
}

export const PhoneNumberDisplaySection: FC<PhoneNumberDisplaySectionProps> = ({
  projectId,
}: PhoneNumberDisplaySectionProps) => {
  const dispatch = useAppDispatch();
  const [isCopyingPhoneNumber, setIsCopyingPhoneNumber] = useState(false);

  const phoneNumberAndExtension = useAppSelector(
    selectPhoneNumberAndExtensionForProject(projectId)
  );

  const phoneNumberForDisplay = phoneNumberAndExtension
    ? getDisplayPhoneNumber(
        phoneNumberAndExtension.phoneNumber.number,
        phoneNumberAndExtension.extension
      )
    : null;
  const phoneNumberForDialing = phoneNumberAndExtension
    ? getDialingPhoneNumber(
        phoneNumberAndExtension.phoneNumber.number,
        phoneNumberAndExtension.extension
      )
    : null;

  if (!phoneNumberForDisplay || !phoneNumberForDialing) {
    return (
      <div className="text-gray-500 text-sm max-w-[350px]">
        No phone number found - reach out to support@alpharun.com to get a phone
        number.
      </div>
    );
  }

  const handleCopyPhoneNumber = () => {
    setIsCopyingPhoneNumber(true);
    copy(
      `Call ${phoneNumberForDisplay}\nOr on mobile tap to call: ${phoneNumberForDialing}`
    );
    setTimeout(() => {
      setIsCopyingPhoneNumber(false);
    }, 4000);
    showNotification(dispatch, {
      id: `phone-number-${phoneNumberForDisplay}-copied-${new Date().getTime()}`,
      primaryMessage: `Dial instructions copied to clipboard`,
      type: NotificationTypeEnum.SUCCESS,
    });
  };

  return (
    <>
      <div className="mt-1 flex flex-row space-x-3">
        <div
          className="rounded-md bg-white py-2 px-2 border w-fit border-solid border-slate-300 flex space-x-2 hover:bg-slate-100 cursor-pointer"
          onClick={handleCopyPhoneNumber}
        >
          <span className="text-sm font-medium text-blue-600">
            {`Call ${phoneNumberForDisplay}`}
          </span>
          {isCopyingPhoneNumber ? (
            <CheckIcon className="h-5 w-5 text-blue-600" />
          ) : (
            <ClipboardDocumentIcon className="h-5 w-5 text-blue-600" />
          )}
        </div>
      </div>
    </>
  );
};
