import { ArrowUpOnSquareIcon, SparklesIcon } from "@heroicons/react/24/outline";
import {
  Attachment,
  ComplexAnswer,
  Contact,
  getCompanyHasFeatureAccess,
  InterviewCheatingRiskSummary,
  InterviewLanguagesEnum,
  InterviewLoaded,
  isCompletedCallMetadata,
  LoadingStatesEnum,
  TranscriptFragment,
} from "app-types";
import { FC, useEffect, useState } from "react";
import {
  Button,
  ButtonVariantsEnum,
  FileCard,
  Label,
  SizesEnum,
  Slideover,
} from "ui";
import { getAxiosInstanceWithAuth } from "../../../api/axiosConfig";
import { useAppSelector } from "../../../hooks/hook";
import { PaywallBanner } from "../../billing/paywallBanner";
import {
  selectCompany,
  selectIsCompanyBlocked,
} from "../../company/companySlice";
import { CustomFieldsSection } from "../../contacts/customFieldsSection";
import { AssessmentScore } from "../../insights/assessmentScore";
import { CheatingRiskSummary } from "../../insights/cheatingRiskSummary";
import { ContactNameWithTime } from "../../insights/contactNameWithTime";
import { selectSalesforceIntegration } from "../../integrations/integrationsSlice";
import { InterviewAssessmentSection } from "./interviewAssessmentSection";
import { InterviewDecisionButtons } from "./interviewDecisionButtons";
import { InterviewMoreMenu } from "./interviewMoreMenu";
import { InterviewSummary } from "./interviewSummary";
import { InterviewTranscript } from "./interviewTranscript";
import { SentimentSection } from "./sentimentSection";
import { ShareInterviewModal } from "./shareInterviewModal";
import { SurveyResponsesSection } from "./surveyResponses/surveyResponsesSection";

interface InterviewSlideoverProps {
  contact?: Contact;
  interview?: InterviewLoaded;
  onClickClose: () => void;
}

export const InterviewSlideover: FC<InterviewSlideoverProps> = ({
  contact,
  interview,
  onClickClose,
}) => {
  const shouldShow = Boolean(contact && interview);

  const [loadingState, setLoadingState] = useState(LoadingStatesEnum.LOADING);
  const [transcriptFragments, setTranscriptFragments] = useState<
    TranscriptFragment[]
  >([]);
  const [highlightedTranscriptFragment, setHighlightedTranscriptFragment] =
    useState<string | null>(null);

  const [complexAnswers, setComplexAnswers] = useState<ComplexAnswer[]>([]);
  const [recordingIdToUrl, setRecordingIdToUrl] = useState<{
    [key: string]: string;
  }>({});
  const [callIdToVideoUrl, setCallIdToVideoUrl] = useState<{
    [key: string]: string;
  }>({});
  const [contactAttachments, setContactAttachments] = useState<Attachment[]>(
    []
  );
  const [cheatingRiskSummary, setCheatingRiskSummary] =
    useState<InterviewCheatingRiskSummary | null>(null);

  const company = useAppSelector(selectCompany);
  const salesforceIntegration = useAppSelector(selectSalesforceIntegration); // TODO: adapt for other providers
  const isCompanyBlocked = useAppSelector(selectIsCompanyBlocked);

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [transcriptFragmentToShare, setTranscriptFragmentToShare] =
    useState<TranscriptFragment | null>(null);
  const [targetLanguage, setTargetLanguage] =
    useState<InterviewLanguagesEnum | null>(null);

  // Load transcriptFragments for this interview and files for this contact.
  useEffect(() => {
    if (!interview || !contact) {
      setContactAttachments([]); // Clear the attachments state when slideover closes.
      setCheatingRiskSummary(null); // Clear the cheating risk summary state when slideover closes.
      return;
    }

    const fetchData = async () => {
      setLoadingState(LoadingStatesEnum.LOADING);

      try {
        const axios = await getAxiosInstanceWithAuth();
        const [insightsResponse, attachmentsResponse] = await Promise.all([
          axios.get(`/insights/interviews/${interview.id}`, {
            params: targetLanguage
              ? { target_language: targetLanguage }
              : undefined,
          }),
          axios.get(`/attachment/list-for-contact/${contact.id}`),
        ]);

        const insightsData = insightsResponse.data;
        const attachmentsData = attachmentsResponse.data;

        setTranscriptFragments(insightsData.transcript_fragments);
        setRecordingIdToUrl(insightsData.recording_id_to_url);
        setCallIdToVideoUrl(insightsData.call_id_to_video_url);

        if (insightsData.complex_answers.length > 0) {
          setComplexAnswers(insightsData.complex_answers);
        }

        setContactAttachments(attachmentsData);

        setCheatingRiskSummary(insightsData.cheating_risk_summary);

        setLoadingState(LoadingStatesEnum.LOADED);
      } catch (err) {
        console.error("Error fetching data:", err);
        setLoadingState(LoadingStatesEnum.ERROR);
      }
    };

    fetchData();
  }, [interview?.id, contact?.id, targetLanguage]);

  const findFragmentFromText = (text: string) => {
    // Convert to lower case and remove any trailing periods.
    const trimmedText = text.toLowerCase().replace(/\.$/, "");

    return transcriptFragments.find(
      (f) =>
        f.text_transcript &&
        f.text_transcript.toLowerCase().includes(trimmedText)
    );
  };

  const jumpToFragmentByText = (text: string) => {
    const fragment = findFragmentFromText(text);
    if (fragment) {
      setHighlightedTranscriptFragment(fragment.id);
    }
  };

  const onShareSentimentTextClick = (text: string) => {
    const fragment = findFragmentFromText(text);
    if (fragment) {
      setIsShareModalOpen(true);
      setTranscriptFragmentToShare(fragment);
    }
  };

  const handleAttachmentDownload = async (attachmentId: string) => {
    try {
      const axios = await getAxiosInstanceWithAuth();
      const response = await axios.get(
        `/attachment/presigned-url/${attachmentId}`
      );
      const { url } = response.data;

      if (url) {
        window.open(url, "_blank");
      }
    } catch (err) {
      console.error("Error downloading file:", err);
    }
  };

  const renderSlideoverContent = () => {
    if (!interview || !contact) {
      return null;
    }

    const shouldShowSalesforceFields =
      salesforceIntegration &&
      company &&
      getCompanyHasFeatureAccess(company, "salesforce_integration");

    const { assessment_score, sentiment_score } = interview.insights;

    return (
      <div className="flex flex-col px-6">
        <div className="sticky top-0 z-10 py-4 bg-white border-b border-gray-200 flex justify-between items-center">
          <ContactNameWithTime
            contact={contact}
            date={
              interview.started_at ? new Date(interview.started_at) : undefined
            }
          />
          <div className="flex space-x-3 items-center">
            <InterviewDecisionButtons interview={interview} />
            <Button
              variant={ButtonVariantsEnum.Secondary}
              onClick={() => setIsShareModalOpen(true)}
              size={SizesEnum.MEDIUM}
              icon={
                <ArrowUpOnSquareIcon
                  className="h-4 w-4 mr-1 text-white-900"
                  aria-hidden="true"
                />
              }
              label="Share"
            />
            <InterviewMoreMenu
              interview={interview}
              onClickDeleteInterview={() => {
                onClickClose();
              }}
            />
          </div>
        </div>
        <div className="overflow-auto pb-6">
          {shouldShowSalesforceFields ? (
            <CustomFieldsSection
              contact={contact}
              integration={salesforceIntegration}
            />
          ) : undefined}
          {contactAttachments.length > 0 && (
            <>
              <Label size={SizesEnum.SMALL} className="mt-4 mb-2">
                Resume
              </Label>
              <div className="flex flex-wrap gap-2">
                {contactAttachments.map((attachment) => (
                  <FileCard
                    key={attachment.id}
                    name={`${
                      attachment.type.charAt(0).toUpperCase() +
                      attachment.type.slice(1)
                    } ${new Date(attachment.created_at).toLocaleString()}`}
                    onDownload={() => handleAttachmentDownload(attachment.id)}
                  />
                ))}
              </div>
            </>
          )}
          <Label size={SizesEnum.SMALL} className="mt-4 mb-2">
            {assessment_score ? "Assessment" : "Summary"}
            <SparklesIcon className="h-4 w-4 ml-1" />
          </Label>
          {assessment_score && (
            <div className="flex gap-2 mb-2">
              <AssessmentScore
                score={assessment_score}
                size={SizesEnum.MEDIUM}
              />
              <CheatingRiskSummary
                interview={interview}
                cheatingRiskSummary={cheatingRiskSummary}
              />
            </div>
          )}
          <InterviewSummary insights={interview.insights} />
          {sentiment_score && (
            <>
              <Label size={SizesEnum.SMALL} className="mt-4 mb-2">
                Sentiment <SparklesIcon className="h-4 w-4 ml-1" />
              </Label>
              <SentimentSection
                insights={interview.insights}
                onClickText={jumpToFragmentByText}
                onClickShare={onShareSentimentTextClick}
              />
            </>
          )}
          <InterviewAssessmentSection interview={interview} />
          <SurveyResponsesSection
            interviewQuestions={interview.questions}
            complexAnswers={complexAnswers}
          />
          <Label size={SizesEnum.SMALL} className="mt-4 mb-2">
            Transcript
          </Label>
          <div className="flex flex-col">
            <InterviewTranscript
              loadingState={loadingState}
              transcriptFragments={transcriptFragments}
              highlightedTranscriptFragment={highlightedTranscriptFragment}
              onClickShare={(fragment) => {
                setIsShareModalOpen(true);
                setTranscriptFragmentToShare(fragment);
              }}
              onHighlightFragment={setHighlightedTranscriptFragment}
              calls={
                interview.metadata?.calls.filter(isCompletedCallMetadata) || []
              }
              recordingIdToUrl={recordingIdToUrl}
              callIdToVideoUrl={callIdToVideoUrl}
              onClickTranslate={(language) => setTargetLanguage(language)}
              interviewInsights={interview.insights}
              jumpToFragmentByText={jumpToFragmentByText}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderShareModal = () => {
    if (!interview || !contact) {
      return null;
    }

    return (
      <ShareInterviewModal
        isOpen={isShareModalOpen}
        onClose={() => {
          setIsShareModalOpen(false);
          setTranscriptFragmentToShare(null);
        }}
        interview={interview}
        transcriptFragment={transcriptFragmentToShare}
        contact={contact}
      />
    );
  };

  return (
    <Slideover
      onClickClose={onClickClose}
      title={"Interview details"}
      shouldShow={shouldShow}
    >
      {isCompanyBlocked ? (
        <div className="p-6">
          <PaywallBanner />
        </div>
      ) : (
        renderSlideoverContent()
      )}
      {renderShareModal()}
    </Slideover>
  );
};
