import { Company } from "../..";

export enum BillingPlansEnum {
  STARTER = "starter-1",
  GROWTH = "growth-1",
  SCALE = "scale-1",
}
const planOrder: BillingPlansEnum[] = [
  BillingPlansEnum.STARTER,
  BillingPlansEnum.GROWTH,
  BillingPlansEnum.SCALE,
];
export const BillingPlansEnumToDisplayName = {
  [BillingPlansEnum.STARTER]: "Starter",
  [BillingPlansEnum.GROWTH]: "Growth",
  [BillingPlansEnum.SCALE]: "Scale",
};

interface PlanFeatures {
  salesforce_integration: boolean;
  project_level_context: boolean;
  whitelabeling: boolean;
}
export type PlanFeatureName = keyof PlanFeatures;

export const PLAN_FEATURE_ACCESS: Record<BillingPlansEnum, PlanFeatures> = {
  [BillingPlansEnum.STARTER]: {
    salesforce_integration: false,
    project_level_context: false,
    whitelabeling: false,
  },
  [BillingPlansEnum.GROWTH]: {
    salesforce_integration: true,
    project_level_context: true,
    whitelabeling: true,
  },
  [BillingPlansEnum.SCALE]: {
    salesforce_integration: true,
    project_level_context: true,
    whitelabeling: true,
  },
};

export const companyIsInTrial = (company: Company) =>
  company.trial_expires_at && new Date(company.trial_expires_at) > new Date();

export const getCompanyHasFeatureAccess = (
  company: Company,
  featureName: PlanFeatureName
) => {
  if (companyIsInTrial(company)) return true;

  if (!company.billing_current_plan) return false;

  return PLAN_FEATURE_ACCESS[company.billing_current_plan][featureName];
};

export const getLowestPlanNameWithFeatureAccess = (
  featureName: PlanFeatureName
) => {
  // Iterate over each plan in the defined order
  for (const plan of planOrder) {
    const features = PLAN_FEATURE_ACCESS[plan];
    if (features[featureName]) {
      // If the feature is enabled in this plan, return the plan's text representation
      return BillingPlansEnumToDisplayName[plan];
    }
  }

  throw new Error("Feature not available on any plan");
};
