import { MatrixQuestionInsights, SurveyScaleToRatings } from "app-types";
import { FC } from "react";
import { InsightsTable, InsightsTableRowData } from "./insightsTable";
import { InsightsCard } from "./keywordTracker/keywordTrackerCardContainer";

interface MatrixSummaryCardProps {
  summary: MatrixQuestionInsights;
}

export const MatrixSummaryCard: FC<MatrixSummaryCardProps> = ({ summary }) => {
  const { question, scale, response_count, data } = summary;

  if (!scale) return null;

  const ratingScaleMembers = SurveyScaleToRatings[scale];
  const columnNames = ratingScaleMembers.map((r) => r.name);

  const rowData: InsightsTableRowData[] = data.map((vector) => {
    const columns = ratingScaleMembers.map((member) => {
      const count = vector.ratings[member.rating];
      const percentage = count > 0 ? (count / response_count) * 100 : 0;
      return { percentage, width: "14%" };
    });

    return {
      value: vector.name,
      columns,
    };
  });

  return (
    <InsightsCard
      header={
        <div className="flex flex-row items-center text-base font-semibold text-gray-900 space-x-1 min-h-[28px]">
          {question}
          {` (${summary.response_count} ${
            summary.response_count > 1 ? "responses" : "response"
          })`}
        </div>
      }
      body={<InsightsTable rowData={rowData} headerColumns={columnNames} />}
    />
  );
};
