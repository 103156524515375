import { FC } from "react";
import { useAppSelector } from "../../hooks/hook";
import { selectCompanyCompetitorsTracker } from "../keywordTrackers/keywordTrackersSlice";
import { KeywordTrackerEditor } from "./keywordTrackerEditor";

export const KeywordTrackersSettingsSection: FC = () => {
  const competitorsTracker = useAppSelector(selectCompanyCompetitorsTracker);

  return (
    <div className="max-w-2xl min-w-[450px] mx-auto px-8">
      <div className="py-6">
        <div className="text-sm pb-4 text-gray-600">
          Keyword Trackers allow you to monitor specific phrases in interviews.
        </div>
        {competitorsTracker ? (
          <KeywordTrackerEditor
            key={`keyword-tracker-editor-${competitorsTracker.id}`}
            keywordTracker={competitorsTracker}
          />
        ) : null}
      </div>
    </div>
  );
};
