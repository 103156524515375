import { FC, useState } from "react";
import { Input } from "ui";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import {
  NotificationTypeEnum,
  showNotification,
} from "../notificationsOverlay/notificationsSlice";
import { putTeammateData, selectTeammate } from "../teammate/teammateSlice";

const ProfileSettingsSection: FC = () => {
  const dispatch = useAppDispatch();
  const teammate = useAppSelector(selectTeammate);
  if (!teammate) return null;

  const [firstName, setFirstName] = useState(teammate.first_name || "");
  const [lastName, setLastName] = useState(teammate.last_name || "");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");

  const onClickSave = async () => {
    // We need a first name and last name
    if (!firstName) setFirstNameError("First name is required");
    if (!lastName) setLastNameError("Last name is required");

    // If any of the fields are missing, let user fill in
    if (!firstName || !lastName) return;

    // Update the teammate first name and last name if necessary
    if (teammate.first_name !== firstName || teammate.last_name != lastName) {
      dispatch(
        putTeammateData({
          ...teammate,
          first_name: firstName,
          last_name: lastName,
        })
      );

      showNotification(dispatch, {
        id: `profile-updated-${new Date().getTime()}`,
        primaryMessage: `Profile successfully updated`,
        type: NotificationTypeEnum.SUCCESS,
      });
    }
  };

  return (
    <div className="max-w-2xl min-w-[450px] mx-auto px-8">
      <div className="border-b border-gray-900/10 pb-8">
        <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-4">
            <Input
              label="Email address"
              onChange={() => {
                /* noop for disabled input*/
              }}
              value={teammate.email}
              readOnly
              isDisabled
            />
          </div>
        </div>
        <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <Input
              label="First name"
              value={firstName}
              onChange={(evt) => {
                setFirstNameError("");
                setFirstName(evt.target.value);
              }}
              autoComplete="given-name"
              errorDescription={firstNameError}
            />
          </div>
          <div className="sm:col-span-3">
            <Input
              label="Last name"
              value={lastName}
              onChange={(evt) => {
                setLastNameError("");
                setLastName(evt.target.value);
              }}
              autoComplete="family-name"
              errorDescription={lastNameError}
            />
          </div>
        </div>
      </div>
      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          type="button"
          onClick={onClickSave}
          className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default ProfileSettingsSection;
