import type { EntityState } from "@reduxjs/toolkit";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import type { Contact } from "app-types";
import { RootState } from "../../app/store";
import {
  fetchFocusedInterview,
  fetchNonEmptyInterviewsForProject,
} from "../interviews/interviewsSlice";

export const contactsAdapter = createEntityAdapter<Contact>();

const initialState: EntityState<Contact> & {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
} = contactsAdapter.getInitialState({
  status: "idle",
  error: null,
});

export const selectAllContacts = contactsAdapter.getSelectors().selectAll;
export const selectContactsDictionary = (state: RootState) =>
  contactsAdapter
    .getSelectors((state: RootState) => state.contacts)
    .selectEntities(state);

export const contactSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    contactAdded: contactsAdapter.addOne,
    contactsAdded: contactsAdapter.addMany,
    contactRemoved: contactsAdapter.removeOne,
    contactUpdated: contactsAdapter.updateOne,
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchNonEmptyInterviewsForProject.fulfilled,
      (state, action) => {
        state.status = "succeeded";
        // This thunk is in the interviewsSlice but creates both contacts
        // and interviews, so we handle updating contact state here.
        contactsAdapter.addMany(state, action.payload.contacts);
      }
    );
    builder.addCase(fetchFocusedInterview.fulfilled, (state, action) => {
      contactsAdapter.upsertOne(state, action.payload.contact);
    });
  },
});

export const { contactAdded, contactsAdded, contactRemoved, contactUpdated } =
  contactSlice.actions;
export default contactSlice.reducer;
