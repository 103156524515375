import axios, { AxiosInstance } from "axios";
import { supabase } from "./supabaseService";

const instance = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

/*
 * Example usage in an async function:
 *   const axios = await getAxiosInstanceWithAuth();
 *   const response = axios.post("/company", { name: companyName });
 */
export function getAxiosInstanceWithAuth(): Promise<AxiosInstance> {
  // getSession() will use the refresh token to get a new session if accessToken has expired
  return supabase.auth.getSession().then(async ({ data: { session } }) => {
    if (!session)
      throw new Error("Attempting to make a request with no session");

    // Update the accessToken on our axios instance
    instance.defaults.headers[
      "Authorization"
    ] = `Bearer ${session.access_token}`;

    return instance;
  });
}
