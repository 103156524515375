import { FC } from "react";

interface Column {
  percentage: number;
  width?: string;
}

export interface InsightsTableRowData {
  value: string;
  columns: Column[];
}

interface InsightsTableProps {
  rowData: InsightsTableRowData[];
  headerColumns?: string[];
}

const getBackgroundColor = (percentage: number) => {
  if (percentage >= 80) return "bg-blue-700 text-white";
  if (percentage >= 60) return "bg-blue-500 text-white";
  if (percentage >= 40) return "bg-blue-300 text-gray-900";
  if (percentage >= 20) return "bg-blue-100 text-gray-900";
  if (percentage > 0) return "bg-blue-50 text-gray-900";
  return "bg-white text-gray-900";
};

export const InsightsTable: FC<InsightsTableProps> = ({
  rowData,
  headerColumns,
}) => {
  return (
    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5">
      <table className="min-w-full divide-y divide-gray-200">
        {headerColumns && headerColumns.length > 0 && (
          <thead className="bg-gray-50">
            <tr>
              <th
                className="py-1 pl-3 pr-2 text-left text-sm font-medium text-gray-900"
                scope="col"
              ></th>
              {headerColumns.map((name, index) => (
                <th
                  key={index}
                  className="px-2 py-1 text-center text-sm font-medium text-gray-900 w-[14%]"
                  scope="col"
                >
                  {name}
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody className="divide-y divide-gray-200 bg-white">
          {rowData.map((row, rowIndex) => (
            <tr key={rowIndex} className="divide-x divide-gray-200">
              <td className="py-2 pl-3 pr-2 text-left text-sm font-medium text-gray-900 border-r border-gray-200">
                {row.value}
              </td>
              {row.columns.map((col, colIndex) => (
                <td
                  key={colIndex}
                  className={`px-3 py-3 text-center text-sm font-medium text-gray-900 ${getBackgroundColor(
                    col.percentage
                  )}`}
                  style={{ width: col.width }}
                >
                  {`${col.percentage.toFixed(0)}%`}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
