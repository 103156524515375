import { Teammate } from "app-types";
import { FC, useState } from "react";
import { getInitials } from "ui";

function getColorSet(email: string) {
  const colorSet = [
    { light: "bg-red-300", text: "text-red-800", ring: "ring-red-800" },
    { light: "bg-green-300", text: "text-green-800", ring: "ring-green-800" },
    { light: "bg-blue-300", text: "text-blue-800", ring: "ring-blue-800" },
    {
      light: "bg-indigo-300",
      text: "text-indigo-800",
      ring: "ring-indigo-800",
    },
    {
      light: "bg-orange-300",
      text: "text-orange-800",
      ring: "ring-orange-800",
    },
    { light: "bg-amber-300", text: "text-amber-800", ring: "ring-amber-800" },
    {
      light: "bg-yellow-300",
      text: "text-yellow-800",
      ring: "ring-yellow-800",
    },
    { light: "bg-lime-300", text: "text-lime-800", ring: "ring-lime-800" },
    { light: "bg-green-300", text: "text-green-800", ring: "ring-green-800" },
    {
      light: "bg-emerald-300",
      text: "text-emerald-800",
      ring: "ring-emerald-800",
    },
    { light: "bg-teal-300", text: "text-teal-800", ring: "ring-teal-800" },
    { light: "bg-cyan-300", text: "text-cyan-800", ring: "ring-cyan-800" },
    { light: "bg-sky-300", text: "text-sky-800", ring: "ring-sky-800" },
    { light: "bg-blue-300", text: "text-blue-800", ring: "ring-blue-800" },
    {
      light: "bg-indigo-300",
      text: "text-indigo-800",
      ring: "ring-indigo-800",
    },
    { light: "bg-rose-300", text: "text-rose-800", ring: "ring-rose-800" },
    { light: "bg-pink-300", text: "text-pink-800", ring: "ring-pink-800" },
    {
      light: "bg-fuchsia-300",
      text: "text-fuchsia-800",
      ring: "ring-fuchsia-800",
    },
    {
      light: "bg-purple-300",
      text: "text-purple-800",
      ring: "ring-purple-800",
    },
    {
      light: "bg-violet-300",
      text: "text-violet-800",
      ring: "ring-violet-800",
    },
  ];

  // Use a hash function to consistently map the email to an index in the colorSet array
  const hashCode = email
    .split("")
    .reduce((acc, char) => acc + char.charCodeAt(0), 0);
  const colorIndex = hashCode % colorSet.length;

  return colorSet[colorIndex];
}

export const TeammateAvatar: FC<{ teammate: Teammate | null }> = ({
  teammate,
}) => {
  if (!teammate)
    return <div className="h-7 w-7 rounded-full bg-gray-200 animate-pulse" />;

  const { profile_picture_url } = teammate;

  const [shouldFallback, setShouldFallback] = useState(false);

  const colorSet = getColorSet(teammate.email);

  return (
    <>
      {profile_picture_url && !shouldFallback ? (
        <img
          alt=""
          className="h-7 w-7 rounded-full"
          src={profile_picture_url}
          onError={(e) => setShouldFallback(true)}
        />
      ) : (
        <div
          className={`h-7 w-7 rounded-full ${colorSet.light} ring-1 ${colorSet.ring} flex items-center justify-center`}
        >
          <span className={`text-sm font-medium ${colorSet.text}`}>
            {getInitials(
              teammate.email,
              teammate.first_name,
              teammate.last_name
            )}
          </span>
        </div>
      )}
    </>
  );
};
