import { CheckCircleIcon } from "@heroicons/react/24/outline";
import {
  BasicQuestion,
  Company,
  ComplexQuestion,
  ComplexQuestionInsert,
  ProjectUpdate,
  ProjectWithInterviewCount,
} from "app-types";
import { FC, useState } from "react";
import { Button, ButtonVariantsEnum, Label, SizesEnum } from "ui";
import { ReadOnlyBulletedList } from "../../../components/readOnlyBulletedList";
import { useAppSelector } from "../../../hooks/hook";
import { selectAssessmentById } from "../../assessments/assessmentsSlice";
import { QuestionsManagerSlideover } from "./questionsManagerSlideover";
import { RecruitingQuestionsManagerSlideover } from "./recruitingQuestionsManagerSlideover";

const NUM_QUESTIONS_TO_DISPLAY = 4;
const NUM_CRITERIA_TO_DISPLAY = 4;

interface ProjectSettingsQuestionsSectionProps {
  complexQuestions: (ComplexQuestion | ComplexQuestionInsert)[];
  basicQuestions: BasicQuestion[];
  project: ProjectWithInterviewCount;
  company: Company;
  onSaveProject: (changes: ProjectUpdate) => void;
  isRecruitingMode: boolean;
}

export const ProjectSettingsQuestionsSection: FC<
  ProjectSettingsQuestionsSectionProps
> = ({
  complexQuestions,
  basicQuestions,
  project,
  company,
  onSaveProject,
  isRecruitingMode,
}) => {
  const assessment = useAppSelector(
    selectAssessmentById(project.assessment_id)
  );

  const [isQuestionsManagerSlideoverOpen, setIsQuestionsManagerSlideoverOpen] =
    useState(false);
  const questions = [...complexQuestions, ...basicQuestions];

  // Criteria items will only be displayed for recruiting projects with an assessment
  const criteriaItems = (assessment?.custom_field_definitions || []).map(
    (criterion) => criterion.display_name
  );

  return (
    <div className="flex flex-col justify-between mb-2">
      <div className="flex flex-col space-y-3">
        {isRecruitingMode && assessment && (
          <div className="">
            <Label size={SizesEnum.SMALL} className="mb-2">
              Assessment Criteria
            </Label>
            {criteriaItems.length > 0 ? (
              <ReadOnlyBulletedList
                items={criteriaItems}
                maxItemsToDisplay={NUM_CRITERIA_TO_DISPLAY}
                onListClick={() => setIsQuestionsManagerSlideoverOpen(true)}
                itemDescriptor="criteria"
                icon={<CheckCircleIcon className="w-4 h-4 text-green-600" />}
              />
            ) : (
              <div className="text-sm text-gray-500">
                <Button
                  variant={ButtonVariantsEnum.Primary}
                  onClick={() => setIsQuestionsManagerSlideoverOpen(true)}
                  label="Add assessment criteria"
                />
              </div>
            )}
          </div>
        )}
        <div>
          {/* Only show AI interview questions label for recruiting projects */}
          {isRecruitingMode && assessment && (
            <Label size={SizesEnum.SMALL} className="mb-2">
              AI Interview Questions
            </Label>
          )}
          {questions.length > 0 ? (
            <ReadOnlyBulletedList
              items={questions.map((question) => question.question)}
              maxItemsToDisplay={NUM_QUESTIONS_TO_DISPLAY}
              onListClick={() => setIsQuestionsManagerSlideoverOpen(true)}
              itemDescriptor="questions"
            />
          ) : (
            <div className="mt-2">
              <Button
                variant={ButtonVariantsEnum.Primary}
                onClick={() => setIsQuestionsManagerSlideoverOpen(true)}
                label="Add interview questions"
              />
            </div>
          )}
        </div>
      </div>

      {isRecruitingMode ? (
        <RecruitingQuestionsManagerSlideover
          basicQuestions={basicQuestions}
          project={project}
          company={company}
          onSaveProject={onSaveProject}
          isOpen={isQuestionsManagerSlideoverOpen}
          onClose={() => setIsQuestionsManagerSlideoverOpen(false)}
        />
      ) : (
        <QuestionsManagerSlideover
          complexQuestions={complexQuestions}
          basicQuestions={basicQuestions}
          project={project}
          company={company}
          onSaveProject={onSaveProject}
          isOpen={isQuestionsManagerSlideoverOpen}
          onClose={() => setIsQuestionsManagerSlideoverOpen(false)}
        />
      )}
    </div>
  );
};
