import { TranscriptFragment } from "../client/client";

export enum TranscriptFragmentRolesEnum {
  AGENT = "agent",
  PARTICIPANT = "participant",
}

export type TranscriptFragmentPublicAPI = {
  role: TranscriptFragmentRolesEnum;
  transcript: string;
};

export function mapInterviewTranscriptFragmentsToPublicAPIFormat(
  transcriptFragments: TranscriptFragment[]
): TranscriptFragmentPublicAPI[] {
  const apiFragments: TranscriptFragmentPublicAPI[] = [];
  transcriptFragments.forEach((fragment) => {
    if (fragment.question) {
      // Handle survey-style fragments by adding the question and answer as separate fragments
      apiFragments.push({
        role: TranscriptFragmentRolesEnum.AGENT,
        transcript: fragment.question,
      });
      apiFragments.push({
        role: TranscriptFragmentRolesEnum.PARTICIPANT,
        transcript: fragment.text_transcript || "",
      });
    } else if (fragment.role) {
      // Phone call fragments
      apiFragments.push({
        role: fragment.role,
        transcript: fragment.text_transcript || "",
      });
    }
  });

  return apiFragments;
}
