import {
  InterviewStatusEnum,
  KeywordTrackerTypesEnum,
  ProjectWithInterviewCount,
  QUESTION_SUMMARY_MAX_INTERVIEW_COUNT,
  QUESTION_SUMMARY_MIN_INTERVIEW_COUNT,
} from "app-types";
import { FC, useMemo } from "react";
import { InfoBanner, Label, SizesEnum } from "ui";
import { InterviewWithContactAndActivities } from "../../app/admin_client_types";
import {
  PlaceholderSentimentLineChart,
  sentimentChartMargins,
  sentimentChartYDomain,
} from "../../helpers/chartHelpers";
import { useAppSelector } from "../../hooks/hook";
import { PaywallBanner } from "../billing/paywallBanner";
import { selectIsCompanyBlocked } from "../company/companySlice";
import { selectContactsDictionary } from "../contacts/contactsSlice";
import { selectInterviewsDictionary } from "../interviews/interviewsSlice";
import { selectKeywordTrackersForProject } from "../keywordTrackers/keywordTrackersSlice";
import { LineChartComponent } from "./charts/charts";
import { selectSentimentTrendData } from "./insightsSlice";
import {
  InsightsCard,
  KeywordTrackerCardContainer,
} from "./keywordTracker/keywordTrackerCardContainer";
import { QuestionThemesTable } from "./questionThemesTable";
import { formatSentimentTickForChart } from "./sentimentScore";
import { SurveyInsightsSection } from "./surveyInsightsSection";

interface InsightsTabProps {
  project: ProjectWithInterviewCount;
  interviews: InterviewWithContactAndActivities[];
}

export const InsightsTab: FC<InsightsTabProps> = ({ project, interviews }) => {
  const keywordTrackers = useAppSelector(
    selectKeywordTrackersForProject(project)
  );
  const isCompanyBlocked = useAppSelector(selectIsCompanyBlocked);
  const sentimentTrendData = useAppSelector(selectSentimentTrendData);

  const contactsDictionary = useAppSelector(selectContactsDictionary);
  const interviewsDictionary = useAppSelector(selectInterviewsDictionary);

  const completedInterviewsCount = useMemo(
    () =>
      interviews.filter((i) => i.status === InterviewStatusEnum.COMPLETED)
        .length,
    [interviews]
  );

  // Question summary trackers are generated after at least 5 interviews have been completed
  const questionSummaryTrackers = keywordTrackers.filter(
    (t) => t.type === KeywordTrackerTypesEnum.QUESTION_SUMMARY
  );
  const hasQuestionSummaryTrackers = questionSummaryTrackers.length > 0;

  const competitorTracker = keywordTrackers.find(
    (t) => t.type === KeywordTrackerTypesEnum.COMPETITIVE
  );

  if (isCompanyBlocked) return <PaywallBanner />;

  return (
    <div className="flex flex-col space-y-8">
      <div>
        <InsightsCard
          header={
            <div className="flex flex-row items-center text-base font-semibold text-gray-900 space-x-1">
              Average sentiment over time
            </div>
          }
          body={
            sentimentTrendData && sentimentTrendData.data.length > 1 ? (
              <div className="p-3 bg-white">
                <LineChartComponent
                  data={sentimentTrendData.data}
                  scale="time"
                  shouldShowTooltip={true}
                  yDomain={sentimentChartYDomain}
                  yTickFormatter={formatSentimentTickForChart}
                  height={200}
                  margin={sentimentChartMargins}
                />
              </div>
            ) : (
              <div className="p-3 bg-white">
                <PlaceholderSentimentLineChart />
              </div>
            )
          }
        />
      </div>
      <SurveyInsightsSection />
      {completedInterviewsCount > QUESTION_SUMMARY_MAX_INTERVIEW_COUNT && (
        <InfoBanner
          title={`Insights from the ${QUESTION_SUMMARY_MAX_INTERVIEW_COUNT} most recent interviews are shown below`}
        />
      )}
      <div>
        <Label
          size={SizesEnum.LARGE}
          className="block mb-4"
          children={"Response insights"}
        />
        {hasQuestionSummaryTrackers ? (
          <QuestionThemesTable
            project={project}
            keywordTrackers={questionSummaryTrackers}
          />
        ) : (
          <InfoBanner
            title={`After ${QUESTION_SUMMARY_MIN_INTERVIEW_COUNT} interviews have been completed, aggregate themes and insights for each of your interview questions will appear here.`}
          />
        )}
      </div>

      {competitorTracker ? (
        <KeywordTrackerCardContainer
          key={`keyword-tracker-${competitorTracker.id}`}
          keywordTracker={competitorTracker}
          contactsDictionary={contactsDictionary}
          interviewsDictionary={interviewsDictionary}
          interviewCount={project.interview_count}
          questionNumber={undefined}
        />
      ) : null}
    </div>
  );
};
