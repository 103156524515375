import { Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import { FC, Fragment } from "react";
import { useAppSelector } from "../../hooks/hook";
import {
  NotificationTypeEnum,
  selectNotifications,
} from "./notificationsSlice";
// import { XMarkIcon } from "@heroicons/react/20/solid";

const NotificationsOverlay: FC = () => {
  const notifications = useAppSelector(selectNotifications);

  return (
    <>
      {/* Global notification overlay rendered on top of app*/}
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-start p-6 z-50"
      >
        <div className="flex w-full flex-col items-end space-y-4">
          {notifications.map((notification) => (
            <Transition
              key={notification.id}
              show={true}
              appear={true}
              as={Fragment}
              enter="transform ease-out duration-300 transition"
              enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              enterTo="translate-y-0 opacity-100 sm:translate-x-0"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      {renderIcon(notification.type)}
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                      <p className="text-sm font-medium text-gray-900">
                        {notification.primaryMessage}
                      </p>
                      {notification.secondaryMessage && (
                        <p className="mt-1 text-sm text-gray-500">
                          {notification.secondaryMessage}
                        </p>
                      )}
                    </div>

                    {/* Restore this if we add sticky notifications that have to be dismissed
                    <div className="ml-4 flex flex-shrink-0">
                      <button
                        type="button"
                        className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => {
                          setShow(false);
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
            </Transition>
          ))}
        </div>
      </div>
    </>
  );
};

function renderIcon(notificationType: NotificationTypeEnum) {
  switch (notificationType) {
    case NotificationTypeEnum.SUCCESS:
      return (
        <CheckCircleIcon
          className="h-6 w-6 text-green-400"
          aria-hidden="true"
        />
      );
    case NotificationTypeEnum.FAILURE:
      return (
        <ExclamationCircleIcon
          className="h-6 w-6 text-red-400"
          aria-hidden="true"
        />
      );
  }
}

export default NotificationsOverlay;
