import { Dictionary } from "@reduxjs/toolkit";
import { Contact, LoadingStatesEnum } from "app-types";
import { FC, useEffect, useState } from "react";
import { getAxiosInstanceWithAuth } from "../../api/axiosConfig";
import { InterviewWithContactAndActivities } from "../../app/admin_client_types";
import { buildInterviewsWithContactsAndActivities } from "../interviews/interviewsSlice";
import {
  TargetingSlideover,
  TargetingSlideoverProps,
} from "./targetingSlideover";

export const TargetingSlideoverContainer: FC<
  Omit<TargetingSlideoverProps, "existingEmptyInterviews" | "loadingState">
> = (props) => {
  const [emptyInterviews, setEmptyInterviews] = useState<
    InterviewWithContactAndActivities[]
  >([]);
  const [loadingState, setLoadingState] = useState<LoadingStatesEnum>(
    LoadingStatesEnum.LOADING
  );

  // Fetch empty interviews on load
  const fetchEmptyInterviews = async () => {
    try {
      const axios = await getAxiosInstanceWithAuth();
      const response = await axios.get(
        `/projects/${props.project.id}/interviews?mode=empty`
      );
      const { interviews, contacts, activities } = response.data;

      // Build dictionary from array of contacts
      const contactsDictionary = (contacts as Contact[]).reduce(
        (acc, contact) => {
          acc[contact.id] = contact;
          return acc;
        },
        {} as Dictionary<Contact>
      );

      // Build interviewWithContactAndActivities using same logic as our redux state
      const emptyInterviews = buildInterviewsWithContactsAndActivities(
        interviews,
        contactsDictionary,
        {},
        activities
      );

      setEmptyInterviews(emptyInterviews);

      setLoadingState(LoadingStatesEnum.LOADED);
    } catch (error: any) {
      setLoadingState(LoadingStatesEnum.ERROR);
    }
  };

  useEffect(() => {
    fetchEmptyInterviews();
  }, []);

  return (
    <TargetingSlideover
      {...props}
      existingEmptyInterviews={emptyInterviews}
      loadingState={loadingState}
    />
  );
};
