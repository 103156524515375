import {
  DocumentArrowUpIcon,
  RocketLaunchIcon,
} from "@heroicons/react/24/outline";
import {
  Activity,
  InterviewStatusEnum,
  isValidEmail,
  LoadingStatesEnum,
  PosthogEventTypesEnum,
  Project,
} from "app-types";
import { format } from "date-fns";
import { parse } from "papaparse";
import posthog from "posthog-js";
import { FC, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  Button,
  ButtonVariantsEnum,
  FileCard,
  isValidName,
  ModalVariantsEnum,
  SimpleModal,
  Slideover,
} from "ui";
import { InterviewWithContactAndActivities } from "../../app/admin_client_types";
import { useAppDispatch } from "../../hooks/hook";
import {
  bulkUpdateInterviews,
  deleteInterviews,
} from "../interviews/interviewsSlice";
import {
  NotificationTypeEnum,
  showNotification,
} from "../notificationsOverlay/notificationsSlice";
import { fetchProjectById } from "../projects/projectsSlice";
import { createContactsAndInterviewsForProject } from "./targetingSlideoverHelpers";
import {
  RowErrorsMap,
  TargetingSlideoverTable,
} from "./targetingSlideoverTable";

export enum TargetingSlideoverVariantsEnum {
  MANUAL = "manual",
  CSV = "csv",
  PENDING_PARTICIPANTS = "pending_participants",
  OUTREACH_PARTICIPANTS = "outreach_participants",
}

export interface TargetingSlideoverProps {
  project: Project;
  existingEmptyInterviews: InterviewWithContactAndActivities[];
  variant: TargetingSlideoverVariantsEnum;
  loadingState: LoadingStatesEnum;
  onClose: () => void;
}

export interface TargetingContact {
  email: string;
  first_name: string | null;
  last_name: string | null;
  activity_summary?: string[];
}

export const TargetingSlideover: FC<TargetingSlideoverProps> = ({
  project,
  existingEmptyInterviews,
  variant,
  onClose,
  loadingState,
}) => {
  const dispatch = useAppDispatch();

  const [summaryError, setSummaryError] = useState<string | undefined>();
  const [rowErrors, setRowErrors] = useState<RowErrorsMap>({});

  // Store the contacts the user enters manually or from a CSV
  const [contacts, setContacts] = useState<TargetingContact[]>(emptyTableState);

  const [uploadedFile, setUploadedFile] = useState<File | undefined>();
  const [isSaveInProgress, setIsSaveInProgress] = useState<boolean>(false);

  const [
    isLiveParticipantsConfirmationModalOpen,
    setIsLiveParticipantsConfirmationModalOpen,
  ] = useState<boolean>(false);

  const [
    isCancelOutreachParticipantsConfirmationModalOpen,
    setIsCancelOutreachParticipantsConfirmationModalOpen,
  ] = useState<boolean>(false);

  // Re-compute errors whenever contacts change. If there are no errors,
  // summaryError will be an empty string and errorsMap an empty object.
  useEffect(() => {
    const { summaryError, errorsMap } = computeRowErrors(contacts);
    setRowErrors(errorsMap);
    setSummaryError(summaryError);
  }, [contacts]);

  const isAddingNewParticipants =
    variant === TargetingSlideoverVariantsEnum.MANUAL ||
    variant === TargetingSlideoverVariantsEnum.CSV;
  const isEditingPendingParticipants =
    variant === TargetingSlideoverVariantsEnum.PENDING_PARTICIPANTS;
  const isEditingOutreachParticipants =
    variant === TargetingSlideoverVariantsEnum.OUTREACH_PARTICIPANTS;
  const pendingInterviews = existingEmptyInterviews.filter(
    (i) => i.status === InterviewStatusEnum.PENDING
  );
  const pendingInterviewContacts: TargetingContact[] = pendingInterviews
    .map((i) => {
      if (!i.contact.email) return null;

      const contact: TargetingContact = {
        email: i.contact.email,
        first_name: i.contact.first_name,
        last_name: i.contact.last_name,
        activity_summary: computeActivitySummary(i.activities),
      };
      return contact;
    })
    .filter((c): c is TargetingContact => Boolean(c));

  useEffect(() => {
    if (isEditingPendingParticipants || isEditingOutreachParticipants) {
      setContacts(pendingInterviewContacts);
    }
  }, [variant, loadingState]);

  // If we're editing outreach participants, any participants removed in the table
  // will need to be cancelled
  const cancelledInterviews =
    isEditingOutreachParticipants &&
    pendingInterviewContacts.filter((c) => {
      return !contacts.some((row) => row.email === c.email);
    });

  const {
    getRootProps: getDropzoneRootProps,
    getInputProps: getDropzoneInputProps,
  } = useDropzone({
    onDropAccepted: (files) => {
      setSummaryError(undefined);
      setRowErrors({});
      const file = files[0]; // There will only ever be on 1 file since we set maxFiles to 1
      setUploadedFile(file);

      // Parse the CSV content
      parse(file, {
        header: true,
        delimiter: ",",
        skipEmptyLines: true,
        complete: (result) => {
          if (result.errors.length) {
            setSummaryError(
              `Error parsing CSV: ${JSON.stringify(result.errors)}`
            );
            return;
          }

          // Map parsed data to contact objects. Account for different column capitalization and naming conventions.
          const mappedContacts = result.data
            .filter((row: any) => findValueForColumnName(row, ["email"]))
            .map((row: any) => ({
              email: findValueForColumnName(row, ["email"]),
              first_name: findValueForColumnName(row, [
                "first_name",
                "firstname",
                "first-name",
                "first name",
                "first",
              ]),
              last_name: findValueForColumnName(row, [
                "last_name",
                "lastname",
                "last-name",
                "last name",
                "last",
              ]),
            }));

          // De-dupe contacts by email
          const uniqueContactsMap: { [email: string]: TargetingContact } = {};
          mappedContacts.forEach((contact) => {
            uniqueContactsMap[contact.email] = contact;
          });

          const uniqueContacts = Object.values(uniqueContactsMap);

          if (!mappedContacts.length) {
            setUploadedFile(undefined);
            setSummaryError(
              "No valid participants found in CSV. Please ensure your CSV has an 'email' column and at least one row with a valid email address."
            );
            return;
          }

          const { summaryError, errorsMap } = computeRowErrors(mappedContacts);
          if (summaryError) {
            setSummaryError(summaryError);
            setRowErrors(errorsMap);
          }

          setContacts(uniqueContacts);
        },
      });
    },
    onDropRejected: (err) => {
      setSummaryError("File must be a CSV");
      setRowErrors({});
    },
    maxFiles: 1,
    maxSize: 10000000, // Max size 10MB
    accept: { "text/csv": [] },
  });

  const renderCsvUploadInstructions = () => {
    return (
      <div className="h-full overflow-y-scroll">
        <p className="mb-4 text-sm text-gray-600">
          CSV must include an "email" column, and can optionally include
          "first_name" and "last_name" columns. Providing at least 20
          participants is recommended.
        </p>
        <div
          {...getDropzoneRootProps()}
          className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10"
        >
          <div className="text-center">
            <DocumentArrowUpIcon
              className="mx-auto h-12 w-12 text-gray-300"
              aria-hidden="true"
            />
            <div className="mt-4 flex text-sm leading-6 text-gray-600">
              <label
                htmlFor="file-upload"
                className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
              >
                <span>Upload a CSV</span>
                <input
                  {...getDropzoneInputProps()}
                  id="file-upload"
                  name="file-upload"
                  type="file"
                  className="sr-only"
                />
              </label>
              <p className="pl-1">or drag and drop.</p>
            </div>
            <p className="text-xs leading-5 text-gray-600">Up to 10MB</p>
          </div>
        </div>
        {maybeRenderSummaryError()}
      </div>
    );
  };

  const renderBodyContent = () => {
    switch (variant) {
      case TargetingSlideoverVariantsEnum.CSV: {
        // If we don't have uploaded file yet, render the dropzone
        if (!uploadedFile) return renderCsvUploadInstructions();

        // Otherwise render the uploaded file
        return (
          <>
            <FileCard
              name={uploadedFile.name}
              onDelete={() => {
                setUploadedFile(undefined);
                setContacts([]);
              }}
            />
            <label
              htmlFor="csv-upload"
              className="block text-sm font-medium leading-6 text-gray-900 my-2"
            >
              {contacts.length} participants found
            </label>
            <TargetingSlideoverTable
              variant={variant}
              contacts={contacts}
              rowErrors={rowErrors}
            />
            {maybeRenderSummaryError()}
          </>
        );
      }

      case TargetingSlideoverVariantsEnum.MANUAL:
        return (
          <>
            <p className="mb-4 text-sm text-gray-600">
              Enter or paste participant details in the table below. Providing
              at least 20 participants is recommended.
            </p>
            <TargetingSlideoverTable
              variant={variant}
              contacts={contacts}
              setContacts={setContacts}
              rowErrors={rowErrors}
            />
            {maybeRenderSummaryError()}
          </>
        );

      case TargetingSlideoverVariantsEnum.PENDING_PARTICIPANTS:
        return (
          <>
            <p className="mb-4 text-sm text-gray-600">
              Edit or remove participants in the table below.
            </p>
            <TargetingSlideoverTable
              variant={variant}
              contacts={contacts}
              setContacts={setContacts}
              rowErrors={rowErrors}
            />
            {maybeRenderSummaryError()}
          </>
        );

      case TargetingSlideoverVariantsEnum.OUTREACH_PARTICIPANTS: {
        return (
          <>
            <p className="mb-4 text-sm text-gray-600">
              Email outreach is in progress for the following participants.
              Reminder emails are automatically sent to participants two days
              after their initial invitation. Removing a participant from the
              table below will cancel their interview.
            </p>
            <TargetingSlideoverTable
              variant={variant}
              contacts={contacts}
              setContacts={setContacts}
              rowErrors={rowErrors}
            />
          </>
        );
      }
    }
  };

  // Return an object with row-specific errors as well as a summary error message
  const computeRowErrors = (
    rows: TargetingContact[]
  ): { summaryError: string; errorsMap: RowErrorsMap } => {
    // Max number of participants that can be added at once is 500
    if (rows.length > 500) {
      return {
        errorsMap: {},
        summaryError:
          "A maximum of 500 participants can be added at once. Please remove some participants and try again.",
      };
    }

    // Compute duplicate emails to be used by our row-specific helper function
    const emailSet = new Set();
    const duplicateEmails: string[] = [];
    rows.forEach((contact) => {
      if (emailSet.has(contact.email)) {
        duplicateEmails.push(contact.email as string);
      }
      emailSet.add(contact.email);
    });

    // Compute row-specific errors and store in our map of row indices to errors.
    let errorsMap: RowErrorsMap = {};
    rows.forEach((row, index) => {
      const errorForRow = computeErrorForRow(row, duplicateEmails);
      if (errorForRow) errorsMap[index] = errorForRow;
    });

    return {
      errorsMap,
      summaryError: computeSummaryErrorMessage(errorsMap, variant),
    };
  };

  const computeErrorForRow = (
    row: TargetingContact,
    duplicateEmails: string[]
  ) => {
    // Ignore empty rows (no email, first_name, and last_name)
    const isEmptyRow = !row.email && !row.first_name && !row.last_name;
    if (isEmptyRow) return "";

    // Ensure every contact has a valid email
    if (!row.email || !isValidEmail(row.email)) return "Invalid email address.";

    if (!isValidEmail(row.email, true))
      return "Email aliases are not supported.";

    const hasInvalidName =
      (row.first_name && !isValidName(row.first_name)) ||
      (row.last_name && !isValidName(row.last_name));
    if (hasInvalidName) {
      return "Names must be fewer than 36 characters.";
    }

    if (duplicateEmails.includes(row.email)) {
      return "Duplicate entries found for this email address";
    }

    const hasConflictingInProgressInterview = existingEmptyInterviews.some(
      (interview) =>
        interview.contact.email === row.email &&
        interview.status === InterviewStatusEnum.IN_PROGRESS
    );
    const hasConflictingPendingInterview = existingEmptyInterviews.some(
      (interview) =>
        interview.contact.email === row.email &&
        interview.status === InterviewStatusEnum.PENDING
    );

    if (hasConflictingInProgressInterview)
      return "An in-progress interview already exists for this participant.";

    if (
      hasConflictingPendingInterview &&
      variant !== TargetingSlideoverVariantsEnum.PENDING_PARTICIPANTS
    )
      return "A pending participant already exists for this contact.";

    return "";
  };

  const onSaveProject = async () => {
    // Ignore empty rows (no email, first_name, or last_name)
    const trimmedRows = contacts.filter(
      (contact) => contact.email || contact.first_name || contact.last_name
    );

    if (isAddingNewParticipants && !trimmedRows.length) {
      setSummaryError("Please provide at least one participant.");
      return;
    }

    const { summaryError, errorsMap } = computeRowErrors(contacts);
    if (summaryError) {
      setRowErrors(errorsMap);
      setSummaryError(summaryError);
      return;
    }

    setIsSaveInProgress(true);

    try {
      // Only delete or cancel interviews if we're editing an existing list.
      if (isEditingPendingParticipants || isEditingOutreachParticipants) {
        // If we can't find an old pending interview in the new rows, the user removed it.
        const deletedPendingInterviewIds = pendingInterviews
          .filter((interview) => {
            return !trimmedRows.some(
              (row) => row.email === interview.contact.email
            );
          })
          .map((contact) => contact.id);

        if (isEditingPendingParticipants) {
          await dispatch(
            deleteInterviews({
              projectId: project.id,
              interviewIds: deletedPendingInterviewIds,
            })
          );
        }

        if (isEditingOutreachParticipants) {
          await dispatch(
            bulkUpdateInterviews({
              interviewIds: deletedPendingInterviewIds,
              update: {
                status: InterviewStatusEnum.CANCELLED,
              },
            })
          );
        }
      }

      // Only create new contacts and interviews if we're adding new participants
      if (!isEditingOutreachParticipants && trimmedRows.length) {
        await createContactsAndInterviewsForProject(
          trimmedRows.map((r) => ({
            email: r.email,
            first_name: r.first_name,
            last_name: r.last_name,
            phone_number: null,
          })),
          project
        );

        // Refetch project to get latest interview counts
        dispatch(fetchProjectById(project.id));

        posthog.capture(PosthogEventTypesEnum.PARTICIPANTS_INVITE, {
          project_id: project.id,
          num_participants_invited: trimmedRows.length,
        });
      }

      // Dispatch a success notification
      showNotification(dispatch, {
        id: `project-${
          project.id
        }-targeting-updated-succeeded-${new Date().getTime()}`,
        primaryMessage: `Participants successfully ${
          isAddingNewParticipants ? "added" : "updated"
        }`,
        type: NotificationTypeEnum.SUCCESS,
      });
      closeSlideover();
    } catch (error: any) {
      // Dispatch a failure notification
      showNotification(dispatch, {
        id: `project-${
          project.id
        }-targeting-update-failed-${new Date().getTime()}`,
        primaryMessage: `Failed to ${
          isAddingNewParticipants ? "add" : "update"
        } participants to project`,
        secondaryMessage: error.message,
        type: NotificationTypeEnum.FAILURE,
      });
    } finally {
      setIsSaveInProgress(false);
    }
  };

  const maybeRenderSummaryError = () => {
    if (!summaryError) return null;

    return (
      <p className="mt-2 text-sm text-red-600" id="csv-upload-error">
        {summaryError}
      </p>
    );
  };

  // Reset state before closing the slideover
  const closeSlideover = () => {
    onClose();

    // Reset state after a short delay to allow the slideover to close first.
    setTimeout(() => {
      setUploadedFile(undefined);
      setContacts(emptyTableState);
      setRowErrors({});
      setSummaryError("");
    }, 1000);
  };

  const renderLiveParticipantsConfirmationModal = () => {
    return (
      <SimpleModal
        isOpen={isLiveParticipantsConfirmationModalOpen}
        variant={ModalVariantsEnum.Standard}
        icon={
          <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
            <RocketLaunchIcon
              className="h-6 w-6 text-blue-600"
              aria-hidden="true"
            />
          </div>
        }
        title="Confirm participants"
        subtitle={
          "Heads up — because your project is currently Live, new participants will immediately receive interview invitation emails once they are saved."
        }
        confirmButtonText="Save participants"
        onCancel={() => {
          setIsLiveParticipantsConfirmationModalOpen(false);
        }}
        onConfirm={onSaveProject}
      />
    );
  };

  const renderCancelOutreachParticipantsConfirmationModal = () => {
    return (
      <SimpleModal
        isOpen={isCancelOutreachParticipantsConfirmationModalOpen}
        variant={ModalVariantsEnum.Warning}
        title="Cancel interviews?"
        subtitle={`If you proceed, ${
          cancelledInterviews && cancelledInterviews.length > 1
            ? `${cancelledInterviews && cancelledInterviews.length} interviews`
            : "1 interview"
        } will be cancelled.`}
        confirmButtonText="Save"
        onCancel={() => {
          setIsCancelOutreachParticipantsConfirmationModalOpen(false);
        }}
        onConfirm={onSaveProject}
      />
    );
  };

  return (
    <Slideover
      title={computeSlideoverTitle(variant)}
      onClickClose={() => {
        closeSlideover();
      }}
      shouldShow={Boolean(variant)}
      buttons={
        <>
          <Button
            variant={ButtonVariantsEnum.Secondary}
            label="Cancel"
            onClick={closeSlideover}
          />
          <Button
            variant={ButtonVariantsEnum.Primary}
            isLoading={isSaveInProgress}
            label="Save"
            isDisabled={
              Object.values(rowErrors).length > 0 ||
              (isAddingNewParticipants &&
                !contacts.filter((c) => c.email).length)
            }
            onClick={() => {
              // If we're editing participants that have already been invited,
              // we might need to warn the user that they're cancelling interviews.
              if (isEditingOutreachParticipants) {
                if (cancelledInterviews && cancelledInterviews.length > 0) {
                  setIsCancelOutreachParticipantsConfirmationModalOpen(true);
                  return;
                } else {
                  closeSlideover();
                  return;
                }
              }

              // If the project is live, show a confirmation modal before saving
              if (project.is_live)
                setIsLiveParticipantsConfirmationModalOpen(true);
              else onSaveProject();
            }}
          />
        </>
      }
    >
      <div className="flex flex-col overflow-y-scroll p-6 h-full">
        <div className="grid grid-rows-[auto,auto,1fr,auto] h-full">
          {renderBodyContent()}
        </div>
      </div>

      {renderLiveParticipantsConfirmationModal()}
      {renderCancelOutreachParticipantsConfirmationModal()}
    </Slideover>
  );
};

// 20 rows of empty data for the targeting table by default.
const emptyTableState = [
  { first_name: "", last_name: "", email: "" },
  { first_name: "", last_name: "", email: "" },
  { first_name: "", last_name: "", email: "" },
  { first_name: "", last_name: "", email: "" },
  { first_name: "", last_name: "", email: "" },
  { first_name: "", last_name: "", email: "" },
  { first_name: "", last_name: "", email: "" },
  { first_name: "", last_name: "", email: "" },
  { first_name: "", last_name: "", email: "" },
  { first_name: "", last_name: "", email: "" },
  { first_name: "", last_name: "", email: "" },
  { first_name: "", last_name: "", email: "" },
  { first_name: "", last_name: "", email: "" },
  { first_name: "", last_name: "", email: "" },
  { first_name: "", last_name: "", email: "" },
  { first_name: "", last_name: "", email: "" },
  { first_name: "", last_name: "", email: "" },
  { first_name: "", last_name: "", email: "" },
  { first_name: "", last_name: "", email: "" },
  { first_name: "", last_name: "", email: "" },
];

// Given a row and a list of valid column names, return the first value
// we can find for a valid column name. Useful for parsing CSVs that might have
// "firstName" instead of "first_name" for example.
function findValueForColumnName(row: any, possibleNames: string[]): any {
  for (let name of possibleNames) {
    const foundKey = Object.keys(row).find(
      (key) => key.replace(/[\s_-]/g, "").toLowerCase() === name
    );
    if (foundKey) {
      return row[foundKey];
    }
  }
}

function computeSummaryErrorMessage(
  errorsMap: RowErrorsMap,
  variant: TargetingSlideoverVariantsEnum
): string {
  // Offset by +1 to make human readable
  const errorIndices = Object.keys(errorsMap).map((index) => Number(index) + 1);
  const numErrors = errorIndices.length;

  if (!numErrors) {
    return "";
  }

  let baseError;
  if (numErrors === 1) {
    baseError = `Error found for row ${errorIndices[0]}`;
  } else {
    baseError = `Errors found for rows ${errorIndices.join(", ")}`;
  }

  return variant === TargetingSlideoverVariantsEnum.CSV
    ? `${baseError}. Please resolve errors and re-upload your CSV.`
    : baseError;
}

function computeSlideoverTitle(variant: TargetingSlideoverVariantsEnum) {
  switch (variant) {
    case TargetingSlideoverVariantsEnum.CSV:
    case TargetingSlideoverVariantsEnum.MANUAL:
      return "Add participants";
    case TargetingSlideoverVariantsEnum.PENDING_PARTICIPANTS:
      return "Edit participants";
    case TargetingSlideoverVariantsEnum.OUTREACH_PARTICIPANTS:
      return "Participant outreach";
  }
}

function computeActivitySummary(activities: Activity[]) {
  if (!activities.length) return undefined;

  if (activities.length === 1) {
    return [
      `Invite sent ${format(
        new Date(activities[0].created_at),
        "MMM d, yyyy"
      )}`,
    ];
  }

  const sortedActivities = activities.slice().sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateA.getTime() - dateB.getTime();
  });

  const oldestActivityDate = new Date(sortedActivities[0].created_at);
  const latestActivityDate = new Date(
    sortedActivities[sortedActivities.length - 1].created_at
  );

  return [
    `Invite sent ${format(oldestActivityDate, "MMM d, yyyy")}`,
    `Reminder sent ${format(latestActivityDate, "MMM d, yyyy")}`,
  ];
}
