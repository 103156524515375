import { Select } from "ui";
import { useAppSelector } from "../../hooks/hook";
import { selectAllPhoneNumbers } from "./phoneNumbersSlice";

interface PhoneNumberSelectorProps {
  phoneNumberId: string | undefined;
  onChange: (value: string) => void;
  className?: string;
}

export function PhoneNumberSelect({
  phoneNumberId,
  onChange,
  className,
}: PhoneNumberSelectorProps) {
  const phoneNumbers = useAppSelector(selectAllPhoneNumbers);

  // If we have less than 2 phone numbers, don't show the selector
  if (!phoneNumbers || phoneNumbers.length < 2) {
    return null;
  }

  // Convert phone numbers to the format expected by Select
  const options = phoneNumbers.map((phoneNumber) => ({
    name: phoneNumber.number,
    value: phoneNumber.id,
  }));

  const currentSelection = options.find(
    (option) => option.value === phoneNumberId
  );

  return (
    <div className={className}>
      <Select
        options={options}
        label="Phone number"
        currentSelection={currentSelection || options[0]}
        onChange={(option) => option.value && onChange(option.value as string)}
        placeholder="Select a phone number"
      />
    </div>
  );
}
