import React, { useEffect, useMemo, useState } from "react";
import { PillColorsEnum } from "ui";
import { getAxiosInstanceWithAuth } from "../../api/axiosConfig";
import { scoreToDetails } from "../insights/assessmentScore";

interface AssessmentDistributionBarProps {
  projectId: string;
  onScoreClick: (score: number) => void;
}

export const AssessmentDistributionBar: React.FC<
  AssessmentDistributionBarProps
> = ({ projectId, onScoreClick }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [scoreDistribution, setScoreDistribution] = useState<{
    [key: number]: number;
  } | null>(null);

  // Fetch the score distribution
  useEffect(() => {
    const fetchDistribution = async () => {
      try {
        const axios = await getAxiosInstanceWithAuth();
        const response = await axios.get(`/projects/${projectId}/insights`);

        setScoreDistribution(response.data.assessment_distribution);
      } catch (error) {
        console.error("Failed to fetch assessment distribution:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDistribution();
  }, [projectId]);

  const distribution = useMemo(() => {
    if (!scoreDistribution) return [];

    const total = Object.values(scoreDistribution).reduce(
      (sum, count) => sum + count,
      0
    );

    return Object.entries(scoreDistribution).map(([score, count]) => ({
      score: parseInt(score),
      count,
      percentage: total ? (count / total) * 100 : 0,
    }));
  }, [scoreDistribution]);

  const getColorClasses = (pillColor: PillColorsEnum) => {
    switch (pillColor) {
      case PillColorsEnum.RED:
        return {
          bg: "bg-red-300",
          text: "text-red-900",
          subtext: "text-red-900",
        };
      case PillColorsEnum.YELLOW:
        return {
          bg: "bg-yellow-300",
          text: "text-yellow-900",
          subtext: "text-yellow-900",
        };
      case PillColorsEnum.GREY:
        return {
          bg: "bg-gray-300",
          text: "text-gray-900",
          subtext: "text-gray-900",
        };
      case PillColorsEnum.LIME:
        return {
          bg: "bg-lime-300",
          text: "text-lime-900",
          subtext: "text-lime-900",
        };
      case PillColorsEnum.GREEN:
        return {
          bg: "bg-green-300",
          text: "text-green-900",
          subtext: "text-green-900",
        };
      default:
        return {
          bg: "bg-gray-300",
          text: "text-gray-900",
          subtext: "text-gray-900",
        };
    }
  };

  return (
    <div className="w-full mb-5">
      <div
        className={`
          h-8 rounded-lg overflow-hidden 
          transition-opacity duration-500 ease-in-out relative
        `}
      >
        <div
          className={`
          absolute w-full
          transition-opacity duration-500 ease-in-out
          ${isLoading ? "opacity-100" : "opacity-0"}
        `}
        >
          <div className="h-8 w-full bg-gray-200 animate-pulse">
            <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/20 to-transparent shimmer" />
          </div>
        </div>

        <div
          className={`
          flex h-8 w-full
          transition-opacity duration-500 ease-in-out
          ${isLoading ? "opacity-0" : "opacity-100"}
        `}
        >
          {distribution.map(({ score, percentage, count }) => {
            const colors = getColorClasses(scoreToDetails[score].color);

            if (percentage === 0) return null;
            return (
              <div
                key={score}
                className={`${colors.bg} min-w-[140px] relative flex items-center pl-4 cursor-pointer`}
                style={{ width: `${percentage || 2}%` }}
                onClick={() => onScoreClick(score)}
                role="button"
                title={`Show interviews with score ${score} or higher`}
              >
                <div
                  className={`flex items-center justify-between space-x-2 w-full whitespace-nowrap`}
                >
                  <span className={`font-semibold text-sm ${colors.text}`}>
                    {scoreToDetails[score].text}
                  </span>
                  <span className={`text-xs ${colors.subtext} pr-4`}>
                    {count} ({Math.round(percentage)}%)
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
